import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  addCommentToTicket,
  getTicketById,
  getTicketInfo,
} from "../../api/geoprod";
import {
  Button,
  Form,
  Input,
  notification,
  Spin,
  Tag,
  Timeline,
  Typography,
} from "antd";
import { useTranslation } from "react-i18next";
import { CalendarOutlined, PaperClipOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { TICKET_STATUS_MD5 } from "../../utils/constants";
import { ChevronLeft, ChevronRight } from "lucide-react";

const { TextArea } = Input;

const ReclamationRecord = () => {
  const [ticket, setTicket] = useState(null);
  const [commentaire, setCommentaire] = useState([]);
  const [loader, setLoader] = useState(true);
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const id_user = JSON.parse(sessionStorage.getItem("-x-token-user")).id_user;
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const fetchData = async () => {
    setLoader(true);
    const response = await getTicketById(id);
    const ticketResponse = await getTicketInfo(id);

    if (response.data.error) {
      notification.error({
        message: t("Erreur"),
        description: response.data.message,
      });
      setLoader(false);
      return;
    }

    setTicket(ticketResponse.data);
    setCommentaire(response.data);
    setLoader(false);
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  const onFinish = async (values) => {
    try {
      if (
        ticket?.statut_details?.id === TICKET_STATUS_MD5.closed ||
        ticket?.statut_details?.id === TICKET_STATUS_MD5.inProgress
      ) {
        return notification.info({
          message: t("Erreur"),
          description: t(
            "Vous ne pouvez pas ajouter de commentaire à un ticket fermé ou en cours de traitement"
          ),
        });
      }
      const response = await addCommentToTicket(id, values.description);

      if (response.data.error) {
        return notification.error({
          message: t("Erreur"),
          description: response.data.message,
        });
      }

      notification.success({
        message: t("Réussi"),
        description: t("Votre commentaire a été ajouté avec succès"),
      });

      // Re-trigger the useEffect by re-fetching the data
      fetchData();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      style={{
        background: "#fff",
        padding: "20px",
        borderRadius: 13,
        minHeight: "400px",
      }}
    >
      {loader ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <h3
            style={{
              marginBlock: 25,
              fontWeight: 600,
              fontSize: 24,
              display: "flex",
              alignItems: "center",
              gap: "5px",
              flexWrap: "pre-wrap",
            }}
          >
            <Button
              type="ghost"
              icon={i18n.dir() === "ltr" ? <ChevronLeft /> : <ChevronRight />}
              onClick={() => navigate(-1)}
            />
            {ticket?.sujet}{" "}
            <div
              style={{
                color: ticket?.statut_details?.color,
                backgroundColor: ticket?.statut_details?.background_color,
                border: `1px solid ${ticket?.statut_details?.color}`,
                padding: "3px 10px",
                fontSize: 11,
                borderRadius: 10,
              }}
            >
              {t(ticket?.statut_details?.libelle)}
            </div>
          </h3>
          <Timeline
            style={{ paddingTop: "20px" }}
            items={commentaire.map((comment) => ({
              children: (
                <Typography.Paragraph
                  style={{
                    fontSize: 16,
                    background:
                      comment.user_id === id_user ? "#FAFAFA" : "#E8F0FE",
                    borderRadius: "13px",
                    padding: "10px",
                    border: "1px solid #E8E8E8",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: 12,
                      }}
                    >
                      {comment.user_id === id_user
                        ? t("Moi :")
                        : t("Administration :")}
                    </span>

                    <span
                      style={{
                        color: "#024fa8",
                        fontStyle: "italic",
                        fontSize: 12,
                      }}
                    >
                      <CalendarOutlined />
                      {comment?.date &&
                        dayjs(comment?.date).format("DD/MM/YYYY HH:mm:ss")}{" "}
                    </span>
                  </div>

                  <span>{comment?.commentaire}</span>
                  {comment?.docs?.map((doc, index) => (
                    <>
                      <br />
                      <a href={doc?.doc} target="_blank" key={"doc-" + index}>
                        <Tag color="geekblue">
                          <PaperClipOutlined /> {doc?.name}
                        </Tag>
                      </a>
                    </>
                  ))}

                  {comment?.children?.length > 0 && (
                    <Timeline
                      style={{ paddingTop: "20px" }}
                      items={comment?.children?.map((child) => ({
                        children: (
                          <Typography.Paragraph
                            key={child.id}
                            style={{
                              fontSize: 16,
                              background:
                                child.user_id === id_user
                                  ? "#FAFAFA"
                                  : "#E8F0FE",
                              borderRadius: "13px",
                              padding: "10px",
                              border: "1px solid #E8E8E8",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <span
                                style={{
                                  fontWeight: "bold",
                                  fontSize: 12,
                                }}
                              >
                                {child.user_id === id_user
                                  ? t("Moi :")
                                  : t("Administration :")}
                              </span>

                              <span
                                style={{
                                  color: "#024fa8",
                                  fontStyle: "italic",
                                  fontSize: 12,
                                }}
                              >
                                <CalendarOutlined />
                                {child?.date &&
                                  dayjs(child?.date).format(
                                    "DD/MM/YYYY HH:mm:ss"
                                  )}{" "}
                              </span>
                            </div>

                            <span>{child?.commentaire}</span>
                            {child?.docs?.map((doc, index) => (
                              <>
                                <br />
                                <a
                                  href={doc?.doc}
                                  target="_blank"
                                  key={"doc-" + index}
                                >
                                  <Tag color="geekblue">
                                    <PaperClipOutlined /> {doc?.name}
                                  </Tag>
                                </a>
                              </>
                            ))}
                          </Typography.Paragraph>
                        ),
                      }))}
                    />
                  )}
                </Typography.Paragraph>
              ),
            }))}
          />
          <Form
            form={form}
            disabled={
              ticket?.statut_details?.id === TICKET_STATUS_MD5.closed ||
              ticket?.statut_details?.id === TICKET_STATUS_MD5.inProgress
            }
            layout="vertical"
            onFinish={onFinish}
          >
            <Form.Item
              name="description"
              label={t("Commentaire")}
              rules={[
                { required: true, message: t("Ce champ est obligatoire") },
                { min: 10, message: t("Minimum 10 caractères requis") },
                {
                  whitespace: true,
                  message: t("Les espaces blancs ne sont pas autorisés"),
                },
              ]}
            >
              <TextArea rows={4} />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                {t("Soumettre")}
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
    </div>
  );
};

export default ReclamationRecord;
