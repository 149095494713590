import { useContext, useState, useEffect } from "react";
import {
  Row,
  Col,
  Breadcrumb,
  Button,
  Select,
  Badge,
  Dropdown,
  Menu,
  Tooltip,
  Modal,
  List,
} from "antd";
import { BellOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import GlobalContext from "../../context/GlobalContext";
import tunisie from "../../assets/tunisia.png";
import classes from "./Header.module.css";
import { getNotifictaions } from "../../api/geoprod";
import { useLoader } from "../../context/LoaderContext";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../utils/axios";
import logo from "../../assets/LogoMinistère.svg";
const toggler = [
  <svg
    width="20"
    height="20"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    key={0}
  >
    <path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
  </svg>,
];

function Header({ subName, onPress }) {
  const { Option } = Select;
  const { globalData, setGlobalData, isRtl, setIsRtl } =
    useContext(GlobalContext);
  const { setIsLoading } = useLoader();

  const { i18n, t } = useTranslation();
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [readAllItems, setReadAllItems] = useState(false);
  const selectedOption = sessionStorage.getItem("selectedOption");
  const navigate = useNavigate();
  useEffect(() => {
    const fetchNotifications = async () => {
      const data = await getNotifictaions();
      setNotifications(data.notifications);
      setUnreadCount(data.nbr_not_viewed);
    };
    fetchNotifications();
  }, [readAllItems]);

  const handleLanguageChange = (value) => {
    localStorage.setItem("language", value);
    setGlobalData({
      ...globalData,
      language: value,
    });
    i18n.changeLanguage(value);

    if (value === "ar") {
      setIsRtl(true);
    } else {
      setIsRtl(false);
    }
  };

  const handleNotificationClick = async (notification) => {
    setIsLoading(true);
    axiosInstance
      .put(
        `${import.meta.env.VITE_APP_GEOPROD_API}/update_notification/${
          notification.id
        }`,
        {},
        {
          headers: {
            Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
              .Acces_Token,
          },
        }
      )
      .then(() => {
        notification?.titre?.toLowerCase()?.includes("commentaire")
          ? navigate(notification.url, {
              state: {
                id_affaire: notification.id,
              },
            })
          : navigate("/demandes", {
              state: {
                id_affaire: notification.id,
              },
            });
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  const viderNotification = () => {
    setIsLoading(true);
    axiosInstance
      .put(
        `${import.meta.env.VITE_APP_GEOPROD_API}/view_all_notifications`,
        {},
        {
          headers: {
            Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
              .Acces_Token,
          },
        }
      )
      .then(() => {
        setUnreadCount(0);
        setIsLoading(false);
        setReadAllItems(true);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedNotification(null);
  };

  const notificationMenu = (
    <Menu>
      <h3
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px 16px",
        }}
      >
        {t("Notifications")}
        <Button
          type="text"
          className={classes.btn}
          onClick={viderNotification}
          disabled={unreadCount === 0}
        >
          {t("Tout marquer comme lu")}
        </Button>
      </h3>
      {notifications?.length > 0 ? (
        <List
          className="header-notifications-dropdown"
          itemLayout="vertical"
          dataSource={notifications}
          renderItem={(item) => (
            <Menu.Item
              key={item.id}
              onClick={() => handleNotificationClick(item)}
              style={{
                borderBottom: "1px solid #f0f0f0",
                borderTop: "1px solid #f0f0f0",
                backgroundColor: item.status === 1 ? "#f5f5f5" : "#ffffff", // Gray background for read notifications
                opacity: item.status === 1 ? 0.6 : 1, // Reduce opacity for read notifications
              }}
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Badge
                    status={item.nb_view === 0 ? "processing" : "default"}
                    text={<span>{t("Nouvelle notification")}</span>}
                  />

                  <div
                    style={{
                      color: "#1890ff",
                      fontSize: "12px",
                    }}
                  >
                    {item.date_notification} {item.heure_notification}
                  </div>
                </div>
                <p style={{ fontSize: 12, marginLeft: 15 }}>{t(item.titre)}</p>
              </div>
            </Menu.Item>
          )}
        />
      ) : (
        <Menu.Item>
          <div>{t("Aucune notification")}</div>
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <>
      <Row gutter={[24, 0]} className={classes.headerClass}>
        <Col span={24} md={6}>
          <Breadcrumb
            items={[
              {
                title: (
                  <div
                    style={{ display: "flex", alignItems: "start", gap: 9,flexDirection:"column" }}
                  >
                    {/* <div> */}
                      <div>{t("VOUS ÊTES DANS :")}</div>
                      <div className="ant-page-header-heading">
                        <span className="ant-page-header-heading-title">
                          {subName === "etablissements/save"
                            ? t("Création d'un nouvel EPF")
                            : subName === "etablissements"
                            ? t("Liste de mes EPFs")
                            : subName === "demandes"
                            ? t("Liste de mes demandes en cours")
                            : subName === "historique"
                            ? t("Liste de mes demandes")
                            : subName === "taches"
                            ? t("Liste de mes tâches")
                            : subName === "brouillon"
                            ? t("Liste de mes brouillons")
                            : subName === "formateurs"
                            ? t("Liste des formateurs")
                            : subName === "userProfil"
                            ? t("Mes Informations")
                            : subName === "reclamation"
                            ? t("Réclamation technique")
                            : subName === "profile"
                            ? t("Profil")
                            : subName === "formations"
                            ? t("Liste des formations")
                            : (subName === "etablissements/edit" || subName==="investisseur/changer")
                            ? selectedOption === "avec_bo_validation"
                              ? t("Modification EPF affectant le BO")
                              : selectedOption === "sans_bo_sans_validation"
                              ? t("Modification EPF sans validation")
                              : t("Modification EPF sans impact sur le BO")
                            : subName === "complement/dossier"
                            ? t("Complément de dossier")
                            : subName === "complement/validation/provisoire"
                            ? t("Étape de l'obtention de la validation finale")
                            : subName === "annexes"
                            ? t("Liste de mes Annexes")
                            : subName === "annexes/save"
                            ? t("Ajouter une annexe")
                            : subName === "userProfil"
                            ? t("Profil")
                            : subName === "etablissements/existante/save"
                            ? t("Rajout d'un epf existant"): subName === "directeurs"
                            ? t("Liste des directeurs") :
                            subName === "profil/directeur"
                            ? t("Données de Directeur de l’Établissement")
                            
                            
                            : t("acceuil")}
                        </span>
                      </div>
                    {/* </div> */}
                  </div>
                ),
              },
            ]}
          />
        </Col>
        <Col span={24} md={18} className="header-control">
          <Button
            type="link"
            className="sidebar-toggler"
            onClick={() => onPress()}
          >
            {toggler}
          </Button>

          {/* <img src={tunisie} style={{ height: "5rem" }} /> */}
          <img src={logo} style={{ height: "5rem" }} />

          <Select
            style={{
              width: "8rem",
              display: "flex",
              alignItems: "center",
              textAlign: i18n.dir() === "rtl" ? "right" : "left",
            }}
            onChange={handleLanguageChange}
            value={i18n?.language || localStorage.getItem("language") || "fr"}
          >
            <Option value="fr">Français</Option>
            <Option value="ar">العربية</Option>
          </Select>
          <Dropdown overlay={notificationMenu} trigger={["click"]}>
            <Badge count={unreadCount} overflowCount={99}>
              <Tooltip title={t("Notifications")}>
                <BellOutlined
                  style={{
                    fontSize: "20px",
                    cursor: "pointer",
                    marginInline: "8px",
                  }}
                />
              </Tooltip>
            </Badge>
          </Dropdown>
        </Col>
      </Row>

      {/* Notification Modal */}
      {selectedNotification && (
        <Modal
          title={selectedNotification.titre}
          open={isModalVisible}
          onCancel={handleModalClose}
          footer={null}
        >
          <div
            dangerouslySetInnerHTML={{ __html: selectedNotification.msg }}
          ></div>
          <small>
            {selectedNotification.date_notification} -{" "}
            {selectedNotification.heure_notification}
          </small>
        </Modal>
      )}
    </>
  );
}

export default Header;
