import React, { useContext, useEffect } from "react";
import { Model, CustomWidgetCollection, surveyLocalization } from "survey-core";
import { useLoader } from "../../context/LoaderContext";
import { Survey } from "survey-react-ui";
import GlobalContext from "../../context/GlobalContext";
import { fetchSpetialityList, fetchSubSectorList } from "../../context/FetchLists";

surveyLocalization.defaultLocale = "fr";
let survey = new Model();
const FormationDetails = () => {
  const { getSurveyJson, getSurveyData } = useLoader();
  const { secteurData } = useContext(GlobalContext);
  const getData = async () => {
    const data = await getSurveyData();
    const training_nature_data = {
      training_nature_data: [data.training_nature_data],
    };
    survey.data = training_nature_data;

    survey.getAllQuestions().forEach((question) => {
        question.readOnly=true
      if (question.name === "training_nature_data") {
        const dynamicPanel = question;

        dynamicPanel?.panels.forEach(async (panel) => {
          const sectorQuestion = panel.getQuestionByName(
            "training_type_data_sector"
          );
          const subSectorQuestion = panel.getQuestionByName(
            "training_type_data_sub_sector"
          );
          const specialtyQuestion = panel.getQuestionByName(
            "training_type_data_specialty"
          );

          sectorQuestion.choices = secteurData;

          const selectedSector = sectorQuestion?.value;

          if (selectedSector) {
            const id = secteurData.find(
              (elem) => elem.value === selectedSector
            )?.id;
            const listSousSecteurs = await fetchSubSectorList(id);

            if (listSousSecteurs) {
              subSectorQuestion.choices = listSousSecteurs;
            } else {
              subSectorQuestion.choices = [];
            }

            const selectedSubSector = subSectorQuestion?.value;

            if (selectedSubSector) {
              const id = listSousSecteurs.find(
                (elem) => elem.value === selectedSubSector
              )?.id;
              const listSpecialiteDiplomes = await fetchSpetialityList(id);

              if (listSpecialiteDiplomes) {
                specialtyQuestion.choices = listSpecialiteDiplomes.map(
                  (item) => item[0].spetialite
                );
              } else {
                specialtyQuestion.choices = [];
              }
            }
          }
        });
      }
    });
    survey.showProgressBar = "off";
    survey.showNavigationButtons = false;
    survey.showPreviewBeforeComplete = false;
  };
  useEffect(() => {
    async function initSurvey() {
      const surveyJson = getSurveyJson();

      if (surveyJson) {
        survey.setJsonObject(surveyJson);

        getData();
      }
    }
    initSurvey();
    return () => {
      survey.dispose(true, true);
      survey = new Model();
    };
  }, []);

  return (
    <div style={{ direction: "ltr" }}>
      <Survey model={survey} />
    </div>
  );
};

export default FormationDetails;
