import { DatePicker, Form, Modal } from "antd";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import GlobalContext from "../../../context/GlobalContext";
import { useLoader } from "../../../context/LoaderContext";

const ModalDateFinContrat = (props) => {
  const { dateFinContrat, setDateFinContrat, surveyEdit } = props;
  const { t } = useTranslation();
  const { form } = useContext(GlobalContext);
  const { getSurveyData, setSurveyData } = useLoader();

  return (
    <Modal
      title={
        dateFinContrat.trainer
          ? t("Veuillez entrer la date du fin de contrat de ce formateur")
          : t("Veuillez entrer la date du fin")
      }
      open={!!dateFinContrat}
      onOk={async () => {
        form.validateFields().then(async () => {
          const originalHandler =
            surveyEdit.onDynamicPanelRemoving.callbacks.slice();

          surveyEdit.onDynamicPanelRemoving.clear();
          if (dateFinContrat.trainer) {
            const trainer_data = surveyEdit.getQuestionByName("trainer_data");
            if (trainer_data) {
              const panelIndex = trainer_data.panels.findIndex(
                (p) => p === dateFinContrat.panel.panel
              );

              if (panelIndex !== -1) {
                trainer_data.removePanel(panelIndex);
              }
              // trainer_data.setValue(
              //   "trainer_data_date_fin_contrat",
              //   form.getFieldsValue("dateFinContrat")
              // );

              // let surveyData = await getSurveyData();

              // if (surveyData) {
              //   surveyData.trainer_data[panelIndex] = {
              //     ...surveyData.trainer_data[panelIndex],
              //     trainer_data_date_fin_contrat:
              //       form.getFieldsValue("dateFinContrat"),
              //   };
              //   setSurveyData(surveyData);
              // }
            }
          }

          if (dateFinContrat.training) {
            const training_nature_data = surveyEdit.getQuestionByName(
              "training_nature_data"
            );
            if (training_nature_data) {
              const panelIndex = training_nature_data.panels.findIndex(
                (p) => p === dateFinContrat.panel.panel
              );

              if (panelIndex !== -1) {
                training_nature_data.removePanel(panelIndex);
              }
            }

            // let surveyData = await getSurveyData();

            // if (surveyData) {
            //   surveyData.training_nature_data[panelIndex] = {
            //     ...surveyData.training_nature_data[panelIndex],
            //     training_date_fin: form.getFieldsValue("dateFinContrat"),
            //   };
            //   setSurveyData(surveyData);
            // }
          }
          originalHandler.forEach((callback) => {
            surveyEdit.onDynamicPanelRemoving.add(callback);
          });
          form.resetFields();
          setDateFinContrat("");
        });
      }}
      onCancel={() => setDateFinContrat("")}
      okText={t("Confirmer")}
      cancelText={t("Annuler")}
    >
      <Form layout="vertical" form={form}>
        <Form.Item
          label={
            dateFinContrat.trainer
              ? t("Date de fin de contrat")
              : t("Date de fin")
          }
          name="dateFinContrat"
          rules={[
            {
              required: true,
              message: t("Veuillez remplir ce champ."),
            },
          ]}
        >
          <DatePicker
            placeholder={t("DD/MM/YYYY")}
            style={{ width: "100%" }}
            format="DD/MM/YYYY"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalDateFinContrat;
