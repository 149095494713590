import { useEffect, useState } from "react";
import { Tabs, Form, Input, Button, Table, notification, Space } from "antd";
import { useTranslation } from "react-i18next";
import styles from "./Reclamation.module.css";
import { addCommentToTicket, getTickets, saveTicket } from "../../api/geoprod";
import { TICKET_STATUS } from "../../utils/constants";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

const { TabPane } = Tabs;
const { TextArea } = Input;

function Reclamation() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [reclamations, setReclamations] = useState([]);
  const [retrigger, setRetrigger] = useState(false);
  const [activeKey, setactiveKey] = useState("1");
  const columns = [
    {
      title: t("Date"),
      dataIndex: "ticket_date_creation",
      key: "date",
      render: (_, record) => (
        <span>
          {dayjs(record?.ticket_date_creation).format("DD/MM/YYYY HH:mm:ss")}
        </span>
      ),
    },
    {
      title: t("Sujet"),
      dataIndex: "ticket_titre",
      key: "subject",
    },
    {
      title: t("Statut"),
      key: "status",
      render: (_, record) => {
        return (
          <div
            style={{
              color: record?.statut_libelle?.[0]?.color,
              backgroundColor: record?.statut_libelle?.[0]?.background_color,
              border: `1px solid ${record?.statut_libelle?.[0]?.color}`,
              padding: "3px 10px",
              fontSize: 11,
              borderRadius: 10,
            }}
          >
            {t(record?.statut_libelle?.[0]?.libelle)}
          </div>
        );
      },
    },
    {
      title: t("Actions"),
      render: (_, record) => (
        <Space>
          <Link to={`/reclamation/${record?.id_ticket}`}>{t("Voir")}</Link>
        </Space>
      ),
    },
  ];

  const onFinish = async (values) => {
    const newReclamation = {
      choix: "t",
      commentaire: values.description,
      statut: TICKET_STATUS.pending,
      title: values.subject,
    };

    const response = await saveTicket(newReclamation);

    if (response.data.error) {
      return notification.error({
        message: t("Erreur"),
        description: response.data.message,
      });
    } else {
      const commentResponse = await addCommentToTicket(
        response.data.id,
        values.description
      );

      if (commentResponse.data.error) {
        return notification.error({
          message: t("Erreur"),
          description: commentResponse.data.message,
        });
      }

      notification.success({
        message: t("Réussi"),
        description: t("Votre réclamation a été enregistrée avec succès"),
        duration: 0,
      });
      setRetrigger(!retrigger);
    }

    form.resetFields();
    setactiveKey("2")

  };

  useEffect(() => {
    const fetchReclamations = async () => {
      const response = await getTickets();

      setReclamations(response.data.aaData);
    };

    fetchReclamations();
  }, [retrigger]);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <h1 className={styles.title}>{t("Réclamation technique")}</h1>

        <Tabs defaultActiveKey="1" activeKey={activeKey} onChange={(key)=>setactiveKey(key)}>
          <TabPane tab={t("Nouvelle Réclamation Technique")} key="1">
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              className={styles.form}
            >
              <Form.Item
                name="subject"
                label={t("Sujet")}
                rules={[
                  { required: true, message: t("Ce champ est obligatoire") },
                  { min: 4, message: t("Minimum 4 caractères requis") },
                  {
                    whitespace: true,
                    message: t("Les espaces blancs ne sont pas autorisés"),
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="description"
                label={t("Description")}
                rules={[
                  { required: true, message: t("Ce champ est obligatoire") },
                  { min: 10, message: t("Minimum 10 caractères requis") },
                  {
                    whitespace: true,
                    message: t("Les espaces blancs ne sont pas autorisés"),
                  },
                ]}
              >
                <TextArea rows={4} />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  {t("Soumettre")}
                </Button>
              </Form.Item>
            </Form>
          </TabPane>

          <TabPane tab={t("Historique")} key="2">
            <Table
              columns={columns}
              dataSource={reclamations}
              pagination={{ pageSize: 5 }}
              locale={{
                emptyText: t("Aucune réclamation trouvée"),
              }}
            />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
}

export default Reclamation;
