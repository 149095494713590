import React, { useState, useEffect, useContext } from "react";
import {
  Row,
  Card,
  Table,
  Space,
  Spin,
  notification,
  Dropdown,
  Button,
  Modal,
} from "antd";
import { InfoCircleOutlined, DownOutlined } from "@ant-design/icons";
import SelectionModal from "../Etablissements/SelectionModal/SelectionModal";
import classes from "../Etablissements/Etablissements.module.css";
import { useTranslation } from "react-i18next";
import {
  getAffaires,
  getDocByIdAffaire,
  getSchema,
  updateActionAffaire,
} from "../../api/geoprod";
import dayjs from "dayjs";
import GlobalContext from "../../context/GlobalContext";
import { useNavigate } from "react-router-dom";
import { useLoader } from "../../context/LoaderContext";
import CommentModal from "../Etablissements/CreateEpf/Modals/CommentModal";
import axiosInstance from "../../utils/axios";
import DownloadDocsModal from "../Etablissements/DownloadDocsModal";

const Historique = () => {
  const { t, i18n } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState();
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(true);
  const { setBrouillon } = useContext(GlobalContext);
  const [commentaire, setCommentaire] = useState([]);
  const idAffaire = new URLSearchParams(window.location.search).get("id");
  const [openModal, setOpenModal] = useState([]);
  const navigate = useNavigate();
  const {
    setIsLoading,
    setSurveyJson,
    setSurveyData,
    setSurveyJsonValidationProvisoire,
    setShowingPreview,
  } = useLoader();
  const [loader, setLoader] = useState(false);
  const [docs, setDocs] = useState([]);
  const [openDocsModal, setOpenDocsModal] = useState(false);

  const idsEtatsDemandesInvisiblesInvestisseur = [
    parseInt(import.meta.env.VITE_APP_ETAT_DEMANDE_AVIS_POSITIF_CREATION_EPF),
    parseInt(
      import.meta.env.VITE_APP_ETAT_DEMANDE_AVIS_POSITIF_MODIFICATION_EPF
    ),
    parseInt(
      import.meta.env.VITE_APP_ETAT_DEMANDE_AVIS_POSITIF_CREATION_EPF_EXISTANT
    ),
    parseInt(
      import.meta.env
        .VITE_APP_ETAT_AVIS_DEFAVORABLE_SERVICE_PRE_DECISION_CREATION_EPF
    ),
    parseInt(
      import.meta.env
        .VITE_APP_ETAT_AVIS_DEFAVORABLE_SERVICE_DECISION_PROPOSEE_CREATION_EPF
    ),
    parseInt(
      import.meta.env
        .VITE_APP_ETAT_AVIS_DEFAVORABLE_SERVICE_PRE_DECISION_MODIFICATION_EPF
    ),
    parseInt(
      import.meta.env
        .VITE_APP_ETAT_AVIS_DEFAVORABLE_SERVICE_PRE_DECISION_CREATION_EPF_EXISTANT
    ),

    parseInt(import.meta.env.VITE_APP_ETAT_RESERVE_DR_REGIONAL_CREATION_EPF),
    parseInt(
      import.meta.env.VITE_APP_ETAT_RESERVE_DR_REGIONAL_MODIFICATION_EPF
    ),
    parseInt(
      import.meta.env.VITE_APP_ETAT_RESERVE_DR_REGIONAL_CREATION_EPF_EXISTANT
    ),
  ];
  const columns = [
    {
      title: t("Dénomination"),
      key: "dénomination",
      render: (_, record) =>
        record?.establishment_data_commercial_name ||
        record?.establishment_data_official_name ||
        record?.legal_entity_official_name,
    },
    {
      title: t("Directeur"),
      dataIndex: "directeur",
      key: "directeur",
      render: (_, record) =>
        record?.establishment_director_data_last_name
          ? record?.establishment_director_data_last_name +
            " " +
            record?.establishment_director_data_first_name
          : "",
    },
    {
      title: t("Gouvernorat"),
      key: "gouvernorat",
      render: (_, record) => record?.establishment_data_governorate,
    },
    {
      title: t("Délégation"),
      dataIndex: "délégation",
      key: "délégation",
      render: (_, record) => record?.establishment_data_delegation,
    },
    {
      title: t("Matricule fiscal"),
      key: "matricule",
      render: (_, record) =>
        record.establishment_data_tax_number ||
        record.legal_entity_tax_number ||
        record?.numero_matricule_fiscal_numero_IURNE ||
        record?.numero_matricule_fiscal_IURNE ||
        t("Non renseigné"),
    },
    {
      title: t("Numéro de téléphone"),
      key: "phoneNum",
      render: (_, record) => record?.investor_phone_number,
    },
    {
      title: t("Nature de formation"),
      key: "natureForm",
      render: (_, record) => record?.establishment_data_training_type,
    },
    {
      title: t("Créateur de la demande"),
      key: "Créateur de la demande",
      render: (_, record) =>
        localStorage.getItem("language") === "ar" && record?.nom_createur_ar
          ? record.nom_createur_ar + " " + record?.prenom_createur_ar
          : record?.nom_createur + " " + record?.prenom_createur,
    },
    {
      title: t("Statut"),
      key: "statut",
      render: (_, record) => {
        return (
          <span
            style={{
              padding: "4px 10px",
              borderRadius: "5px",
              fontSize: "12px",
              backgroundColor: idsEtatsDemandesInvisiblesInvestisseur?.includes(
                record?.id_etat_dossier
              )
                ? "#EEEEEE"
                : record?.etat_background,
              color: idsEtatsDemandesInvisiblesInvestisseur?.includes(
                record?.id_etat_dossier
              )
                ? "#000000"
                : record?.etat_color,
            }}
          >
            {idsEtatsDemandesInvisiblesInvestisseur?.includes(
              record?.id_etat_dossier
            )
              ? t("En cours de traitement")
              : t(record?.etat)}
          </span>
        );
      },
    },
    {
      title: t("Type"),
      key: "statut",
      render: (_, record) => {
        return (
          <span
            style={{
              padding: "4px 10px",
              borderRadius: "5px",
              fontSize: "12px",
              backgroundColor: idsEtatsDemandesInvisiblesInvestisseur?.includes(
                record?.id_etat_dossier
              )
                ? "#EEEEEE"
                : record?.etat_background,
              color: idsEtatsDemandesInvisiblesInvestisseur?.includes(
                record?.id_etat_dossier
              )
                ? "#000000"
                : record?.etat_color,
            }}
          >
            {
            (record?.affaire_id_contrat=== import.meta.env.VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_AVEC_BO || 
            record?.affaire_id_contrat=== import.meta.env.VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_SANS_BO ||
            record?.affaire_id_contrat=== import.meta.env.VITE_APP_LIGNE_PRODUIT_MODIFIACTION_SANS_VALIDATION_SANS_BO ) &&  
            record?.origin_contrat_id ===
            import.meta.env.VITE_ID_LIGNE_PRODUIT_ANNEXE ?
            t(record.contrat_type.replace("EPF","annexe")) :
            t(record.contrat_type)}
          </span>
        );
      },
    },
    {
      title: t("Date de soumission"),
      key: "Date de soumission",
      render: (_, record) =>
        record?.affaire_date_creation
          ? dayjs(record?.affaire_date_creation).format("DD/MM/YYYY HH:MM:ss")
          : t("Non renseigné"),
    },
    {
      title: t("Actions"),
      key: "Actions",
      render: (_, record) => (
        <Space>
          <Dropdown
            menu={{
              items: [
                {
                  label: (
                    <Button
                      size="small"
                      block
                      onClick={() => {
                        sessionStorage.setItem("consulter", true);
                        setIsLoading(true);
                        setShowingPreview(true);

                        axiosInstance
                          .get(
                            import.meta.env.VITE_APP_GEOPROD_API +
                              "/get_info_specifique/" +
                              record?.affaire_id,
                            {
                              headers: {
                                Idsession: JSON.parse(
                                  sessionStorage.getItem("-x-token-user")
                                )?.Acces_Token,
                              },
                            }
                          )
                          .then(async (res) => {
                            setIsLoading(false);
                            setSurveyJson(res?.data?.response?.form);

                            sessionStorage.setItem("demande", true);
                            const { form, data, current_step } =
                              res?.data?.response;
                            const navigateTo = determineNavigationPath(
                              record,
                              form
                            );
                            if (
                              sessionStorage.getItem("role") === "directeur"
                            ) {
                              sessionStorage.setItem(
                                "user_id_to_get",
                                res?.data?.response?.responsable_EPF
                              );
                            } else {
                              sessionStorage.setItem(
                                "user_id_to_get",
                                res?.data?.response?.id_user
                              );
                            }
                            const type =
                              record?.origin_contrat_id ===
                              import.meta.env.VITE_ID_LIGNE_PRODUIT_ANNEXE
                                ? "annexe"
                                : "";
                            if (
                              navigateTo === "/etablissements/existante/save"
                            ) {
                              setSurveyData(res?.data);
                              navigate(navigateTo, {
                                state: {
                                  idAffaireEpfExistant: record.affaire_id,
                                },
                              });
                            } else if (navigateTo === "/etablissements/edit") {
                              setSurveyData(res?.data);
                              navigate(navigateTo, {
                                state: {
                                  type: type,
                                },
                              });
                            } else {
                              navigate(navigateTo, {
                                state: {
                                  id_affaire: record.affaire_id,
                                  etat_demande: record?.id_etat_dossier,
                                  contrat_id: res?.data?.response?.contrat_id,
                                },
                              });
                              setSurveyJsonValidationProvisoire(
                                res?.data?.response?.form_valid
                              );
                              setSurveyData(data);
                            }

                            // if (res?.data?.response?.data) {
                            //   if (
                            //     record?.affaire_id_contrat ==
                            //     import.meta.env.VITE_ID_LIGNE_PRODUIT_ANNEXE
                            //   ) {
                            //     sessionStorage.setItem(
                            //       "id_affaire_annexe",
                            //       record.affaire_id
                            //     );
                            //     navigate(
                            //       "/annexes/save?affaire_id=" + record?.affaire_parent
                            //     );
                            //   } else if (
                            //     record?.affaire_id_contrat ==
                            //     import.meta.env.VITE_ID_LIGNE_PRODUIT_EPF_EXISTANTE
                            //   ) {
                            //     sessionStorage.setItem(
                            //       "idAffaireEpfExistant",
                            //       record.affaire_id
                            //     );
                            //     navigate("/etablissements/existante/save", {
                            //       state: {
                            //         idAffaireEpfExistant: record.affaire_id,
                            //       },
                            //     });
                            //   } else if (
                            //     record?.affaire_id_contrat ==
                            //       import.meta.env
                            //         .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_AVEC_BO &&
                            //     res?.data?.response?.form?.pages?.find(
                            //       (item) => item?.name === "ChnangementInvestisseur"
                            //     )
                            //   ) {
                            //     const changementInvestisseurPage =
                            //       res?.data?.response?.form?.pages?.find(
                            //         (item) => item?.name === "ChnangementInvestisseur"
                            //       );
                            //     setSurveyJson(changementInvestisseurPage);
                            //     sessionStorage.setItem(
                            //       "idAffaireChangementInvestisseur",
                            //       record.affaire_id
                            //     );
                            //     navigate("/investisseur/changer", {
                            //       state: {
                            //         idAffaireChangementInvestisseur: record.affaire_id,
                            //       },
                            //     });
                            //   } else {
                            //     navigate("/etablissements/save", {
                            //       state: {
                            //         id_affaire: record.affaire_id,
                            //         etat_demande: record?.id_etat_dossier,
                            //       },
                            //     });
                            //   }
                            // }
                          })
                          .catch(() => {
                            setIsLoading(false);
                            notification.error({
                              message: t("Erreur"),
                              description:
                                "Une erreur a été rencontré lors de chargement de formulaire de l'epf existant",
                              duration: 0,
                            });
                          });
                      }}
                    >
                      {t("Consulter la demande")}
                    </Button>
                  ),
                },
              ],
            }}
            trigger={["click"]}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                {t("Liste")}
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
        </Space>
      ),
    },
  ];
  const determineSelectedOption = (affaire_id_contrat) => {
    switch (affaire_id_contrat) {
      case import.meta.env
        .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_AVEC_BO:
        return "avec_bo_validation";
      case import.meta.env
        .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_SANS_VALIDATION_SANS_BO:
        return "sans_bo_sans_validation";
      default:
        return "sans_bo_avec_validation";
    }
  };
  const determineNavigationPath = (record, form) => {
    const { affaire_id, affaire_id_contrat } = record;
    const { pages } = form;

    switch (affaire_id_contrat) {
      case import.meta.env.VITE_ID_LIGNE_PRODUIT_ANNEXE:
        sessionStorage.setItem("id_affaire_annexe", affaire_id);
        return "/annexes/save?affaire_id=" + record?.affaire_parent;
      case import.meta.env.VITE_ID_LIGNE_PRODUIT_EPF_EXISTANTE:
        sessionStorage.setItem("idAffaireEpfExistant", affaire_id);
        return "/etablissements/existante/save";
      case import.meta.env
        .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_AVEC_BO:
      case import.meta.env
        .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_SANS_BO:
      case import.meta.env
        .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_SANS_VALIDATION_SANS_BO:
        const pageChangementInvestisseur = pages?.find(
          (item) => item?.name === "ChnangementInvestisseur"
        );
        if (pageChangementInvestisseur) {
          sessionStorage.setItem("idAffaireChangementInvestisseur", affaire_id);
          setSurveyJson(pageChangementInvestisseur);
          return "/investisseur/changer";
        } else {
          const selectedOption = determineSelectedOption(affaire_id_contrat);
          sessionStorage.setItem("selectedOption", selectedOption);
          return `/etablissements/edit`;
        }
      default:
        return "/etablissements/save";
    }
  };
  async function fetchData() {
    try {
      const data = await getAffaires({
        id_user: JSON.parse(sessionStorage.getItem("-x-token-user")).id_user,
        id_entreprise: JSON.parse(sessionStorage.getItem("-x-token-user")).user
          .entreprise_id,
        filters: {
          contrat: [],
          status: [],
          excludedStatus: [
            import.meta.env.VITE_APP_ID_STATUS_BROUILLON_SANS_VALIDATION,
            import.meta.env.VITE_APP_ID_STATUS_BROUILLON_AVEC_VALIDATION,
            import.meta.env.VITE_APP_ID_STATUS_BROUILLON,
            import.meta.env.VITE_APP_ID_STATUS_BROUILLON_EPF_EXISTANT,
          ],
        },
        historique: true,
      });
      setDataSource(data?.aaData);
      let brouillon = data?.aaData.filter(
        (elem) =>
          elem.etat === "Brouillon" &&
          elem.affaire_id_contrat === import.meta.env.VITE_ID_LIGNE_PRODUIT
      );
      const brouillinAnnexe = data?.aaData.filter(
        (elem) =>
          elem.etat === "Brouillon" &&
          elem.affaire_id_contrat ===
            import.meta.env.VITE_ID_LIGNE_PRODUIT_ANNEXE
      );
      if (brouillinAnnexe.length > 0) {
        sessionStorage.setItem(
          "id_affaire_annexe",
          brouillinAnnexe[0]?.affaire_id
        );
        setBrouillon(brouillinAnnexe);
        setLoading(false);
      }
      if (brouillon.length > 0) {
        sessionStorage.setItem("id_affaire", brouillon[0]?.affaire_id);
        setBrouillon(brouillon);
        setLoading(false);
      }
      setIsLoading(false);

      setLoading(false);
    } catch (error) {
      setIsLoading(false);

      notification.error({
        message: t("Erreur"),
        description:
          error?.response?.data?.message ||
          "Une interruption est survenue lors de la récupération des données. Veuillez réessayer.",
      });
    }
  }
  useEffect(() => {
    fetchData();

    if (idAffaire) {
      handleGetComment(idAffaire);
    }
  }, []);

  const handleGetComment = (id_affaire) => {
    setIsLoading(true);
    axiosInstance
      .get(
        `${
          import.meta.env.VITE_APP_GEOPROD_API
        }/commentaire/get_investisseur_commentaires?entity=A&id_entity=${id_affaire}`,
        {
          headers: {
            Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
              ?.Acces_Token,
          },
        }
      )
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.info?.length > 0) {
          setCommentaire(res?.data?.info);
        } else {
          notification.warning({
            message: t(
              "Vous ne disposez pas de commentaire pour cette demande !"
            ),
            duration: 0,
          });
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const AnnulerDemande = async () => {
    setIsLoading(true);
    const id_affaire = openModal[0].id_affaire;
    const affaire_id_contrat = openModal[0].affaire_id_contrat;
    const data = await updateActionAffaire({
      id_affaire: id_affaire,
      id_status:
        affaire_id_contrat ===
          import.meta.env
            .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_AVEC_BO ||
        affaire_id_contrat ===
          import.meta.env
            .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_SANS_BO
          ? import.meta.env.VITE_APP_ID_STATUS_ANNULE_DEAMNDE_MODIFICATION_EPF
          : affaire_id_contrat ===
            import.meta.env.VITE_ID_LIGNE_PRODUIT_EPF_EXISTANTE
          ? import.meta.env
              .VITE_APP_ID_STATUS_ANNULE_DEAMNDE_CREATION_EPF_EXISTANT
          : import.meta.env.VITE_APP_ID_STATUS_ANNULE_DEMANDE_CREATION_EPF,
    });
    if (data) {
      setOpenModal([]);
      fetchData();
      notification.success({
        message: t("Votre demande a bien été annulée."),
        duration: 0,
      });
    } else {
      setOpenModal([]);
      setIsLoading(false);
    }
  };

  return (
    <Spin spinning={loader} tip="" style={{ width: "100%" }}>
      <div>
        <Modal
          open={openModal.length > 0}
          footer={null}
          width={600}
          onCancel={() => setOpenModal([])}
        >
          <h4>
            {" "}
            {t(
              "Êtes-vous sûr de vouloir annuler cette demande ? Cette action est irréversible."
            )}{" "}
          </h4>
          <Button type="primary" onClick={() => AnnulerDemande()}>
            {t("Oui")}
          </Button>
          <Button
            type="primary"
            style={{ marginInline: "15px" }}
            onClick={() => setOpenModal([])}
          >
            {" "}
            {t("Non")}
          </Button>
        </Modal>
        <SelectionModal
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
        />
        <CommentModal
          commentaire={commentaire}
          setCommentaire={setCommentaire}
        />
        <DownloadDocsModal
          docs={docs}
          open={openDocsModal}
          setOpen={setOpenDocsModal}
        />

        <Row className={classes.listeRow}>
          <Card
            className={classes.listeCard}
            bordered={false}
            title={
              <div>
                <div
                  style={{
                    textAlign: i18n?.dir() === "ltr" ? "left" : "right",
                  }}
                >
                  {t("Liste de mes demandes")}
                </div>
              </div>
            }
          >
            <Table
              locale={{ emptyText: t("Aucune donnée") }}
              loading={loading}
              columns={columns}
              dataSource={dataSource}
              className="ant-border-space"
              style={{ overflowX: "auto" }}
              pagination={true}
            />
          </Card>
        </Row>
      </div>
    </Spin>
  );
};

export default Historique;
