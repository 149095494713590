import React, { useContext, useEffect, useState } from "react";
import { Row, Card, Table, Space, Dropdown, Button, notification } from "antd";
import { useTranslation } from "react-i18next";
import GlobalContext from "../../context/GlobalContext";
import classes from "../Etablissements/Etablissements.module.css";
import { useLoader } from "../../context/LoaderContext";
import DownloadDocsModal from "../Etablissements/DownloadDocsModal";
import dayjs from "dayjs";
import { DownOutlined } from "@ant-design/icons";
import axiosInstance from "../../utils/axios";
import { useNavigate } from "react-router-dom";
import { getAffaires, getDocByIdAffaire, getSchema } from "../../api/geoprod";
import CommentModal from "../Etablissements/CreateEpf/Modals/CommentModal";

const Taches = () => {
  const { t, i18n } = useTranslation();
  const {
    setIsLoading,
    setSurveyJson,
    setSurveyData,
    setSurveyJsonValidationProvisoire,
    setShowingPreview,
  } = useLoader();
  const [docs, setDocs] = useState([]);
  const [openDocsModal, setOpenDocsModal] = useState(false);
  const [commentaire, setCommentaire] = useState([]);
  const [dataSource,setDataSource]=useState([])
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const idsEtatsDemandesInvisiblesInvestisseur = [
    parseInt(import.meta.env.VITE_APP_ETAT_DEMANDE_AVIS_POSITIF_CREATION_EPF),
    parseInt(
      import.meta.env.VITE_APP_ETAT_DEMANDE_AVIS_POSITIF_MODIFICATION_EPF
    ),
    parseInt(
      import.meta.env.VITE_APP_ETAT_DEMANDE_AVIS_POSITIF_CREATION_EPF_EXISTANT
    ),
    parseInt(
      import.meta.env
        .VITE_APP_ETAT_AVIS_DEFAVORABLE_SERVICE_PRE_DECISION_CREATION_EPF
    ),
    parseInt(
      import.meta.env
        .VITE_APP_ETAT_AVIS_DEFAVORABLE_SERVICE_DECISION_PROPOSEE_CREATION_EPF
    ),
    parseInt(
      import.meta.env
        .VITE_APP_ETAT_AVIS_DEFAVORABLE_SERVICE_PRE_DECISION_MODIFICATION_EPF
    ),
    parseInt(
      import.meta.env
        .VITE_APP_ETAT_AVIS_DEFAVORABLE_SERVICE_PRE_DECISION_CREATION_EPF_EXISTANT
    ),

    parseInt(import.meta.env.VITE_APP_ETAT_RESERVE_DR_REGIONAL_CREATION_EPF),
    parseInt(
      import.meta.env.VITE_APP_ETAT_RESERVE_DR_REGIONAL_MODIFICATION_EPF
    ),
    parseInt(
      import.meta.env.VITE_APP_ETAT_RESERVE_DR_REGIONAL_CREATION_EPF_EXISTANT
    ),
  ];
  const columns = [
    {
      title: t("Dénomination"),
      key: "dénomination",
      render: (_, record) =>
        record?.establishment_data_commercial_name ||
        record?.establishment_data_official_name ||
        record?.legal_entity_official_name,
    },
    {
      title: t("Directeur"),
      dataIndex: "directeur",
      key: "directeur",
      render: (_, record) =>
        record?.establishment_director_data_last_name
          ? record?.establishment_director_data_last_name +
            " " +
            record?.establishment_director_data_first_name
          : "",
    },
    {
      title: t("Gouvernorat"),
      key: "gouvernorat",
      render: (_, record) => record?.establishment_data_governorate,
    },
    {
      title: t("Délégation"),
      dataIndex: "délégation",
      key: "délégation",
      render: (_, record) => record?.establishment_data_delegation,
    },
    {
      title: t("Matricule fiscal"),
      key: "matricule",
      render: (_, record) =>
        record.establishment_data_tax_number ||
        record.legal_entity_tax_number ||
        record?.numero_matricule_fiscal_numero_IURNE || record?.numero_matricule_fiscal_IURNE ||
        t("Non renseigné"),
    },
    {
      title: t("Numéro de téléphone"),
      key: "phoneNum",
      render: (_, record) => record?.investor_phone_number,
    },
    {
      title: t("Nature de formation"),
      key: "natureForm",
      render: (_, record) => record?.establishment_data_training_type,
    },
    {
      title: t("Créateur de la demande"),
      key: "Créateur de la demande",
      render: (_, record) =>
        localStorage.getItem("language") ==="ar" && record?.nom_createur_ar ?  record.nom_createur_ar +" "+ record?.prenom_createur_ar: record?.nom_createur + " " + record?.prenom_createur ,
    },
    {
      title: t("Statut"),
      key: "statut",
      render: (_, record) => {
        return (
          <span
            style={{
              padding: "4px 10px",
              borderRadius: "5px",
              fontSize: "12px",
              backgroundColor: idsEtatsDemandesInvisiblesInvestisseur?.includes(
                record?.id_etat_dossier
              )
                ? "#EEEEEE"
                : record?.etat_background,
              color: idsEtatsDemandesInvisiblesInvestisseur?.includes(
                record?.id_etat_dossier
              )
                ? "#000000"
                : record?.etat_color,
            }}
          >
            {idsEtatsDemandesInvisiblesInvestisseur?.includes(
              record?.id_etat_dossier
            )
              ? t("En cours de traitement")
              : t(record?.etat)}
          </span>
        );
      },
    },
    {
      title: t("Type"),
      key: "statut",
      render: (_, record) => {
        return (
          <span
            style={{
              padding: "4px 10px",
              borderRadius: "5px",
              fontSize: "12px",
              backgroundColor: idsEtatsDemandesInvisiblesInvestisseur?.includes(
                record?.id_etat_dossier
              )
                ? "#EEEEEE"
                : record?.etat_background,
              color: idsEtatsDemandesInvisiblesInvestisseur?.includes(
                record?.id_etat_dossier
              )
                ? "#000000"
                : record?.etat_color,
            }}
          >
            {t(record.contrat_type)}
          </span>
        );
      },
    },
    {
      title: t("Date de soumission"),
      key: "Date de soumission",
      render: (_, record) =>
        record?.affaire_date_creation
          ? dayjs(record?.affaire_date_creation).format("DD/MM/YYYY HH:MM:ss")
          : t("Non renseigné"),
    },
    {
      title: t("Actions"),
      key: "Actions",
      render: (_, record) => (
        <Space>
          <Dropdown
            menu={{
              items: [
                {
                  label: (
                    <Button
                      size="small"
                      block
                      onClick={() => {
                        sessionStorage.setItem("consulter", true);
                        setIsLoading(true);
                        // sessionStorage.setItem("showingPreview", true);
                        setShowingPreview(true);

                        axiosInstance
                          .get(
                            import.meta.env.VITE_APP_GEOPROD_API +
                              "/get_info_specifique/" +
                              record?.affaire_id,
                            {
                              headers: {
                                Idsession: JSON.parse(
                                  sessionStorage.getItem("-x-token-user")
                                )?.Acces_Token,
                              },
                            }
                          )
                          .then(async (res) => {
                            setIsLoading(false);
                            setSurveyJson(res?.data?.response?.form);

                            sessionStorage.setItem("demande", true);
                            const { form, data, current_step } =
                              res?.data?.response;
                            const navigateTo = determineNavigationPath(
                              record,
                              form
                            );
                            if (
                              sessionStorage.getItem("role") === "directeur"
                            ) {
                              sessionStorage.setItem(
                                "user_id_to_get",
                                res?.data?.response?.responsable_EPF
                              );
                            } else {
                              sessionStorage.setItem(
                                "user_id_to_get",
                                res?.data?.response?.id_user
                              );
                            }
                            if (
                              navigateTo === "/etablissements/existante/save"
                            ) {
                              setSurveyData(res?.data);
                              navigate(navigateTo, {
                                state: {
                                  idAffaireEpfExistant: record.affaire_id,
                                },
                              });
                            } else if (navigateTo === "/etablissements/edit") {
                              setSurveyData(res?.data);
                              navigate(navigateTo);
                            } else {
                              navigate(navigateTo, {
                                state: {
                                  id_affaire: record.affaire_id,
                                  etat_demande: record?.id_etat_dossier,
                                  contrat_id: res?.data?.response?.contrat_id,
                                },
                              });
                              setSurveyJsonValidationProvisoire(
                                res?.data?.response?.form_valid
                              );
                              setSurveyData(data);
                            }
                          })
                          .catch(() => {
                            setIsLoading(false);
                            notification.error({
                              message: t("Erreur"),
                              description:
                                "Une erreur a été rencontré lors de chargement de formulaire de l'epf existant",
                              duration: 0,
                            });
                          });
                      }}
                    >
                      {t("Consulter la demande")}
                    </Button>
                  ),
                },
                (record?.id_etat_dossier ===
                  parseInt(import.meta.env.VITE_APP_ETAT_COMPLEMENT_DOSSIER) ||
                  record?.id_etat_dossier ===
                    parseInt(
                      import.meta.env
                        .VITE_APP_ETAT_COMPLEMENT_DOSSIER_MODIFICATION
                    ) ||
                  record?.id_etat_dossier ===
                    parseInt(
                      import.meta.env
                        .VITE_APP_ETAT_COMPLEMENT_DOSSIER_EPF_EXISTANT
                    )) && {
                  label: (
                    sessionStorage.getItem("role") !== "directeur" &&  <Button
                      block
                      size="small"
                      onClick={async () => {
                        // setLoader(true);
                        const data = await getSchema(record.affaire_id);
                        setShowingPreview(false);
                        navigate("/complement/dossier", {
                          state: {
                            id_affaire: record?.affaire_id,
                            data_complement_dossier:
                              data?.response?.complement_dossier,
                            complement_dossier_uploaded:
                              data?.response?.key_urls,
                            affaire_id_contrat: record?.affaire_id_contrat,
                          },
                        });
                        // }
                        // setLoader(false);
                      }}
                    >
                      {t("Compléter la demande")}
                    </Button>
                  ),
                },
                (record?.id_etat_dossier ===
                  parseInt(
                    import.meta.env.VITE_APP_ETAT_VALIDATION_PROVISOIRE
                  ) ||
                  record?.id_etat_dossier ===
                    parseInt(
                      import.meta.env
                        .VITE_APP_ETAT_COMPLEMENT_VALIDATION_PROVISOIRE
                    ) ||
                  record?.id_etat_dossier ===
                    parseInt(
                      import.meta.env
                        .VITE_APP_ETAT_VALIDATION_PROVISOIRE_PREDECISION
                    )) && {
                  label: (
                    sessionStorage.getItem("role") !== "directeur" &&  <Button
                      size="small"
                      block
                      onClick={async () => {
                        setShowingPreview(false);
                        sessionStorage.setItem("consulter", false);
                        setIsLoading(true);
                        const data = await getSchema(record.affaire_id);
                        setSurveyJson(data?.response?.form_valid);

                        await setSurveyData(data?.response?.data);
                        setSurveyJsonValidationProvisoire(
                          data?.response?.form_valid
                        );

                        navigate("/complement/validation/provisoire", {
                          state: {
                            id_affaire: record?.affaire_id,
                            etat_demande: record?.id_etat_dossier,
                            //hello
                            contrat_id: data?.response?.contrat_id,
                          },
                        });
                        setIsLoading(false);
                      }}
                    >
                      {t("Étape de l'obtention de la validation finale")}
                    </Button>
                  ),
                },
                record?.affaire_id_contrat !==
                  import.meta.env
                    .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_AVEC_BO &&
                  record?.affaire_id_contrat !==
                    import.meta.env
                      .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_SANS_BO &&
                  record?.affaire_id_contrat !==
                    import.meta.env
                      .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_SANS_VALIDATION_SANS_BO && {
                    label: (
                      <Button
                        block
                        size="small"
                        onClick={async () => {
                          setIsLoading(true);
                          try {
                            const data = await getDocByIdAffaire(
                              record?.affaire_id
                            );
                            const documents = data?.data?.find(
                              (doc) =>
                                doc?.md5_id ===
                                import.meta.env.VITE_APP_ID_DOCUMENTS_RECU_EMAIL
                            );

                            setDocs([documents]);
                            setOpenDocsModal(true);
                          } catch (error) {
                            console.error(
                              "Erreur lors du téléchargement :",
                              error
                            );
                          } finally {
                            setIsLoading(false);
                          }
                        }}
                      >
                        {t("Télécharger les documents ")}
                      </Button>
                      // </Popconfirm>
                    ),
                    // children: documents?.files?.map((file, index) => ({
                    //     label: (
                    //       <a href={file?.url_base64} download={file?.name}>
                    //         {file?.name}
                    //       </a>
                    //     ),
                    //   })),
                  },
                {
                  label: (
                    <Button
                      size="small"
                      block
                      onClick={() => handleGetComment(record?.affaire_id)}
                    >
                      {t("Commentaires")}
                    </Button>
                  ),
                },
               
              ],
            }}
            trigger={["click"]}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                {t("Liste")}
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>{" "}
        </Space>
      ),
    },
  ];

  const handleGetComment = (id_affaire) => {
    setIsLoading(true);
    axiosInstance
      .get(
        `${
          import.meta.env.VITE_APP_GEOPROD_API
        }/commentaire/get_investisseur_commentaires?entity=A&id_entity=${id_affaire}`,
        {
          headers: {
            Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
              ?.Acces_Token,
          },
        }
      )
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.info?.length > 0) {
          setCommentaire(res?.data?.info);
        } else {
          notification.warning({
            message: t(
              "Vous ne disposez pas de commentaire pour cette demande !"
            ),
            duration: 0,
          });
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  const determineNavigationPath = (record, form) => {
    const { affaire_id, affaire_id_contrat } = record;
    const { pages } = form;

    switch (affaire_id_contrat) {
      case import.meta.env.VITE_ID_LIGNE_PRODUIT_ANNEXE:
        sessionStorage.setItem("id_affaire_annexe", affaire_id);
        return "/annexes/save?affaire_id=" + record?.affaire_parent;
      case import.meta.env.VITE_ID_LIGNE_PRODUIT_EPF_EXISTANTE:
        sessionStorage.setItem("idAffaireEpfExistant", affaire_id);
        return "/etablissements/existante/save";
      case import.meta.env
        .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_AVEC_BO:
      case import.meta.env
        .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_SANS_BO:
      case import.meta.env
        .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_SANS_VALIDATION_SANS_BO:
        const pageChangementInvestisseur = pages?.find(
          (item) => item?.name === "ChnangementInvestisseur"
        );
        if (pageChangementInvestisseur) {
          sessionStorage.setItem("idAffaireChangementInvestisseur", affaire_id);
          setSurveyJson(pageChangementInvestisseur);
          return "/investisseur/changer";
        } else {
          const selectedOption = determineSelectedOption(affaire_id_contrat);
          sessionStorage.setItem("selectedOption", selectedOption);
          return `/etablissements/edit`;
        }
      default:
        return "/etablissements/save";
    }
  };

   useEffect(() => {
    async function fetchData() {
      try {
        const data = await getAffaires({
          id_user: JSON.parse(sessionStorage.getItem("-x-token-user")).id_user,
          id_entreprise: JSON.parse(sessionStorage.getItem("-x-token-user"))
            .user.entreprise_id,
          filters: {
            contrat: [],
            status: [
              import.meta.env.VITE_APP_ETAT_VALIDATION_PROVISOIRE,
              import.meta.env.VITE_APP_ETAT_COMPLEMENT_VALIDATION_PROVISOIRE,
              import.meta.env.VITE_APP_ETAT_COMPLEMENT_DOSSIER,
              import.meta.env.VITE_APP_ETAT_COMPLEMENT_DOSSIER_EPF_EXISTANT,
              import.meta.env.VITE_APP_ETAT_COMPLEMENT_DOSSIER_MODIFICATION,
            ],
            excludedStatus:[]
          },
        });
        if(data){
          setLoading(false)
          setDataSource(data?.aaData)
        }
      } catch (error) {
        setLoading(false)
      }
    }

    fetchData();
  }, []);

  return (
    <Row className={classes.listeRow}>
      <DownloadDocsModal
        docs={docs}
        open={openDocsModal}
        setOpen={setOpenDocsModal}
      />
       <CommentModal
          commentaire={commentaire}
          setCommentaire={setCommentaire}
        />
      <Card
        className={classes.listeCard}
        bordered={false}
        title={
          <div>
            <div
              style={{ textAlign: i18n?.dir() === "ltr" ? "left" : "right" }}
            >
              {t("Liste de mes tâches")}
            </div>
          </div>
        }
      >
        <Table
          locale={{ emptyText: t("Aucune donnée") }}
          loading={loading}
          columns={columns}
          dataSource={dataSource}
          className="ant-border-space"
          style={{ overflowX: "auto" }}
          pagination={false}
        />
      </Card>
    </Row>
  );
};

export default Taches;
