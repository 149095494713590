/* eslint-disable react/prop-types */
import { createContext, useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axiosInstance from "../utils/axios";
import axios from "axios";

const AnnexeLoaderContext = createContext();

export const AnnexeLoaderProvider = ({ children }) => {
  const [isLoadingAnnexe, setIsLoading] = useState(false);
  const [surveyJson, setSurveyJson] = useState(null);


  return (
    <AnnexeLoaderContext.Provider
      value={{ isLoadingAnnexe, setIsLoading, surveyJson, setSurveyJson }}
    >
      {children}
    </AnnexeLoaderContext.Provider>
  );
};

// Custom hook to use loader context
export const useAnnexeLoader = () => useContext(AnnexeLoaderContext);
