import { useContext, useEffect, useRef, useState } from "react";
import { Survey } from "survey-react-ui";
import { Model, CustomWidgetCollection, surveyLocalization } from "survey-core";
import GlobalContext from "../../../context/GlobalContext";
import { useLocation, useNavigate } from "react-router-dom";
import {
  onDynamicPanelItemValueChanged,
  onUploadFiles,
  onValueChanging,
} from "../CreateEpf/CreateEpfComponents/surveyEvents";
import mime from "mime";
import DatePikckerComponent from "../CreateEpf/DatePIkcer";
import {
  fetchCodePostalList,
  fetchDelegationList,
  getCommuneByCodeCommune,
} from "../../../context/FetchLists";
import dayjs from "dayjs";
import { checkCitizenStatusByCinDn } from "../../../api/extern";
import { Button, ConfigProvider, Modal, notification, Space } from "antd";
import { t } from "i18next";
import DeleteBrouillonModal from "../CreateEpf/Modals/DeleteBrouillonModal";
import {
  addAffaire,
  addRelationAffaire,
  getSchema,
  saveAffaire,
  updateAffaire,
  updateInfoProfile,
} from "../../../api/geoprod";
// import "survey-core/defaultV2.min.css";
// import { themeJson } from "../CreateEpf/CreateEpfComponents/creationJson";
import { useLoader } from "../../../context/LoaderContext";
import classes from "../CreateEpf/CreateEpf.module.css";
import axiosInstance from "../../../utils/axios";
import { Save, Trash2 } from "lucide-react";
// import surveyJson from "./ChangementInvestisseur.json";
surveyLocalization.defaultLocale = "fr";

let survey = new Model();

const ChangementInvestisseur = () => {
  const [confirmModal, setConfirmModal] = useState(false);
  const { setIsLoading, getSurveyJson, getSurveyData } = useLoader();
  const {
    secteurData,
    gouvernoratList,
    nationalityList,
    matriculeFiscale,
    listAllDelegations,
  } = useContext(GlobalContext);
  const ref = useRef();
  const params = useLocation();
  const navigate = useNavigate();
  const surveyJson = getSurveyJson();
  // survey.applyTheme(themeJson);

  // onCompleteInvestisseurChanging(survey, setIsLoading, navigate);

  const updateSurveyData = async () => {
    const data = await getSurveyData();
    survey.data = data;
  };

  const setFileValues = (value) => {
    const fileType = mime.getType(value.name);
    const file = {
      content: value.content,
      name: value.name,
      type: fileType,
    };
    return file;
  };

  const parseDate = (dateStr) => {
    if (dayjs(dateStr, "YYYY-MM-DD", true).isValid()) {
      return dayjs(dateStr).format("YYYY-MM-DD");
    }
    if (dayjs(dateStr, "YYYY/MM/DD", true).isValid()) {
      return dayjs(dateStr, "YYYY/MM/DD").format("YYYY-MM-DD");
    }
    if (dayjs(dateStr, "DD/MM/YYYY", true).isValid()) {
      return dayjs(dateStr, "DD/MM/YYYY").format("YYYY-MM-DD");
    }
  };

  const getData = async () => {
    const user = JSON.parse(sessionStorage.getItem("-x-token-user"));
    survey.showProgressBar = "off";

    survey.showPreviewBeforeComplete = false;

    if (params.state?.id_affaire) {
      sessionStorage.setItem("consulter", true);

      survey.showCompleteButton = false;
      survey.getAllQuestions().map((question) => {
        question.readOnly = true;
       
      });
    } else {
      sessionStorage.setItem("consulter", false);
    }
    async function fetchData() {
      let data;
      if (user?.nationality === "تونسية" && user?.ehouwiya !== 1) {
        data = await checkCitizenStatusByCinDn(
          user?.user?.date_naiss_user,
          user?.cin
        );
      }
      if (data?.prenomAr) {
        survey.setValue("investor_first_name_ar", data?.prenomAr);
        const name_ar = survey.getQuestionByName("investor_first_name_ar");
        name_ar.readOnly = true;
      }
      if (data?.nomAr) {
        survey.setValue("investor_last_name_ar", data?.nomAr);
        const prenom_ar = survey.getQuestionByName("investor_last_name_ar");
        prenom_ar.readOnly = true;
      }
      if (data?.commune) {
        let lieu_naissance = await getCommuneByCodeCommune(data?.commune);
        survey.setValue("investor_birth_place", lieu_naissance);
        survey.getQuestionByName("investor_birth_place").readOnly = true;
      }
      if (data?.prenomFr) {
        survey.setValue("investor_last_name_fr", data?.prenomFr || user?.nom);
        const name_fr = survey.getQuestionByName("investor_last_name_fr");
        name_fr.readOnly = true;
      }
      if (data?.nomFr) {
        survey.setValue("investor_first_name_fr", data?.nomFr || user?.prenom);
        const prenom_fr = survey.getQuestionByName("investor_first_name_fr");
        prenom_fr.readOnly = true;
      }

      if (user?.email) {
        survey.setValue("investor_email", user?.email);
        const investor_email = survey.getQuestionByName("investor_email");
        investor_email.readOnly = true;
      }

      survey.setValue("investor_phone_number", user?.mobile);
      survey.setValue("investor_nationality", user?.nationality);
      survey.setValue(
        "investor_civility",
        data?.genre === "1" ? "ذكر" : "أنثى"
      );
      survey.setValue("investor_cin", user?.cin);
      survey.setValue(
        "investor_birth_date",
        dayjs(user?.user?.date_naiss_user).format("YYYY-MM-DD")
      );
    }
    fetchData();
    const setUserData = (userData, role) => {
      const fieldsToSet = [
        {
          surveyField:
            role === "directeur"
              ? "establishment_director_data_passeport"
              : "investor_passport",
          userDataField: "passport",
        },

        {
          surveyField:
            role === "directeur"
              ? "establishment_director_date_expiration_passeport"
              : "investor_date_expiration_passeport",
          userDataField: "expiration_passeport",
        },
        {
          surveyField:
            role === "directeur"
              ? "establishment_director_data_experience_years"
              : "",
          userDataField: "experience_years",
          readOnly: true,
        },
        {
          surveyField:
            role === "directeur"
              ? "establishment_director_data_first_name"
              : "investor_first_name_ar",
          userDataField: "prenom_ar",
          readOnly: true,
        },
        {
          surveyField:
            role === "directeur"
              ? "establishment_director_data_last_name"
              : "investor_last_name_ar",
          userDataField: "nom_ar",
          readOnly: true,
        },

        {
          surveyField:
            role === "directeur"
              ? "establishment_director_data_first_name_fr"
              : "investor_first_name_fr",
          userDataField: "prenom",
          readOnly: true,
        },
        {
          surveyField:
            role === "directeur"
              ? "establishment_director_data_last_name_fr"
              : "investor_last_name_fr",
          userDataField: "nom",
          readOnly: true,
        },
        {
          surveyField:
            role === "directeur"
              ? "establishment_director_data_email"
              : "investor_email",
          userDataField: "mail",
          readOnly: true,
        },
        {
          surveyField:
            role === "directeur"
              ? "establishment_director_data_birth_place"
              : "investor_birth_date",
          userDataField: "date_naiss_user",
          readOnly: true,
        },
        {
          surveyField:
            role === "directeur"
              ? "establishment_director_data_genre"
              : "investor_civility",
          userDataField: "civilite",
          readOnly: true,
        },

        {
          surveyField:
            role === "directeur"
              ? "establishment_director_data_nationality"
              : "investor_nationality",
          userDataField: "nationality",
          readOnly: true,
        },
        {
          surveyField:
            role === "directeur"
              ? "establishment_director_data_cin_number"
              : "investor_cin",
          userDataField: "cin",
          readOnly: true,
        },

        {
          surveyField:
            role === "directeur"
              ? "establishment_director_data_date_delivrance"
              : "investor_date_delivrance",
          userDataField: "delivrance_date",
        },
        {
          surveyField:
            role === "directeur"
              ? "establishment_director_data_birth_place"
              : "investor_birth_place",
          userDataField: "birthplace",
          readOnly: true,
        },
        {
          surveyField:
            role === "directeur"
              ? "establishment_director_data_education_level"
              : "investor_eduction_level",
          userDataField: "niveau_scolaire",
          readOnly: true,
        },
        {
          surveyField:
            role === "directeur"
              ? "establishment_director_data_phone_number"
              : "investor_phone_number",
          userDataField: "telephone",
          readOnly: true,
        },
        {
          surveyField:
            role === "directeur"
              ? "establishment_director_data_address"
              : "investor_residence_address",
          userDataField: "adresse",
        },
        {
          surveyField:
            role === "directeur"
              ? "establishment_director_data_governorate"
              : "investor_residence_governorate",
          userDataField: "goverment",
        },
        {
          surveyField:
            role === "directeur"
              ? "establishment_director_data_delegation"
              : "investor_residence_delegation",
          userDataField: "delegation",
        },
        {
          surveyField:
            role === "directeur" ? "" : "investor_residence_postal_code",
          userDataField: "code_postal",
        },
        {
          surveyField:
            role === "directeur"
              ? "establishment_director_data_specific_needs"
              : "investor_specific_needs",
          userDataField: "besoins_specifiques",
        },
        {
          surveyField:
            role === "directeur"
              ? "establishment_director_data_cin_copie"
              : "investor_copie_cin",
          userDataField: "copie_cin",
        },
        {
          surveyField:
            role === "directeur"
              ? "establishment_director_data_passeport_copie"
              : "investor_copie_passeport",
          userDataField: "copie_passeport",
        },
        {
          surveyField:
            role === "directeur"
              ? "establishment_director_data_director_folder"
              : "investor_copie_cv",
          userDataField: "copie_cv",
        },
        {
          surveyField:
            role === "directeur" ? "copie_autorisation_ministre" : "",
          userDataField: "copie_autorisation_ministre",
        },
      ];

      fieldsToSet.forEach((field) => {
        const { surveyField, userDataField, readOnly } = field;
        const value =
          surveyField.includes("copie") && userData[userDataField]
            ? setFileValues(userData[userDataField])
            : surveyField.includes("_date") && userData[userDataField]
            ? parseDate(userData[userDataField])
            : userData[userDataField];
        const question = survey.getQuestionByName(surveyField);

        if (!value) {
          question ? (question.readOnly = false) : null;
        } else {
          survey.setValue(surveyField, value);
          if (readOnly) {
            question.readOnly = true;
          }
        }
      });
    };
    if (user) {
      const idAffaire = sessionStorage.getItem("idAffaireEpfExistant")
        ? sessionStorage.getItem("idAffaireEpfExistant")
        : params.state?.idAffaireEpfExistant;
      const idAffaireChangementInvestisseur = sessionStorage.getItem(
        "idAffaireChangementInvestisseur"
      )
        ? sessionStorage.getItem("idAffaireChangementInvestisseur")
        : params.state?.idAffaireChangementInvestisseur;

      updateSurveyData();

      if (idAffaire) {
        axiosInstance
          .get(
            `${
              import.meta.env.VITE_APP_GEOPROD_API
            }/config/proxy/registreEntrepriseParId/${matriculeFiscale.substring(
              0,
              8
            )}`
          )
          .then((res) => {
            if (res?.data?.code === "404") {
              survey.clearValue("establishment_data_official_arabic_name");
              survey.clearValue("establishment_data_official_name");
              return notification.error({
                message: t("Erreur"),
                description: t("Numéro d’identifiant fiscal / RNE invalide"),
                duration: 0,
              });
            }
            const epfData = res?.data;
            const arabicEpfName = epfData?.nomSociete
              ? epfData?.nomSociete
              : epfData?.nomAssociation;
            const latinEpfName = epfData?.nomAssociationLatin
              ? epfData?.nomAssociationLatin
              : epfData?.nomSocieteLatin;
            survey.setValue(
              "establishment_data_official_arabic_name",
              arabicEpfName
            );
            survey.setValue("establishment_data_official_name", latinEpfName);
            axiosInstance
              .get(`${import.meta.env.VITE_APP_GEOPROD_API}/get_user_with_id`, {
                headers: {
                  Idsession: user?.Acces_Token,
                },
              })
              .then(async (data) => {
                const role = sessionStorage.getItem("role");
                setUserData(data?.data?.user, role);
                populateFunction();

                if (
                  !sessionStorage.getItem("idAffaireChangementInvestisseur")
                ) {
                  // const dataSurvey = await getSurveyData();

                  const data = await getSchema(
                    params?.state?.idAffaireChangerInv
                  );

                  if (data) {
                    sessionStorage.setItem(
                      "BeforechangeInvestorData",
                      JSON.stringify(data?.response?.data)
                    );

                    const payload = {
                      id_affaire: params?.state?.idAffaireChangerInv,
                      data_form_prod: JSON.stringify(data?.response?.data),
                      ligne_produit: import.meta.env
                        .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_AVEC_BO,
                      etat_affaire: import.meta.env
                        .VITE_APP_ID_STATUS_BROUILLON_AVEC_VALIDATION,
                      epf_existant: true,
                    };
                    axiosInstance
                      .post(
                        import.meta.env.VITE_APP_GEOPROD_API + "/update_EPF",
                        payload,
                        {
                          headers: {
                            Idsession: JSON.parse(
                              sessionStorage.getItem("-x-token-user")
                            )?.Acces_Token,
                          },
                        }
                      )
                      .then((res) => {
                        sessionStorage.setItem(
                          "idAffaireChangementInvestisseur",
                          res?.data?.new_affaire
                        );
                        setIsLoading(false);
                      })
                      .catch(() => {
                        navigate("/etablissements");
                        setIsLoading(false);
                      });
                  }
                }

                // const payload = {
                //   ligne_produit: import.meta.env
                //     .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_AVEC_VALIDATION_AVEC_BO,
                //   id_opp: null,
                //   nom_produit: "THAMM PRODUCT (  )",
                //   id_produit: "d728587fff2d87201eae0a158507d814",
                //   cmp: "987654300",
                //   user_id: user.id_user,
                //   date_effet: "2024-01-02",
                //   souscription: "2024-01-02",
                //   id_fractionnement: 11,
                //   affected: "1002237",
                //   data_form_prod: JSON.stringify({
                //     ...survey.data,
                //   }),
                // };
                // const getIdAffaire = async () => {
                //   try {
                //     const res = await addAffaire(payload);
                //     sessionStorage.setItem(
                //       "idAffaireChangementInvestisseur",
                //       res?.id_affaire
                //     );
                //     const idAffaireFils = res?.id_affaire;
                //     const payloadRelationAffaire = {
                //       id_affaire1: idAffaire,
                //       id_affaire2: idAffaireFils,
                //       type: 1,
                //     };
                //     await addRelationAffaire(payloadRelationAffaire);
                //     const infoSpecefiquePayload = {
                //       additional_form: "{}",
                //       json_prod: { ...survey.data },
                //     };
                //     await updateAffaire(
                //       idAffaireFils,
                //       infoSpecefiquePayload,
                //       "Brouillon"
                //     );
                //     setSurveyData({ ...survey.data });
                //   } catch {
                //     notification.error({
                //       message: t("Erreur"),
                //       description: t(
                //         "Erreur lors de la création de la demande."
                //       ),
                //       duration: 0,
                //     });
                //   }
                // };
                // getIdAffaire();
              });
          });
      }
    }
    survey.getAllQuestions().forEach((question) => {
      if (question.name.toLowerCase().includes("_nationality")) {
        question.choices = nationalityList;
      }
      if (question.name.toLowerCase().includes("_governorate")) {
        question.choices = gouvernoratList;
      }
    });
  };

  const handleClickOutside = async (event) => {
    // const data = await getSurveyData();
    // let data_brouillon
    // if(!sessionStorage.getItem("demande") || sessionStorage.getItem("demande")!=="true"){
    //   const all_data_but_investor=Object.fromEntries(
    //     Object.entries(data).filter(([key, value]) => !key.includes("investor"))
    //   );
    //   data_brouillon = all_data_but_investor;
    // }
    // else{
    //   data_brouillon = data;
    // }
    const payload = {
      current_step: 0,
      json_prod: { ...survey.data },
      model: surveyJson,
    };
    sessionStorage.setItem("payload", JSON.stringify(payload));
  };

  const populateFunction = async () => {
    survey.getAllQuestions().forEach(async (question) => {
      if (question.name.toLowerCase().includes("_governorate")) {
        question.choices = gouvernoratList;
        const delegateKey = question.name.replace(
          "_governorate",
          "_delegation"
        );
        const selectedGouvernorat = question.value;
        const selectedDelegate = survey.getQuestionByName(delegateKey);
        const id_goverment = gouvernoratList.find(
          (e) => e.value === selectedGouvernorat
        )?.id;
        if (id_goverment) {
          const delegations = await fetchDelegationList(id_goverment);

          selectedDelegate.choices = delegations || [];
        }
      }
      if (question.name?.includes("_delegation")) {
        const selectedDelegation = question.value;
        const postalCodeQuestion = survey.getQuestionByName(
          question.name.replace("_delegation", "_postal_code")
        );

        const idDelegation = listAllDelegations.find(
          (elem) => elem.value === selectedDelegation
        )?.id;

        if (idDelegation && postalCodeQuestion) {
          const listPostalCodes = await fetchCodePostalList(idDelegation);
          if (listPostalCodes) {
            postalCodeQuestion.choices = listPostalCodes.map((code) => ({
              value: code.value,
              text: code.text,
            }));
          } else {
            postalCodeQuestion.choices = [];
          }
        }
      }
    });
  };

  useEffect(() => {
    const surveyJson = getSurveyJson();
    if (surveyJson !== null) {
      survey?.setJsonObject({
        ...surveyJson,
      });
      survey.defaultLocal;
      onValueChanging(survey, gouvernoratList, listAllDelegations);
      onDynamicPanelItemValueChanged(survey, secteurData);
      onUploadFiles(
        survey,
        "changementInvestisseur",
        params?.state?.selectedOption
      );

      survey.onCompleting.add(async (sender, options) => {
        options.allowComplete = false;
        sender.allowComplete = false;
        setIsLoading(true);
        const surveyJson = getSurveyJson();
        const surveyData = JSON.parse(
          sessionStorage.getItem("BeforechangeInvestorData")
        );
        const roleUser = sessionStorage.getItem("role");

        const rolePayload =
          roleUser === "investisseur"
            ? import.meta.env.VITE_APP_ID_ROLE_INVESTISSEUR
            : roleUser === "directeur"
            ? import.meta.env.VITE_APP_ID_ROLE_DIRECTEUR
            : roleUser === "investisseur-directeur"
            ? import.meta.env.VITE_APP_ID_ROLE_INVESTISSEUR_DIRCETEUR
            : import.meta.env.VITE_APP_ID_ROLE_FORMATEUR;

        const payloadUpdateProfil = {
          adresse: {
            geom: "",
          },
          user: {
            acc_affaire: [],
            acc_opportunite: [],
            acces_avenant: 2,
            acces_communiques: 2,
            acces_courrier_entrant: 0,
            acces_distrib: 0,
            acces_edit_maileva: 2,
            acces_ett: true,
            acces_fusion: 2,
            acces_maileva: 2,
            acces_qualite: 2,
            acces_reclamation_ticket: 1,
            acces_signature: 0,
            act_grp_communication: 2,
            act_grp_negociateur: 2,
            act_grp_pubpostage: 2,
            act_grp_statut: 2,
            act_grp_tags: 2,
            active: 1,
            admin_restreint: 0,
            admin_tpv: 2,
            adresse: survey.data.investor_residence_address,
            adresse_auto: "*",
            api_key: "",
            assistant: 0,
            beta: 0,
            birthplace: survey.data.investor_birth_place,
            bloc_env: 0,
            cin:
              survey.data.investor_nationality === "تونسية"
                ? survey.data.investor_cin
                : "",
            civilite: survey.data.investor_civility,
            code: null,
            comparateur: 0,
            date_integration: null,
            date_naiss_user: dayjs(survey.data.investor_birth_date).format(
              "YYYY-MM-DD"
            ),
            delegation: survey.data.investor_residence_delegation,
            ecrit_comp_aff: 0,
            ecriture_transaction_financiere_client: 2,
            ecriture_transaction_financiere_distribution: 2,
            entreprise_id: import.meta.env.VITE_APP_ID_Entreprise_INVESTISSEUR,
            espace_distrib_access: {
              acces_info_spec: 2,
              changement_statut: 2,
            },
            delivrance_date:
              survey.data.investor_nationality === "تونسية"
                ? dayjs(survey.data.investor_date_delivrance).format(
                    "YYYY-MM-DD"
                  )
                : "",
            expiration_passeport:
              survey.data.investor_nationality !== "تونسية"
                ? dayjs(survey.data.investor_date_expiration_passeport).format(
                    "YYYY-MM-DD"
                  )
                : "",
            execution_tpv: 2,
            exp_aff: null,
            exp_maileva: 2,
            exp_opp: null,
            export_aff_det: 0,
            export_maileva_det: 2,
            export_opp_det: 0,
            fiche_dec: 0,
            filtre: 0,
            first_login: null,
            goverment: survey.data.investor_residence_governorate,
            groupe_commission: null,
            groupe_user: [],
            groupe_user_alert: [],
            groupe_user_alert_nom: [],
            heure_debut: "0:00:00",
            heure_fin: null,
            hist: 2,
            hist_comm: 2,
            id: 1002234,
            identite_reelle: null,
            lect_comp_aff: 0,
            lecture_tpv: 2,
            lecture_transaction_financiere_client: 2,
            lecture_transaction_financiere_distribution: 2,
            limit_listing_aff: "100",
            limit_listing_maileva: "2",
            limit_listing_opp: "100",
            location: null,
            mail: survey.data.investor_email,
            manager: null,
            manager_de: [],
            manager_de_nom: [],
            manager_list: [],
            manager_nom: [],
            matricule_rh: null,
            mobile: survey.data.investor_phone_number,
            autoriser_dossier: "",
            nationality: survey.data.investor_nationality,
            nom: survey.data.investor_last_name_fr,
            lieu_naissance: survey.data?.investor_birth_place,
            nom_ar: survey.data.investor_last_name_ar,
            nom_groupe_commission: null,
            nom_organisme: "",
            nom_producteur: null,
            nom_role: "",
            nom_zone: null,
            num_interne: null,
            orias_producteur: null,
            passport:
              survey.data.investor_nationality !== "تونسية"
                ? survey.data.investor_passport
                : "",
            password: "",
            photo: null,
            pointage: 0,
            prenom: survey.data.investor_first_name_fr,
            prenom_ar: survey.data.investor_first_name_ar,

            prenom_producteur: null,
            proprietaire: true,
            qualite_producteur: null,
            role: rolePayload,
            role_auto: "[]",
            role_auto_nom: [],
            samedi_off: 0,
            signature: null,
            skype: null,
            ssl_mail_data: 0,
            super_admin: true,
            tab_code: [],
            tarificateur: 2,
            tel2: null,
            tel_urgence: null,
            telephone: survey.data.investor_phone_number,
            transaction_financiere: 2,
            type_user: "c81e728d9d4c2f636f067f89cc14862c",
            user_groupe_nom: [],
            user_pwd_data: null,
            validate_paiement: 2,
            domaines_specialite: "",
            code_postal: survey.data.investor_residence_postal_code,
            niveau_scolaire: survey.data.investor_eduction_level,
            besoins_specifiques: survey.data.investor_specific_needs,
            zone: null,
          },
          ville: {},
        };
        const user = JSON.parse(sessionStorage.getItem("-x-token-user"));

        const data = {
          ...surveyData,
          ...survey.data,
          // investor_email:survey.data.investor_email
        };
        // const payload = {
        //   current_step: surveyEdit.currentPageNo,
        //   json_prod: {  },
        //   model: editJson,
        // };
        const payload = {
          additional_form: "{}",
          json_prod: { ...data },
          model: surveyJson,
        };
        const dataSaveAffaire = await saveAffaire(
          payload,
          "SoumettreModification",
          setIsLoading,
          "changementInvestisseur"
        );
        if (dataSaveAffaire) {
          await updateInfoProfile(payloadUpdateProfil, user.id_user);

          sessionStorage.removeItem("idAffaireChangementInvestisseur");

          setIsLoading(false);
          navigate("/demandes");
        } else {
          setIsLoading(false);
        }
        // .then(() => {
        //   sessionStorage.removeItem("idAffaireChangementInvestisseur");

        //   setIsLoading(false);
        //   navigate("/demandes");
        // })
        // .catch(() => {
        //   setIsLoading(false);
        // });
      });

      survey.onErrorCustomText.add(function (sender, options) {
        if (options.name == "required") {
          options.text = t("Veuillez remplir ce champ.");
        }
      });

      survey.onAfterRenderQuestion.add(function (sender, options) {
        if (options.question.name === "investor_residence_delegation") {
        populateFunction()
      }
        const fileInputButton =
          options.htmlElement?.querySelector(".sd-file__wrapper");
        if (fileInputButton) {
          const spanElement = fileInputButton?.querySelector("span");
          if (spanElement) {
            spanElement.textContent = "Télécharger le fichier - تحميل الملف";
          }
        }
        const emptyTextDiv = options?.htmlElement?.querySelector(
          ".sv-list__empty-text"
        );
        if (
          (options.question.name.includes("_delegation") ||
            options.question.name.includes("_postal_code") ||
            options.question.name.includes("_sub_sector") ||
            options.question.name.includes("_specialty")) &&
          emptyTextDiv
        ) {
          emptyTextDiv.textContent = t(
            "Veuillez d'abord remplir le champ précédent !"
          );
        }
        const fileInputButtonBeforeChoose = options?.htmlElement?.querySelector(
          ".sd-file__choose-btn"
        );
        if (fileInputButtonBeforeChoose) {
          const spanElement =
            fileInputButtonBeforeChoose?.querySelector("span");
          if (spanElement) {
            spanElement.textContent = "Télécharger le fichier - تحميل الملف";
          }
        }

        const placeholderNoFile = options?.htmlElement?.querySelector(
          ".sd-file__decorator"
        );
        if (placeholderNoFile) {
          const placeholder = placeholderNoFile?.querySelector(
            ".sd-file__drag-area-placeholder"
          );
          if (placeholder) {
            const spanElement = placeholder?.querySelector(".sv-string-viewer");
            if (
              spanElement &&
              (spanElement?.textContent === "No file selected" ||
                spanElement?.textContent === "Aucun fichier ajouté")
            ) {
              spanElement.textContent =
                "Aucun fichier téléchargé - لم يتم تحميل أي ملف";
            }
          }
        }
      });

      CustomWidgetCollection.Instance.addCustomWidget({
        name: "datepicker",
        title: "Date picker",
        isFit: function (question) {
          return (
            question.getType() === "text" &&
            question?.jsonObj?.inputType === "date"
          );
        },
        render: function (question, element) {
          return <DatePikckerComponent question={question} survey={survey} />;
        },
      });

      getData();

      if (
        sessionStorage.getItem("demande") === "false" ||
        !sessionStorage.getItem("demande")
      ) {
        document.addEventListener("mousedown", handleClickOutside);
      }
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      survey.dispose(true, true);
      survey = new Model();
    };
  }, []);
  return (
    <div ref={ref}>
      <ConfigProvider direction="ltr">
        <div style={{ direction: "ltr" }}>
          <Survey model={survey} />
        </div>
      </ConfigProvider>
      <DeleteBrouillonModal
        confirmModal={confirmModal}
        setConfirmModal={setConfirmModal}
        typeAffaire="changementInvestisseur"
      />
      <Space className={classes.rowButtonCreate}>
        {!params.state?.id_affaire && (
          <Space>
            <Button
              className={classes.buttonCancel}
              onClick={() => setConfirmModal(true)}
            >
              <Trash2 strokeWidth={1.25} />
              {t("Annuler la demande")}
            </Button>
            <Button
              className={classes.buttonCreate}
              onClick={async () => {
                // setIsLoading(true);

                const payload = {
                  current_step: 0,
                  json_prod: {
                    ...survey.data,
                  },
                  model: surveyJson,
                };

                await saveAffaire(
                  payload,
                  "Brouillon",
                  setIsLoading,
                  "changementInvestisseur"
                );
                sessionStorage.removeItem("current_step");

                // setIsLoading(false);
                navigate("/brouillon");
              }}
            >
              <Save strokeWidth={1.25} />
              {t("Sauvegarder au brouillon")}
            </Button>{" "}
          </Space>
        )}
        {/* <Button
          className={classes.buttonCreate}
          onClick={() => navigate("/etablissements")}
        >
          <UnorderedListOutlined />
          {t("Liste de mes EPFs")}
        </Button> */}
      </Space>
    </div>
  );
};

export default ChangementInvestisseur;
