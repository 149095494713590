import {
  Row,
  Card,
  Table,
  Tag,
  notification,
  Space,
  Tooltip,
  Dropdown,
  Button,
} from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import classes from "../Etablissements/Etablissements.module.css";

import { getListDirecteursByUser } from "../../api/geoprod";
import { DownOutlined, EyeOutlined } from "@ant-design/icons";
import ChangeDirectorEmailModal from "../Etablissements/ChangeDirectorEmailModal";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../utils/axios";
import { useLoader } from "../../context/LoaderContext";

const Directeurs = () => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const { setSurveyJson, setIsLoading } = useLoader();
  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getListDirecteursByUser();
        setData(data?.data?.message);
        setLoading(false);
      } catch (error) {
        notification.error({
          message: t("Erreur"),
          description:
            error?.response?.data?.message ||
            "Une interruption est survenue lors de la récupération des données. Veuillez réessayer.",
          duration: 0,
        });
      }
    }

    fetchData();
  }, []);

  const columns = [
    {
      title: t("Dénomination"),
      key: "Dénomination",
      render: (_, record) => record?.nom_etablissement || t("Non renseigné"),
    },
    {
      title: t("Numéro d'enregistrement"),
      key: "Numéro d'enregistrement",
      render: (_, record) => record?.registration_number || t("Non renseigné"),
    },
    {
      title: t("Type"),
      key: "Type",
      render: (_, record) =>
        record?.establishment_director_data_identity || t("Non renseigné"),
    },
    {
      title: t("Nom"),
      key: "Nom",
      render: (_, record) =>
        record?.establishment_director_data_last_name || t("Non renseigné"),
    },
    {
      title: t("Prénom"),
      key: "Prénom",
      render: (_, record) =>
        record?.establishment_director_data_first_name || t("Non renseigné"),
    },
    {
      title: t("Adresse e-mail"),
      key: "Email",
      render: (_, record) =>
        record?.establishment_director_data_email || t("Non renseigné"),
    },
    {
      title: t("Numéro de téléphone"),
      key: "Téléphone",
      render: (_, record) =>
        record?.establishment_director_data_phone_number || t("Non renseigné"),
    },
    {
      title: t("Actions"),
      key: "Actions",
      render: (_, record) => (
        <Space>
          <Dropdown
            menu={{
              items: [
                {
                  label: (
                    <Button
                      size="small"
                      block
                      onClick={async () => {
                        setIsLoading(true);
                        const ligne_produit = import.meta.env
                          .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_SANS_VALIDATION_SANS_BO;
                        axiosInstance
                          .get(
                            `${
                              import.meta.env.VITE_APP_GEOPROD_API
                            }/admin-bpm/contrat/${ligne_produit}`,
                            {
                              headers: {
                                Idsession: JSON.parse(
                                  sessionStorage.getItem("-x-token-user")
                                )?.Acces_Token,
                              },
                            }
                          )
                          .then((res) => {
                            let form = res?.data?.contrat?.form;

                            form.pages = form?.pages.filter(
                              (page) =>
                                page.name ===
                                "Données de Directeur de l’Établissement"
                            );
                            setSurveyJson(form);
                            sessionStorage.setItem("consulter", true);
                            setIsLoading(false);

                            navigate("/profil/directeur", {
                              state: {
                                info: record,
                              },
                            });
                          });
                      }}
                    >
                      {t("Consulter")}
                    </Button>
                  ),
                },
                {
                  label: <ChangeDirectorEmailModal record={record} />,
                },
              ],
            }}
            trigger={["click"]}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                {t("Liste")}
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Row className={classes.listeRow}>
        <Card
          className={classes.listeCard}
          bordered={false}
          title={
            <div
              style={{ textAlign: i18n?.dir() === "ltr" ? "left" : "right" }}
            >
              {t("Liste des directeurs")}
            </div>
          }
        >
          <Table
            locale={{ emptyText: t("Aucune donnée") }}
            columns={columns}
            dataSource={data}
            className="ant-border-space"
            style={{ overflowX: "auto" }}
            pagination={false}
            loading={loading}
          />
        </Card>
      </Row>
    </div>
  );
};

export default Directeurs;
