import { Modal, Button } from "antd";
import React from "react";
import sessionExpiredImage from "../assets/sessionExpiredImage.png"; 
import { useTranslation } from "react-i18next";

const ModalSessionExpired = () => {
  const role = sessionStorage.getItem("role");
  const redirectRole = role === "investisseur-directeur" ? "investisseur" : role;
  const { t } = useTranslation();
  const handleRedirect = () => {
    sessionStorage.clear()
    window.location.href = `/signin?role=${redirectRole}`; 
  };

  return (
    <div
      
      style={{
        textAlign: "center",
        padding: "40px",
        backgroundColor: "#f9fafc",
        borderRadius: "10px",
      }}
    >
      <img
        src={sessionExpiredImage}
        alt="Session Expired"
        style={{ width: "300px", marginBottom: "20px",borderRadius:"0.5rem" }}
      />
      <h2 style={{ fontWeight: "bold", marginBottom: "10px" }}>
        {t("Votre session a expiré")}
      </h2>
      <p style={{ marginBottom: "20px", color: "#6c757d" }}>
        {t("Oops, votre session a expiré. Veuillez vous reconnecter pour revenir à l’interface.")}
      </p>
      <Button
        type="primary"
        style={{ background: "linear-gradient(158deg, #025CAB 0%, #2BC1A7 100%)", borderRadius: "5px" }}
        onClick={handleRedirect}
      >
        {t("Reconnecter")}
      </Button>
    </div>
  );
};

export default ModalSessionExpired;
