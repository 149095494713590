import { Form, notification } from "antd";
import GlobalContext from "./GlobalContext";
import { useEffect, useMemo, useState } from "react";
import {
  fetchAllDelegationList,
  fetchFormesJuridiquesList,
  fetchGovermentList,
  fetchNationlaityList,
  fetchSectorList,
} from "./FetchLists";
import { useSessionStorageState } from "ahooks";
import axiosInstance from "../utils/axios";
import { getAffaires } from "../api/geoprod";
import { useTranslation } from "react-i18next";
const AppContext = (props) => {
  const { t } = useTranslation();
  const [globalData, setGlobalData] = useState(
    sessionStorage.getItem("globalData")
      ? JSON.parse(sessionStorage.getItem("globalData"))
      : {}
  );
  const [isRtl, setIsRtl] = useState(localStorage.getItem("language") === "ar");
  const [form] = Form.useForm();
  const [porteDoc, setPorteDocs] = useState([]);
  const [pageN, setPageN] = useState();
  const [brouillon, setBrouillon] = useState([]);
  const [personneMorale, setPersonneMorale] = useState(
    sessionStorage.getItem("personneMorale") === "true" ? true : false
  );
  const [listTasks, setListTasks] = useState([]);
  const [employeurByPatNumPatCL, setEmployeurByPatNumPatCL] =
    useSessionStorageState("employeurByPatNumPatCLData", { defaultValue: [] });
  const [ficheEntrepriseDGI, setFicheEntrepriseDGI] = useSessionStorageState(
    "ficheEntrepriseDGIData",
    { defaultValue: [] }
  );
  const [etablissementSecondaireDGI, setEtablissementSecondaireDGI] =
    useSessionStorageState("etablissementSecondaireDGIData", {
      defaultValue: [],
    });
  const [listActiviteRNE, setListActiviteRNE] = useSessionStorageState(
    "listActiviteRNEData",
    { defaultValue: [] }
  );
  const [entrepriseRegistrationId, setEntrepriseRegistrationId] =
    useSessionStorageState("entrepriseRegistrationIdData", {
      defaultValue: [],
    });

  const [matriculeFiscale, setMatriculeFiscale] = useState(
    sessionStorage?.getItem("matriculeFiscale")
  );
  const [numeroEnregistrement, setNumeroEnregistrement] = useState(
    sessionStorage?.getItem("numeroEnregistrement")
      ? sessionStorage?.getItem("numeroEnregistrement")
      : ""
  );

  const gouvernoratList = fetchGovermentList()?.map((res) => {
    return {
      text: res?.name,
      value: res?.value,
      id: res?.id,
      name_fr: res?.name_fr,
    };
  });

  const listAllDelegations = fetchAllDelegationList()?.map((res) => {
    return {
      text: res?.name,
      value: res?.value,
      id: res?.id,
    };
  });

  const nationalityList = fetchNationlaityList()?.map((res) => {
    return {
      text: res?.name,
      value: res?.value,
    };
  });

  const FormesJuridiquesList = fetchFormesJuridiquesList()?.map((res) => {
    return {
      text: res?.name,
      value: res?.value,
    };
  });

  const secteurData = fetchSectorList()?.map((res) => {
    return {
      text: res?.name,
      value: res?.value,
      id: res?.id,
    };
  });

  const nationalityOptions = nationalityList?.map((item) => ({
    label: item?.text,
    value: item?.value,
  }));

  const gouvernoratOptions = gouvernoratList?.map((item) => ({
    label: item?.text,
    value: item?.value,
    id: item?.id,
  }));

  useEffect(() => {
    if (JSON.parse(sessionStorage.getItem("-x-token-user"))?.Acces_Token) {
      axiosInstance
        .post(
          `${import.meta.env.VITE_APP_GEOPROD_API}/nbr_tache_by_user`,
          {
            status: [
              import.meta.env.VITE_APP_ETAT_VALIDATION_PROVISOIRE,
              import.meta.env.VITE_APP_ETAT_COMPLEMENT_VALIDATION_PROVISOIRE,
              import.meta.env.VITE_APP_ETAT_COMPLEMENT_DOSSIER,
              import.meta.env.VITE_APP_ETAT_COMPLEMENT_DOSSIER_EPF_EXISTANT,
              import.meta.env.VITE_APP_ETAT_COMPLEMENT_DOSSIER_MODIFICATION,
            ],
          },
          {
            headers: {
              Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
                ?.Acces_Token,
            },
          }
        )
        .then((res) => {
          setListTasks(res?.data?.nombre_taches);
        });
    }
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (JSON.parse(sessionStorage.getItem("-x-token-user"))?.Acces_Token) {
        try {
          const data = await getAffaires({
            id_user: JSON.parse(sessionStorage.getItem("-x-token-user"))
              .id_user,
            id_entreprise: JSON.parse(sessionStorage.getItem("-x-token-user"))
              .user.entreprise_id,
            filters: {
              contrat: [],
              status: [
                import.meta.env.VITE_APP_ID_STATUS_BROUILLON_SANS_VALIDATION,
                import.meta.env.VITE_APP_ID_STATUS_BROUILLON_AVEC_VALIDATION,
                import.meta.env.VITE_APP_ID_STATUS_BROUILLON_EPF_EXISTANT,
                import.meta.env.VITE_APP_ID_STATUS_BROUILLON,
              ],
              excludedStatus: [],
            },
          });
          setBrouillon(data?.aaData);
          // setLoading(false);
        } catch (error) {
          notification.error({
            message: t("Erreur"),
            description:
              error?.response?.data?.message ||
              "Une interruption est survenue lors de la récupération des données. Veuillez réessayer.",
            duration: 0,
          });
        }
      }
    }

    fetchData();
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        globalData,
        setGlobalData,
        form,
        isRtl,
        setIsRtl,
        porteDoc,
        setPorteDocs,
        pageN,
        setPageN,
        // codes_communes,
        brouillon,
        setBrouillon,
        nationalityOptions,
        gouvernoratOptions,
        // survey,
        secteurData,
        gouvernoratList,
        nationalityList,
        FormesJuridiquesList,
        personneMorale,
        setPersonneMorale,
        matriculeFiscale,
        setMatriculeFiscale,
        numeroEnregistrement,
        setNumeroEnregistrement,
        listAllDelegations,
        employeurByPatNumPatCL,
        setEmployeurByPatNumPatCL,
        ficheEntrepriseDGI,
        setFicheEntrepriseDGI,
        etablissementSecondaireDGI,
        setEtablissementSecondaireDGI,
        listActiviteRNE,
        setListActiviteRNE,
        entrepriseRegistrationId,
        setEntrepriseRegistrationId,
        listTasks,
        setListTasks,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export default AppContext;
