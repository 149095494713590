import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  DatePicker,
  Row,
  Col,
  Select,
  Card,
  Checkbox,
  notification,
  Radio,
  Upload,
} from "antd";
import { useTranslation } from "react-i18next";
import GlobalContext from "../context/GlobalContext";
import PhoneInput from "antd-phone-input";
import dayjs from "dayjs";
import i18next from "i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { UploadOutlined } from "@ant-design/icons";
import { LoaderProvider } from "../context/LoaderContext";
import { useLoader } from "../context/LoaderContext";
import {
  fetchCodePostalList,
  fetchDelegationList,
  fetchDelegationListByGoverment,
  fetchPostalCodeListByDelegation,
} from "../context/FetchLists";
import axiosInstance from "../utils/axios";

const Profile = (props) => {
  const { t } = useTranslation();
  const { Option } = Select;
  const params = useLocation();
  let first_login = params?.state?.first_login;
  const ehouwiya = JSON.parse(sessionStorage.getItem("-x-token-user")).ehouwiya;
  const { form, nationalityOptions, gouvernoratOptions } =
    useContext(GlobalContext);
  const { setIsLoading } = useLoader();
  const [copiePasseportFileList, setCopiePasseportFileList] = useState([]);
  const [copieCinFileList, setCopieCinFileList] = useState([]);
  const [copieDossierFileList, setCopieDossierFileList] = useState([]);

  const [nationalité, setNationalité] = useState("تونسية");
  const [delegations, setDelegations] = useState([]);
  const [postalCodeList, setPostalCodeList] = useState([]);
  const handleGouvernoratChange = async (value) => {
    let selected_goverment = gouvernoratOptions.find(
      (e) => e.value === value
    ).id;
    let list_delegation = await fetchDelegationList(selected_goverment);
    form.setFieldsValue({
      ...form.getFieldsValue(),
      residenceDelegation: "",
      postal_code: "",
    });
    setPostalCodeList([]);
    setDelegations(list_delegation);
  };

  const handleDelegationChange = async (value) => {
    let selected_delegation = delegations.find((e) => e.value === value).id;
    let list_code_postaux = await fetchCodePostalList(selected_delegation);
    form.setFieldsValue({
      ...form.getFieldsValue(),
      postal_code: "",
    });
    setPostalCodeList(list_code_postaux);
  };

  const initialValues = JSON.parse(sessionStorage.getItem("-x-token-user"));
  const role = sessionStorage.getItem("role");
  const navigate = useNavigate();
  const niveauScolaire = [
    {
      value: "إبتدائي",
      label: "Primaire - إبتدائي",
    },
    {
      value: "ثانوي",
      label: "Secondaire - ثانوي",
    },
    {
      value: "جامعي",
      label: "Universitaire - جامعي",
    },
    {
      value: "تكوين مهني",
      label: "Formation professionnelle - تكوين مهني",
    },
  ];

  const getFiles = (cin, passeport) => {
    const apiUrl = `${
      import.meta.env.VITE_APP_GEOPROD_API
    }/document_formateur/getByCinPassport`;
    const param =
      form.getFieldsValue()["nationality"] === "تونسية"
        ? `cin=${cin}`
        : `passport=${passeport}`;
    axiosInstance
      .get(
        `${apiUrl}?${param}`,

        {
          headers: {
            Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
              ?.Acces_Token,
          },
        }
      )
      .then((res) => {
        const url_passepot = res?.data?.response?.find(
          (e) => e.key === "copie_passeport"
        )?.url;
        const url_cin = res?.data?.response?.find(
          (e) => e.key === "copie_cin"
        )?.url;
        const url_dossier = res?.data?.response?.find(
          (e) => e.key === "dossier_file"
        )?.url;

        if (url_passepot) {
          form.setFieldsValue({
            ...form.getFieldsValue(),
            copie_passeport: url_passepot,
          });
          setCopiePasseportFileList([
            {
              uid: "-1",
              name: "copie passeport",
              status: "done",
              url: url_passepot,
            },
          ]);
        }

        if (url_cin) {
          form.setFieldsValue({
            ...form.getFieldsValue(),
            copie_cin: url_cin,
          });
          setCopieCinFileList([
            { uid: "-1", name: "copie cin", status: "done", url: url_cin },
          ]);
        }

        if (url_dossier) {
          form.setFieldsValue({
            ...form.getFieldsValue(),
            dossier_file: url_dossier,
          });
          setCopieDossierFileList([
            {
              uid: "-1",
              name: "copie dossier",
              status: "done",
              url: url_dossier,
            },
          ]);
        }

        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    axiosInstance
      .get(`${import.meta.env.VITE_APP_GEOPROD_API}/get_user_with_id`, {
        headers: {
          Idsession: initialValues?.Acces_Token,
        },
      })
      .then(async (res) => {
        form.setFieldsValue({
          email: res?.data?.user?.mail,
          lastName: res?.data?.user?.nom,
          lastName_ar: res?.data?.user?.nom_ar,
          firstName: res?.data?.user?.prenom,
          firstName_ar: res?.data?.user?.prenom_ar,
          gender: res?.data?.user?.civilite,
          nationality: res?.data?.user?.nationality,
          cin: res?.data?.user?.cin,
          passportNumber: res?.data?.user?.passport,
          birthPlace: res?.data?.user?.birthplace,
          birthDate: dayjs(res?.data?.user?.date_naiss_user),
          residenceGovernorate: res?.data?.user?.goverment,
          residenceDelegation: res?.data?.user?.delegation,
          adresse: res?.data?.user?.adresse,
          phone: res?.data?.user?.mobile,
          domaines_spetialite: res?.data?.user?.domaines_specialite,
          postal_code: res?.data?.user?.code_postal,
          niveau_scolaire: res?.data?.user?.niveau_scolaire,
          besoins_specifiques: res?.data?.user?.besoins_specifiques,
          delivrance_date:
            res?.data?.user?.delivrance_date &&
            dayjs(res?.data?.user?.delivrance_date, "DD/MM/YYYY"),
          lieu_naissance: res?.data?.user?.birthplace,
          autoriser_dossier: res?.data?.user?.autoriser_dossier,
          passportExpiry:
            res?.data?.user?.expiration_passeport &&
            dayjs(res?.data?.user?.expiration_passeport),
        });
        setNationalité(res?.data?.user?.nationality);
        getFiles(res?.data?.user?.cin, res?.data?.user?.passport);
        const delegations = await fetchDelegationListByGoverment(
          res?.data?.user?.goverment
        );
        setDelegations(delegations);
        const code_postaux = await fetchPostalCodeListByDelegation(
          res?.data?.user?.delegation
        );
        setPostalCodeList(code_postaux);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  const handleEdit = (values) => {
    setIsLoading(true);
    // const phoneNumber = values.phone.countryCode
    //   ? "+" +
    //     values.phone.countryCode +
    //     values.phone.areaCode +
    //     values.phone.phoneNumber
    //   : values.phone;
    const newPassword = form.getFieldValue("password_change_formateur");
    const payload = {
      adresse: {
        geom: "",
      },
      user: {
        acc_affaire: [],
        acc_opportunite: [],
        acces_avenant: 2,
        acces_communiques: 2,
        acces_courrier_entrant: 0,
        acces_distrib: 0,
        acces_edit_maileva: 2,
        acces_ett: true,
        acces_fusion: 2,
        acces_maileva: 2,
        acces_qualite: 2,
        acces_reclamation_ticket: 1,
        acces_signature: 0,
        act_grp_communication: 2,
        act_grp_negociateur: 2,
        act_grp_pubpostage: 2,
        act_grp_statut: 2,
        act_grp_tags: 2,
        active: 1,
        admin_restreint: 0,
        admin_tpv: 2,
        adresse: values.adresse,
        adresse_auto: "*",
        api_key: "",
        assistant: 0,
        beta: 0,
        birthplace: values?.lieu_naissance,
        bloc_env: 0,
        cin: values.cin,
        civilite: values.gender,
        code: null,
        comparateur: 0,
        date_integration: null,
        date_naiss_user: dayjs(values.birthDate).format("YYYY-MM-DD"),
        delegation: values.residenceDelegation,
        ecrit_comp_aff: 0,
        ecriture_transaction_financiere_client: 2,
        ecriture_transaction_financiere_distribution: 2,
        entreprise_id:
          role === "investisseur"
            ? import.meta.env.VITE_APP_ID_Entreprise_INVESTISSEUR
            : import.meta.env.VITE_APP_ID_Entreprise_FORMATEUR,
        espace_distrib_access: { acces_info_spec: 2, changement_statut: 2 },
        delivrance_date: dayjs(values.delivrance_date).format("DD/MM/YYYY"),
        expiration_passeport: form?.getFieldValue("passportExpiry")
          ? form?.getFieldValue("passportExpiry")
          : "",
        execution_tpv: 2,
        exp_aff: null,
        exp_maileva: 2,
        exp_opp: null,
        export_aff_det: 0,
        export_maileva_det: 2,
        export_opp_det: 0,
        fiche_dec: 0,
        filtre: 0,
        first_login: null,
        goverment: values?.residenceGovernorate,
        groupe_commission: null,
        groupe_user: [],
        groupe_user_alert: [],
        groupe_user_alert_nom: [],
        heure_debut: "0:00:00",
        heure_fin: null,
        hist: 2,
        hist_comm: 2,
        id: 1002234,
        identite_reelle: null,
        lect_comp_aff: 0,
        lecture_tpv: 2,
        lecture_transaction_financiere_client: 2,
        lecture_transaction_financiere_distribution: 2,
        limit_listing_aff: "100",
        limit_listing_maileva: "2",
        limit_listing_opp: "100",
        location: null,
        mail: values.email,
        manager: null,
        manager_de: [],
        manager_de_nom: [],
        manager_list: [],
        manager_nom: [],
        matricule_rh: null,
        mobile: values?.phone,
        autoriser_dossier: values?.autoriser_dossier,

        nationality: values.nationality,
        nom: values.lastName,
        lieu_naissance: values?.lieu_naissance,
        nom_ar: values.lastName_ar,
        nom_groupe_commission: null,
        nom_organisme: "",
        nom_producteur: null,
        nom_role: "",
        nom_zone: null,
        num_interne: null,
        orias_producteur: null,
        passport: values?.passportNumber,
        password: newPassword ? newPassword : "",
        photo: null,
        pointage: 0,
        prenom: values.firstName,
        prenom_ar: values.firstName_ar,

        prenom_producteur: null,
        proprietaire: true,
        qualite_producteur: null,
        role:
          role === "investisseur"
            ? import.meta.env.VITE_APP_ID_ROLE_INVESTISSEUR
            : import.meta.env.VITE_APP_ID_ROLE_FORMATEUR,
        role_auto: "[]",
        role_auto_nom: [],
        samedi_off: 0,
        signature: null,
        skype: null,
        ssl_mail_data: 0,
        super_admin: true,
        tab_code: [],
        tarificateur: 2,
        tel2: null,
        tel_urgence: null,
        telephone: values?.phone,
        transaction_financiere: 2,
        type_user: "c81e728d9d4c2f636f067f89cc14862c",
        user_groupe_nom: [],
        user_pwd_data: null,
        validate_paiement: 2,
        domaines_specialite: values.domaines_spetialite,
        code_postal: values.postal_code,
        niveau_scolaire: values.niveau_scolaire,
        besoins_specifiques: values.besoins_specifiques,
        zone: null,
      },
      ville: {},
    };
    axiosInstance
      .put(
        `${import.meta.env.VITE_APP_GEOPROD_API}/admin/update_user/${
          initialValues.id_user
        }`,
        payload,
        {
          headers: {
            Idsession: initialValues?.Acces_Token,
          },
        }
      )
      .then(() => {
        notification.success({
          message: t("Votre compte a été mis à jour avec succes"),
          duration: 0,
        });
        // navigate("/acceuil");
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        notification.error({
          message: error?.response?.data?.message,
          duration: 0,
        });
      });
  };

  const uploadFile = (file, key) => {
    setIsLoading(true);
    const formData = new FormData();

    formData.append("name_doc", file?.file?.name);
    formData.append("file[]", file?.file);
    formData.append("key", key);
    axiosInstance
      .post(
        `${import.meta.env.VITE_APP_GEOPROD_API}/document_formateur/upload`,
        formData,
        {
          headers: {
            Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
              ?.Acces_Token,
          },
        }
      )
      .then((res) => {
        getFiles(
          form.getFieldsValue()["cin"],
          form.getFieldsValue()["passportNumber"]
        );
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };
  const handlePreview = async (file) => {
    window.open(file.url);
  };
  const removeFile = (key) => {
    setIsLoading(true);
    axiosInstance
      .delete(
        `${
          import.meta.env.VITE_APP_GEOPROD_API
        }/document_formateur/remove?key=${key}`,

        {
          headers: {
            Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
              ?.Acces_Token,
          },
        }
      )
      .then((res) => {
        if (key === "copie_cin") {
          form.setFieldsValue({
            ...form.getFieldsValue(),
            copie_cin: "",
          });
          setCopieCinFileList([]);
        } else if (key === "copie_passeport") {
          form.setFieldsValue({
            ...form.getFieldsValue(),
            copie_passeport: "",
          });
          setCopiePasseportFileList([]);
        } else {
          form.setFieldsValue({
            ...form.getFieldsValue(),
            dossier_file: "",
          });
          setCopieDossierFileList([]);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <Form
      layout="vertical"
      style={{ display: "flex", justifyContent: "center" }}
      form={form}
      onFinish={(values) => handleEdit(values)}
    >
      <Card style={{ width: "90%" }}>
        <Row gutter={16}>
          {first_login === 1 && ehouwiya !== 1 && (
            <Col lg={12} md={24}>
              <Form.Item
                label={t("Mot de passe")}
                name="password_change_formateur"
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                  {
                    pattern:
                      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[*/.@-])[A-Za-z\d*/.@-]{6,}$/,
                    message: t(
                      "Le mot de passe doit contenir au moins 6 caractères, dont au moins un chiffre (0-9) et au moins un caractère spécial (/ . @ -)."
                    ),
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
          )}
          {first_login === 1 && ehouwiya !== 1 && (
            <Col lg={12} md={24}>
              <Form.Item
                label={t("Confirmer le mot de passe")}
                name="confirm_password_formateur"
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        !value ||
                        getFieldValue("password_change_formateur") === value
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          t(
                            "Le mot de passe et la confirmation doivent être identiques."
                          )
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
            </Col>
          )}

          <Col lg={12} md={24}>
            <Form.Item
              label="Adresse e-mail - البريد الإلكتروني"
              name="email"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
                {
                  type: "email",
                  message: t("Veuillez vérifier ce champ."),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={12} md={24}>
            <Form.Item
              label="Nom (En arabe)  - اللقب (بالعربية)"
              name="lastName_ar"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
                {
                  pattern: /^[\u0621-\u064A\s]+$/, // Same validation for Arabic alphabet
                  message: t(
                    "Veuillez entrer uniquement des caractères arabes."
                  ),
                },
                {
                  validator: (_, value) => {
                    if (value && value.trim() === "") {
                      return Promise.reject(
                        t("Veuillez remplir ce champ.")
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input disabled={nationalité === "تونسية"} />
            </Form.Item>
          </Col>
          <Col lg={12} md={24}>
            <Form.Item
              label="Prénom (En arabe) - الإسم (بالعربية)"
              name="firstName_ar"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
                {
                  pattern: /^[\u0621-\u064A\s]+$/, // Same validation for Arabic alphabet
                  message: t(
                    "Veuillez entrer uniquement des caractères arabes."
                  ),
                },
                {
                  validator: (_, value) => {
                    if (value && value.trim() === "") {
                      return Promise.reject(
                        t("Veuillez remplir ce champ.")
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input disabled={nationalité === "تونسية"} />
            </Form.Item>
          </Col>
          <Col lg={12} md={24}>
            <Form.Item
              label="Nom (En français)  - اللقب (بالفرنسية)"
              name="lastName"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
                {
                  pattern: /^[A-Za-zÀ-ÿ\s-]+$/, // Allows French alphabet (including accented characters), spaces, and hyphens
                  message: t(
                    "Veuillez entrer uniquement des caractères français."
                  ),
                },
                {
                  validator: (_, value) => {
                    if (value && value.trim() === "") {
                      return Promise.reject(
                        t("Veuillez remplir ce champ.")
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                // disabled={nationalité === "تونسية"}
                disabled={
                  nationalité === "تونسية" &&
                  form.getFieldValue("lastName") &&
                  form.getFieldValue("lastName") !== "" &&
                  ehouwiya !== 1
                }
              />
            </Form.Item>
          </Col>
          <Col lg={12} md={24}>
            <Form.Item
              label="Prénom (En français) - الإسم (بالفرنسية)"
              name="firstName"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
                {
                  pattern: /^[A-Za-zÀ-ÿ\s-]+$/, // Allows French alphabet (including accented characters), spaces, and hyphens
                  message: t(
                    "Veuillez entrer uniquement des caractères français."
                  ),
                },
                {
                  validator: (_, value) => {
                    if (value && value.trim() === "") {
                      return Promise.reject(
                        t("Veuillez remplir ce champ.")
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                disabled={
                  nationalité === "تونسية" &&
                  form.getFieldValue("firstName") &&
                  form.getFieldValue("firstName") !== "" &&
                  ehouwiya !== 1
                }
              />
            </Form.Item>
          </Col>
          <Col lg={12} md={24}>
            <Form.Item
              label="Genre - الجنس"
              name="gender"
              rules={[
                {
                  required: true,
                  message: t("Veuillez sélectionner un genre."),
                },
              ]}
            >
              <Select
                disabled={nationalité === "تونسية"}
                options={[
                  {
                    value: "ذكر",
                    label: "Masculin - ذكر",
                  },
                  {
                    value: "أنثى",
                    label: "Féminin - أنثى",
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col lg={12} md={24}>
            <Form.Item
              label="Nationalité - الجنسية"
              name="nationality"
              rules={[
                {
                  required: true,
                  message: t("Veuillez sélectionner une nationalité."),
                },
              ]}
            >
              <Select
                optionFilterProp="label"
                showSearch
                disabled={nationalité === "تونسية"}
                onChange={(e) => setNationalité(e)}
                options={nationalityOptions}
              />
            </Form.Item>
          </Col>
          {nationalité === "تونسية" && (
            <Col lg={12} md={24}>
              <Form.Item
                label="Numéro CIN - رقم بطاقة التعريف الوطنية"
                name="cin"
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                ]}
              >
                <Input
                  maxLength={8}
                  onChange={(e) =>
                    form.setFieldValue(
                      "cin",
                      e.target.value.replace(/[^0-9]/g, "")
                    )
                  }
                  disabled
                />
              </Form.Item>
            </Col>
          )}
          {nationalité !== "تونسية" && (
            <Col lg={12} md={24}>
              <Form.Item
                label={t("N° passeport")}
                name="passportNumber"
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                  {
                    validator: (_, value) => {
                      if (value && value.trim() === "") {
                        return Promise.reject(t("Veuillez remplir ce champ."));
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                // onChange={(e) =>
                //   form.setFieldValue(
                //     "passportNumber",
                //     e.target.value.replace(/[^0-9]/g, "")
                //   )
                // }
                />
              </Form.Item>
            </Col>
          )}
          {nationalité !== "تونسية" && (
            <Col lg={12} md={24}>
              <Form.Item
                label={t("Date d’expiration du passeport")}
                name="passportExpiry"
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  format="DD/MM/YYYY"
                  placeholder=""
                  disabledDate={(current) => {
                    // Disable future dates by checking if the current date is after today
                    return current && current > dayjs().endOf("day");
                  }}
                />
              </Form.Item>
            </Col>
          )}
          <Col lg={12} md={24}>
            <Form.Item
              label="Date de naissance - تاريخ الولادة"
              name="birthDate"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
              ]}
            >
              <DatePicker
                disabled={nationalité === "تونسية"}
                format="DD/MM/YYYY"
                style={{ width: "100%" }}
                placeholder=""
                disabledDate={(current) => {
                  // Disable future dates by checking if the current date is after today
                  return current && current > dayjs().endOf("day");
                }}
              />
            </Form.Item>
          </Col>

          {nationalité === "تونسية" && (
            <Col lg={12} md={24}>
              <Form.Item
                label="Date de délivrance de la CIN - تاريخ إصدار بطاقة التعريف الوطنية"
                name="delivrance_date"
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                ]}
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  style={{ width: "100%" }}
                  placeholder=""
                  disabledDate={(current) => {
                    const date = form.getFieldValue("birthDate");
                    return (
                      current &&
                      (current > dayjs().endOf("day") ||
                        (date && current < dayjs(date)))
                    );
                  }}
                />
              </Form.Item>
            </Col>
          )}
          <Col lg={12} md={24}>
            <Form.Item
              label="Adresse de résidence (En arabe) - عنوان الإقامة (بالعربية)"
              name="adresse"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
                {
                  validator: (_, value) => {
                    if (value && value.trim() === "") {
                      return Promise.reject(t("Veuillez remplir ce champ."));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col lg={12} md={24}>
            <Form.Item
              label="Lieu de naissance (En arabe) - مكان الولادة (بالعربية)"
              name="lieu_naissance"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
                {
                  validator: (_, value) => {
                    if (value && value.trim() === "") {
                      return Promise.reject(t("Veuillez remplir ce champ."));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input disabled={nationalité === "تونسية" && ehouwiya !== 1} />
            </Form.Item>
          </Col>

          <Col lg={12} md={24}>
            <Form.Item
              label="Gouvernorat de résidence - ولاية الإقامة"
              name="residenceGovernorate"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
              ]}
            >
              <Select
                optionFilterProp="label"
                showSearch
                onChange={handleGouvernoratChange}
                options={gouvernoratOptions}
              />
            </Form.Item>
          </Col>
          <Col lg={12} md={24}>
            <Form.Item
              label="Délégation de résidence - معتمدية الإقامة"
              name="residenceDelegation"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
              ]}
            >
              <Select
                showSearch
                onChange={handleDelegationChange}
                optionFilterProp="label"
                filterOption={(input, option) =>
                  option?.children?.toLowerCase().includes(input.toLowerCase())
                }
              >
                {delegations?.map((delegation) => (
                  <Option key={delegation.text} value={delegation.value}>
                    {delegation.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={12} md={24}>
            <Form.Item
              label="Code postal - الترقيم البريدي"
              name="postal_code"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
              ]}
            >
              <Select
                showSearch
                optionFilterProp="label"
                filterOption={(input, option) =>
                  option?.children?.toLowerCase().includes(input?.toLowerCase())
                }
                notFoundContent={
                  <div style={{ textAlign: "center", padding: "10px", color: "#999" }}>
                    {t("Veuillez d'abord remplir le champ précédent !")}
                  </div>
                }
              >
                {postalCodeList?.map((postalCode) => (
                  <Option key={postalCode?.text} value={postalCode?.value}>
                    {postalCode?.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col lg={12} md={24}>
            <Form.Item
              label="Numéro de téléphone - رقم الهاتف"
              name="phone"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
                {
                  pattern: new RegExp(/^\d{8}$/),
                  message: t("Veuillez vérifier ce champ."),
                },
              ]}
            >
              <Input
                maxLength={8}
                onChange={(e) =>
                  form.setFieldValue(
                    "phone",
                    e.target.value.replace(/[^0-9]/g, "")
                  )
                }
              />
            </Form.Item>
          </Col>

          <Col lg={12} md={24}>
            <Form.Item
              label="Niveau scolaire - المستوى الدراسي"
              name="niveau_scolaire"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
              ]}
            >
              <Select options={niveauScolaire} />
            </Form.Item>
          </Col>

          <Col lg={12} md={24}>
            <Form.Item
              label="Domaines de spécialité - مجالات التخصص"
              name="domaines_spetialite"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
                {
                  validator: (_, value) => {
                    if (value && value.trim() === "") {
                      return Promise.reject(t("Veuillez remplir ce champ."));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          {nationalité === "تونسية" ? (
            <Col lg={12} md={24}>
              <Form.Item
                label="Copie de votre CIN - نسخة من بطاقة التعريف الوطنية"
                name="copie_cin"
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                ]}
              >
                <Upload
                  name="copie_cin"
                  customRequest={(file) => {
                    uploadFile(file, "copie_cin");
                  }}
                  onRemove={() => removeFile("copie_cin")}
                  maxCount={1}
                  fileList={copieCinFileList}
                  onPreview={handlePreview}
                  showUploadList={{
                    showPreviewIcon: true,
                  }}
                  accept="jpg,.jpeg,.png,.pdf"
                >
                  <Button icon={<UploadOutlined />}>CIN</Button>
                </Upload>
              </Form.Item>
            </Col>
          ) : (
            <Col lg={12} md={24}>
              <Form.Item
                label="Copie du passeport - نسخة من جواز السفر"
                name="copie_passeport"
                rules={[
                  {
                    required: true,
                    message: t("Veuillez remplir ce champ."),
                  },
                ]}
              >
                <Upload
                  name="copie_passeport"
                  customRequest={(file) => uploadFile(file, "copie_passeport")}
                  onRemove={() => removeFile("copie_passeport")}
                  fileList={copiePasseportFileList}
                  onPreview={handlePreview} // Handle file preview
                  showUploadList={{
                    showPreviewIcon: true, // Show preview icon
                  }}
                  accept="jpg,.jpeg,.png,.pdf"
                >
                  <Button icon={<UploadOutlined />}>Passport</Button>
                </Upload>
              </Form.Item>
            </Col>
          )}

          <Col lg={12} md={24}>
            <Form.Item
              label="Copie de votre dossier en tant que formateur - نسخة من ملفكم كمكون"
              name="dossier_file"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
              ]}
            >
              <Upload
                name="dossier_file"
                customRequest={(file) => uploadFile(file, "dossier_file")}
                onRemove={() => removeFile("dossier_file")}
                maxCount={1}
                fileList={copieDossierFileList}
                onPreview={handlePreview}
                showUploadList={{
                  showPreviewIcon: true,
                }}
                accept="jpg,.jpeg,.png,.pdf"
              >
                <Button icon={<UploadOutlined />}>Dossier</Button>
              </Upload>
            </Form.Item>
          </Col>

          <Col lg={12} md={24}>
            <Form.Item
              label="Personne à besoin spécifique - شخص ذو إحتياجات خصوصية "
              name="besoins_specifiques"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
              ]}
            >
              <Radio.Group>
                <Radio value="نعم">Oui - نعم</Radio>
                <Radio value="لا">Non - لا</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col lg={12} md={24}>
            <Form.Item
              label="Autorisez-vous les établissements de formation privés avec lesquels vous collaborez à accéder à votre dossier ? - هل تسمح لمؤسسات التكوين المهني الخاصة التي تتعامل معها بالاطلاع على ملفك ؟"
              name="autoriser_dossier"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
              ]}
            >
              <Radio.Group>
                <Radio value="نعم">Oui - نعم</Radio>
                <Radio value="لا">Non - لا</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col lg={24}>
            <Button type="primary" htmlType="submit">
              {t("Mettre à jours mes données")}
            </Button>
          </Col>

          {/* <Col lg={12} md={24}>
            <Form.Item
              label={t("Lieu de naissance")}
              name="birthPlace"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col> */}

          {/* <Col lg={12} md={24}>
            <Form.Item
              label={t("Adresse")}
              name="address"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={12} md={24}>
            <Form.Item
              label={t("Gouvernorat de résidence")}
              name="residenceGovernorate"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
              ]}
            >
              <Select onChange={handleGouvernoratChange}>
                {Object.keys(gouvernorats).map((gouvernorat) => (
                  <Option key={gouvernorat} value={gouvernorat}>
                    {gouvernorat}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={12} md={24}>
            <Form.Item
              label={t("Délégation de résidence")}
              name="residenceDelegation"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
              ]}
            >
              <Select>
                {delegations.map((delegation) => (
                  <Option key={delegation} value={delegation}>
                    {delegation}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={12} md={24}>
            <Form.Item
              label={t("Code postal")}
              name="postalCode"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col lg={12} md={24}>
            <Form.Item
              label={t("Numéro de téléphone")}
              name="phoneNumber"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
              ]}
            >
              <PhoneInput
                country="tn" // Default country code
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col lg={12} md={24}>
            <Form.Item
              label={t("Mot de passe")}
              name="password"
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
          <Col lg={12} md={24}>
            <Form.Item
              label={t("Confirmer le mot de passe")}
              name="confirmPassword"
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: t("Veuillez remplir ce champ."),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(t("Les mots de passe ne correspondent pas."))
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
          <Col lg={12} md={24}>
            <Form.Item
              label={t("Personne à besoin spécifique")}
              name="specialNeeds"
              valuePropName="checked"
            >
              <Checkbox>{t("Aucun Personne à besoin spécifique")}</Checkbox>
            </Form.Item>
          </Col> */}
        </Row>
      </Card>
    </Form>
  );
};

export default Profile;
