import { Input, notification } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
const { Search } = Input;
import { useLoader } from "../../../context/LoaderContext";
const SearchInputComponent = ({
  question,
  expression,
  apiCall,
  minLength,
  maxLength,
  regex,
  message,
  survey,
  
}) => {
  const { t } = useTranslation();
  const {showingPreview}=useLoader()
  const searchFunction = (value) => {
    if (!value || value.trim() === "") {
      notification.error({
        message: t("Erreur"),
        description: t(
          `Veuillez remplir le champ avant de lancer la recherche`
        ),
        duration: 0,
      });
      return;
    }
    if (regex && !regex.test(value)) {
      notification.error({
        message: t("Erreur"),
        description: message,
        duration: 0,
      });
      return;
    }
    apiCall();
  };

  useEffect(() => {

    question.showRefreshBar = (isVisible) => {
      const searchInput = document?.querySelector(
        `div[data-name="${question?.name}"]`
      );
      if (searchInput) {
        searchInput.style.display = "block";
      }
    };
    return () => {
      delete question.showRefreshBar;
    };
  }, [question]);

  const onChange = (e) => {
    if (e) {
      question.value = e.target.value;
    } else {
      question.value = null;
    }
  };
  return (
    <Search
      value={question.value ? question.value : null}
      onSearch={searchFunction}
      onChange={onChange}
      maxLength={maxLength}
      disabled={
        question.readOnly ||
        sessionStorage.getItem("consulter") === "true" ||
        showingPreview
      }
    />
  );
};

export default SearchInputComponent;
