export const TICKET_STATUS_MD5 = {
  pending: import.meta.env.VITE_APP_ID_STATUS_TICKET_PENDING_MD5,
  closed: import.meta.env.VITE_APP_ID_STATUS_TICKET_CLOSED_MD5,
  needInfo: import.meta.env.VITE_APP_ID_STATUS_TICKET_NEED_INFO_MD5,
  inProgress: import.meta.env.VITE_APP_ID_STATUS_TICKET_IN_PROGRESS_MD5,
};

export const TICKET_STATUS = {
  pending: import.meta.env.VITE_APP_ID_STATUS_TICKET_PENDING,
  closed: import.meta.env.VITE_APP_ID_STATUS_TICKET_CLOSED,
  needInfo: import.meta.env.VITE_APP_ID_STATUS_TICKET_NEED_INFO,
  inProgress: import.meta.env.VITE_APP_ID_STATUS_TICKET_IN_PROGRESS,
};
