import { notification } from "antd";
import { checkCitizenStatusByCinDn } from "../../../../api/extern";
import axiosInstance from "../../../../utils/axios";
import {
  getCommuneByCodeCommune,
  getVillesById,
} from "../../../../context/FetchLists";

export const checkDNCINDirector = async (
  survey,
  t,
  getSurveyData,
  setSurveyData
) => {
  const dn = survey.getValue("establishment_director_data_birth_date");
  const cin = survey.getValue("establishment_director_data_cin_number");
  const identity = survey.getValue("establishment_director_data_identity");
  let data;
  const valid = survey.getQuestionByName(
    "establishment_director_data_test_valid"
  );
  const spinner = survey.getQuestionByName(
    "establishment_director_data_spinner"
  );

  if (!dn || !cin || !identity) {
    valid.value = false;
    notification.error({
      message: t("Erreur"),
      description: t("Veuillez remplir tous les champs obligatoires."),
      duration: 0,
    });
    return;
  } else if (cin.length !== 8) {
    valid.value = false;
    notification.error({
      message: t("Erreur"),
      description: t(
        "Le numéro cin doit être au format 8 chiffres(ex: 11111111)."
      ),
      duration: 0,
    });
    return;
  }

  spinner.visible = true;

  axiosInstance
    .post(`${import.meta.env.VITE_APP_GEOPROD_API}/admin/check_cin_role`, {
      cin: cin,
      role: import.meta.env.VITE_APP_ID_ROLE_DIRECTEUR,
    })
    .then(async (res) => {
      const page = survey.getPageByName(
        "Données de Directeur de l’Établissement"
      );
      if (res.data.cin_pass) {
        spinner.visible = false;
        // page.questions.map((question) => {
        //   if (question.name !== "establishment_director_data_identity")
        //     question.readOnly = true;
        // });
        notification.error({
          message: t("Erreur"),
          description: t(
            "Le directeur ajouté est fonctionnel dans un autre EPF."
          ),
          duration: 0,
        });
        return;
      } else {
        data = await checkCitizenStatusByCinDn(dn, cin);

        if (data) {
          valid.value = true;
          spinner.visible = false;

          page.questions.map((question) => {
            question.readOnly = false;
            if (
              question.name !== "establishment_director_data_test_valid" &&
              question.name !== "establishment_director_data_passeport_copie" &&
              question.name !== "copie_autorisation_ministre" &&
              question.name !== "establishment_director_data_cin_copie" &&
              question.name !== "establishment_director_data_date_delivrance" &&
              question.name !==
                "establishment_director_data_ministerial_auth_date" &&
              question.name !== "establishment_director_data_passeport" &&
              question.name !==
                "establishment_director_date_expiration_passeport" &&
              question.name !== "establishment_director_data_cin_number" &&
              question.name !== "establishment_director_data_spinner"
            ) {
              question.visible = true;
            }
            if (
              survey.getQuestionByName(
                "establishment_director_data_nationality"
              ).value === "تونسية"
            ) {
              if (
                question.name === "establishment_director_data_cin_copie" ||
                question.name ===
                  "establishment_director_data_date_delivrance" ||
                question.name === "director_search"
              ) {
                question.visible = true;
              }
            } else if (
              survey.getQuestionByName(
                "establishment_director_data_nationality"
              ).value !== "تونسية"
            ) {
              if (
                question.name === "establishment_director_data_test_valid" ||
                question.name ===
                  "establishment_director_data_passeport_copie" ||
                question.name === "copie_autorisation_ministre" ||
                question.name ===
                  "establishment_director_data_ministerial_auth_date"
              ) {
                question.visible = true;
              }
            }
          });

          survey.setValue("establishment_director_data_last_name", data?.nomAr);
          survey.setValue(
            "establishment_director_data_first_name",
            data?.prenomAr
          );

          const name_ar = survey.getQuestionByName(
            "establishment_director_data_last_name"
          );
          const prenom_ar = survey.getQuestionByName(
            "establishment_director_data_first_name"
          );

          name_ar.readOnly = true;
          prenom_ar.readOnly = true;
          const name_fr = survey.getQuestionByName(
            "establishment_director_data_last_name_fr"
          );
          const prenom_fr = survey.getQuestionByName(
            "establishment_director_data_first_name_fr"
          );
          if (data?.nomFr) {
            const surveyData = await getSurveyData();
            if (surveyData) {
              surveyData.establishment_data_names_fr = true;
              setSurveyData(surveyData);
            }

            survey.data = {
              ...survey.data,
              establishment_data_names_fr: true,
            };

            survey.setValue(
              "establishment_director_data_last_name_fr",
              data?.nomFr
            );
            survey.setValue(
              "establishment_director_data_first_name_fr",
              data?.prenomFr
            );

            name_fr.readOnly = true;
            prenom_fr.readOnly = true;
          } else {
            name_fr.readOnly = false;
            prenom_fr.readOnly = false;
          }
          survey.setValue(
            "establishment_director_data_genre",
            data?.genre === "1" ? "ذكر" : "أنثى"
          );
          const genre = survey.getQuestionByName(
            "establishment_director_data_genre"
          );
          genre.readOnly = true;

          if (data?.commune) {
            let lieu_naissance = await getCommuneByCodeCommune(data?.commune);

            survey.setValue(
              "establishment_director_data_birth_place",
              lieu_naissance
            );

            const birthplace = survey.getQuestionByName(
              "establishment_director_data_birth_place"
            );
            birthplace.readOnly = true;
          }
        } else {
          valid.value = false;
          spinner.visible = false;

          const excludedQuestions = [
            "establishment_director_data_nationality",
            "establishment_director_data_cin_number",
            "establishment_director_data_passeport",
            "establishment_director_date_expiration_passeport",
            "establishment_director_data_birth_date",
            "establishment_director_data_identity",
            "director_search",
          ];

          page.getQuestions().forEach((question) => {
            if (!excludedQuestions.includes(question.name)) {
              question.visible = false;
            }
          });
        }
      }
    })
    .catch(() => {
      spinner.visible = false;
    });

  // setIsLoading(false);
};

export const getCadre = async (survey, t, getSurveyData, setSurveyData) => {
  const management_data = survey.getQuestionByName("management_data");

  management_data?.panels.map(async (panel) => {
    if (
      survey.getQuestionByName("management_data").getItemIndex() - 1 ===
      survey.getQuestionByName("management_data").panels.indexOf(panel)
    ) {
      const changedData = panel.getValue();
      const spinner = panel.getQuestionByName("management_data_check_spinner");
      const valid = panel.getQuestionByName("management_data_staff_valid");
      const cinNumber = changedData?.management_data_staff_cin_number;
      const birthDate = changedData?.management_data_staff_birth_date;

      try {
        if (!birthDate || !cinNumber) {
          valid.value = false;
          notification.error({
            message: t("Erreur"),
            description: t("Veuillez remplir tous les champs obligatoires."),
            duration: 0,
          });
          return;
        } else if (cinNumber.length !== 8) {
          valid.value = false;
          notification.error({
            message: t("Erreur"),
            description: t(
              "Le numéro cin doit être au format 8 chiffres(ex: 11111111)."
            ),
            duration: 0,
          });
          return;
        }

        spinner.visible = true;

        const data = await checkCitizenStatusByCinDn(birthDate, cinNumber);

        if (data) {
          valid.value = true;
          spinner.visible = false;
          panel.getQuestions().forEach((question) => {
            if (
              question.name !== "management_data_staff_cin_number" &&
              question.name !== "management_data_staff_passport_number" &&
              question.name !== "management_data_staff_date_delivrance" &&
              question.name !== "management_data_staff_cin_copy" &&
              question.name !== "management_data_staff_passport_copy" &&
              question.name !== "management_data_staff_valid" &&
              question.name !== "management_data_check_spinner"
            ) {
              question.visible = true;
            }

            if (
              question.name === "management_data_staff_cin_copy" ||
              question.name === "management_data_staff_date_delivrance" ||
              question.name === "management_data_search"
            ) {
              question.visible = true;
            }
          });

          panel.getQuestionByName("management_data_staff_last_name").value =
            data?.nomAr || "";
          panel.getQuestionByName("management_data_staff_first_name").value =
            data?.prenomAr || "";

          panel.getQuestionByName("management_data_staff_civility").value =
            data?.genre === "1" ? "ذكر" : "أنثى";
          const nom_fr = panel.getQuestionByName(
            "management_data_staff_last_name_fr"
          );
          const prenom_fr = panel.getQuestionByName(
            "management_data_staff_first_name_fr"
          );
          if (data?.nomFr) {
            const surveyData = await getSurveyData();
            survey.data = {
              ...survey.data,
              management_data_names_fr: {
                [survey
                  .getQuestionByName("management_data")
                  .panels.indexOf(panel)]: true,
              },
            };
            if (surveyData) {
              surveyData.management_data_names_fr = {
                ...surveyData.management_data_names_fr,
                [survey
                  .getQuestionByName("management_data")
                  .panels.indexOf(panel)]: true,
              };
              setSurveyData(surveyData);
            }

            nom_fr.value = data?.nomFr || "";
            prenom_fr.value = data?.prenomFr || "";
            nom_fr.readOnly = true;
            prenom_fr.readOnly = true;
          } else {
            nom_fr.readOnly = false;
            prenom_fr.readOnly = false;
          }
        } else {
          valid.value = false;
          spinner.visible = false;
          const excludedQuestions = [
            "management_data_staff_nationality",
            "management_data_staff_passport_number",
            "management_data_staff_cin_number",
            "management_data_staff_birth_date",
            "management_data_search",
          ];
          panel.getQuestions().forEach((question) => {
            if (!excludedQuestions.includes(question.name)) {
              question.visible = false;
            }
          });
        }
      } catch (error) {
        valid.value = false;
        notification.error({
          message: t("Erreur"),
          description: error?.response?.data?.message || "An error occurred.",
          duration: 0,
        });
      }
    }
  });
};

export const searchClick = async (survey, getSurveyData, contrat_id, t) => {
  survey.setValue("validation_provisoire_check", false);
  const data = await getSurveyData();
  const matricule = survey.getValue("numero_matricule_fiscal_numero_IURNE");
  const etat_secondaire = survey.getValue("numero_etablissement_secondaire");
  const regex_matricule = /^[0-9]{7}[A-Z]$/;
  const regex_secondaire = /^\d{3}$/;
  if (!matricule || !etat_secondaire) {
    notification.error({
      message: "Erreur",
      description:
        "Veuillez remplir les champs Matricule fiscal / Numéro IURN et Numéro État secondaire.",
    });
  } else if (!regex_matricule.test(matricule)) {
    notification.error({
      message: t("Erreur"),
      description: t(
        "Le numéro de matricule fiscal ou le numéro IURNE doit respecter le format suivant : 7 chiffres suivis d'une lettre majuscule (exemple : 0000000A)."
      ),
      duration: 0,
    });
  }
  //  else if (
  //   contrat_id === import.meta.env.VITE_ID_LIGNE_PRODUIT_ANNEXE &&
  //   etat_secondaire === "000"
  // ) {
  //   notification.error({
  //     message: t("Erreur"),
  //     description: t(
  //       "Le numéro d'établissement secondaire d'une annexe ne peut pas être défini avec la valeur 000."
  //     ),
  //     duration: 0,
  //   });
  // }
  else if (!regex_secondaire.test(etat_secondaire)) {
    notification.error({
      message: t("Erreur"),
      description: t(
        "Le numéro d'État secondaire doit être composé uniquement de 3 chiffres (exemple : 111)."
      ),
      duration: 0,
    });
  } else {
    if (survey.data.numero_etablissement_secondaire === "000") {
      survey.getQuestionByName("data_spinner").visible = true;
      const registreRne = await getExtraitRegistreEntrepriseParId(
        survey,
        data,
        t
      );
      if (registreRne) {
        await getEmployeurByPatNumPatCL(survey, t);
        await getFicheEntrepriseDGI(survey, t);
        await getListActiviteRNE(survey, data, contrat_id, t);
      }
    } else {
      survey.getQuestionByName("data_spinner").visible = true;
      const registreRne = await getExtraitRegistreEntrepriseParId(
        survey,
        data,
        t
      );
      if (registreRne) {
        await getEmployeurByPatNumPatCL(survey, t);
        await getEtablissementSecondaireDgi(survey, data, contrat_id, t);
      }
    }
  }
};

export const getEmployeurByPatNumPatCL = async (survey, t) => {
  const value =
    survey.getValue("numero_matricule_fiscal_numero_IURNE") ||
    survey.getValue("establishment_data_tax_number") ||
    survey.getValue("legal_entity_tax_number");
  // survey.getQuestionByName("data_spinner").visible = true;
  try {
    const response = await axiosInstance.get(
      `${
        import.meta.env.VITE_APP_GEOPROD_API
      }/config/CNSS_EmployeurByPatNumPatCl?patNum=${value.slice(
        0,
        7
      )}&patCle=${value.slice(-1)}`
    );
    // survey.getQuestionByName("data_spinner").visible = false;
    survey.setValue(
      "numero_matricule_CNSS",
      `${response?.data?.message?.id?.empMat}${response?.data?.message?.id?.empCle}`
    );
    survey.setValue(
      "denomination_officielle_CNSS",
      response?.data?.message?.empRais
    );
  } catch (error) {
    survey.getQuestionByName("data_spinner").visible = false;
    notification.error({
      message: t("Erreur"),
      description: t(
        "Un service externe à SIGAF ne répond pas.Veuillez réessayer. Si le problème persiste, essayez plus tard."
      ),
      duration: 0,
    });
    throw error;
  }
};

export const getFicheEntrepriseDGI = async (survey, t) => {
  // ok
  const value =
    survey.getValue("numero_matricule_fiscal_numero_IURNE") ||
    survey.getValue("establishment_data_tax_number") ||
    survey.getValue("legal_entity_tax_number");
  // survey.getQuestionByName("data_spinner").visible = true;
  try {
    const response = await axiosInstance.get(
      `${
        import.meta.env.VITE_APP_GEOPROD_API
      }/config/FicheEntreprise?typeid=${1}&ident=${value}`
    );

    // survey.getQuestionByName("data_spinner").visible = false;
    survey.setValue("adresse_epf_DGI", response?.data?.message?.adresse);
    survey.setValue(
      "activite_principale_DGI",
      response?.data?.message?.activiteP
    );
    survey.setValue(
      "activite_secondaire_DGI",
      response?.data?.message?.activiteS
    );
  } catch (error) {
    survey.getQuestionByName("data_spinner").visible = false;
    notification.error({
      message: t("Erreur"),
      description:
        t(error?.response?.data?.message) ||
        t(
          "Une interruption est survenue lors de la récupération des données. Veuillez réessayer."
        ),
      duration: 0,
    });
    throw error;
  }
};

export const getListActiviteRNE = async (survey, data, contrat_id, t) => {
  const value = survey.getValue("numero_matricule_fiscal_numero_IURNE");
  // const contrat_id = sessionStorage.getItem("contrat_id");

  // survey.getQuestionByName("data_spinner").visible = true;
  try {
    const response = await axiosInstance.get(
      `${
        import.meta.env.VITE_APP_GEOPROD_API
      }/config/RNE_Listeactivites?Identifiant_Unique=${value}`
    );
    // survey.getQuestionByName("data_spinner").visible = false;

    const firstPrimary = response?.data?.message?.Activities?.activite.find(
      (activity) => activity?.type_activite === "PRIMAIRE"
    );
    const firstSecondary = response?.data?.message?.Activities?.activite.find(
      (activity) => activity?.type_activite === "SECONDAIRE"
    );
    survey.setValue(
      "activite_principale_RNE",
      firstPrimary?.detail_activite_arabe
    );
    survey.setValue(
      "activite_secondaire_RNE",
      firstSecondary?.detail_activite_arabe
    );

    survey.getQuestionByName("numero_matricule_CNSS").visible = true;
    survey.getQuestionByName("denomination_officielle_CNSS").visible = true;
    survey.getQuestionByName(
      "denomination_officielle_RNE_arabe"
    ).visible = true;
    survey.getQuestionByName(
      "denomination_officielle_RNE_latin"
    ).visible = true;
    survey.getQuestionByName(
      "denomination_commerciale_RNE_arabe"
    ).visible = true;
    survey.getQuestionByName(
      "denomination_commerciale_RNE_latin"
    ).visible = true;
    survey.getQuestionByName("adresse_EPF").visible = true;
    survey.getQuestionByName("adresse_epf_DGI").visible = true;
    survey.getQuestionByName("activite_principale_DGI").visible = true;
    survey.getQuestionByName("activite_secondaire_DGI").visible = true;

    survey.getQuestionByName(
      "date_expiration_protection_civile"
    ).visible = true;
    survey.getQuestionByName("doc_rne").visible = true;
    if (
      data?.investor_RNE ===
      "الحجز في السجل الوطني للمؤسسات فقط (شركة قيد التأسيس)"
    ) {
      survey.getQuestionByName("doc_patente").visible = true;
    }
    if (contrat_id !== import.meta.env.VITE_ID_LIGNE_PRODUIT_ANNEXE) {
      survey.getQuestionByName("doc_affiliation_CNSS").visible = true;
    }

    survey.getQuestionByName(
      "doc_attestation_protection_civile"
    ).visible = true;
    if (
      data?.investor_nationality === "تونسية" &&
      contrat_id !== import.meta.env.VITE_ID_LIGNE_PRODUIT_ANNEXE
    ) {
      survey.getQuestionByName("doc_b3_investisseur").visible = true;
    }

    if (
      data?.establishment_director_data_identity === "مدير غير المستثمر" &&
      data?.establishment_director_data_nationality === "تونسية"
    ) {
      const questionName =
        contrat_id === import.meta.env.VITE_ID_LIGNE_PRODUIT_ANNEXE
          ? "doc_b3_responsable"
          : "doc_b3_directeur";
      survey.getQuestionByName(questionName).visible = true;
    }
    survey.getQuestionByName("doc_autres").visible = true;

    survey.getQuestionByName("adresse_epf_RNE").visible = true;
    survey.getQuestionByName("activite_principale_RNE").visible = true;
    survey.getQuestionByName("activite_secondaire_RNE").visible = true;
    survey.setValue("validation_provisoire_check", true);
    survey.getQuestionByName("data_spinner").visible = false;
  } catch (error) {
    survey.getQuestionByName("data_spinner").visible = false;
    notification.error({
      message: t("Erreur"),
      description: t(
        "Un service externe à SIGAF ne répond pas.Veuillez réessayer. Si le problème persiste, essayez plus tard."
      ),
      duration: 0,
    });
    throw error;
  }
};

export const getEtablissementSecondaireDgi = async (
  survey,
  data,
  contrat_id,
  t
) => {
  const value = survey.getValue("numero_matricule_fiscal_numero_IURNE");
  const etat_secondaire = survey.getValue("numero_etablissement_secondaire");
  survey.getQuestionByName("data_spinner").visible = true;
  // const contrat_id = sessionStorage.getItem("contrat_id");

  try {
    const response = await axiosInstance.get(
      `${
        import.meta.env.VITE_APP_GEOPROD_API
      }/config/DGI_EtabSecondaires?matfis=${value}`
    );
    // survey.getQuestionByName("data_spinner").visible = false;
    const etablissement = response?.data?.message?.ListEtab?.find(
      (activity) => activity?.etabNum === etat_secondaire
    );

    if (response?.data?.message?.Code === 0) {
      survey.setValue("adresse_epf_DGI", etablissement?.etabAdr);
      survey.setValue("activite_principale_DGI", etablissement?.etabLibacpr);
      survey.setValue("activite_secondaire_DGI", etablissement?.etabLibacse);

      survey.getQuestionByName("numero_matricule_CNSS").visible = true;
      survey.getQuestionByName("denomination_officielle_CNSS").visible = true;
      survey.getQuestionByName(
        "denomination_officielle_RNE_arabe"
      ).visible = true;
      survey.getQuestionByName(
        "denomination_officielle_RNE_latin"
      ).visible = true;
      survey.getQuestionByName(
        "denomination_commerciale_RNE_arabe"
      ).visible = true;
      survey.getQuestionByName(
        "denomination_commerciale_RNE_latin"
      ).visible = true;
      survey.getQuestionByName("adresse_EPF").visible = true;
      survey.getQuestionByName("adresse_epf_DGI").visible = true;
      survey.getQuestionByName("activite_principale_DGI").visible = true;
      survey.getQuestionByName("activite_secondaire_DGI").visible = true;

      survey.getQuestionByName(
        "date_expiration_protection_civile"
      ).visible = true;
      survey.getQuestionByName("doc_rne").visible = true;
      if (
        data?.investor_RNE ===
        "الحجز في السجل الوطني للمؤسسات فقط (شركة قيد التأسيس)"
      ) {
        survey.getQuestionByName("doc_patente").visible = true;
      }
      if (contrat_id !== import.meta.env.VITE_ID_LIGNE_PRODUIT_ANNEXE) {
        survey.getQuestionByName("doc_affiliation_CNSS").visible = true;
      }

      survey.getQuestionByName(
        "doc_attestation_protection_civile"
      ).visible = true;
      if (
        data?.investor_nationality === "تونسية" &&
        contrat_id !== import.meta.env.VITE_ID_LIGNE_PRODUIT_ANNEXE
      ) {
        survey.getQuestionByName("doc_b3_investisseur").visible = true;
      }

      if (
        data?.establishment_director_data_identity === "مدير غير المستثمر" &&
        data?.establishment_director_data_nationality === "تونسية"
      ) {
        const questionName =
          contrat_id === import.meta.env.VITE_ID_LIGNE_PRODUIT_ANNEXE
            ? "doc_b3_responsable"
            : "doc_b3_directeur";
        survey.getQuestionByName(questionName).visible = true;
      }
      survey.getQuestionByName("doc_autres").visible = true;
      survey.setValue("validation_provisoire_check", true);
      survey.getQuestionByName("data_spinner").visible = false;
    } else {
      survey.getQuestionByName("data_spinner").visible = false;
      notification.error({
        message: t("Erreur"),
        description: t(`${response?.data?.message?.message}`),
        duration: 0,
      });
    }
  } catch (error) {
    survey.getQuestionByName("data_spinner").visible = false;
    notification.error({
      message: t("Erreur"),
      description: t(
        "Un service externe à SIGAF ne répond pas.Veuillez réessayer. Si le problème persiste, essayez plus tard."
      ),
      duration: 0,
    });
    throw error;
  }
};

export const getExtraitRegistreEntrepriseParId = async (survey, data, t) => {
  const value =
    survey.getValue("numero_matricule_fiscal_numero_IURNE") ||
    survey.getValue("establishment_data_tax_number") ||
    survey.getValue("numero_matricule_fiscal_IURNE");
  const etat_secondaire =
    survey.getValue("numero_etablissement_secondaire") ||
    survey.getValue("numero_etat_secondaire");
  const inverstor_type = data?.inverstor_type;
  const investor_cin = data?.investor_cin;
  try {
    const response = await axiosInstance.get(
      `${
        import.meta.env.VITE_APP_GEOPROD_API
      }/config/RNE_ExtraitRegistreEntrepriseParId?Identifiant_Unique=${value}`
    );
    survey.setValue("adresse_EPF", data?.establishment_data_address);
    if (response?.data?.message?.code === "404") {
      notification.error({
        message: t("Erreur"),
        description: t("Saisie invalide, veuillez vérifier vos données."),
        duration: 0,
      });
      survey.getQuestionByName("data_spinner").visible = false;
      return false;
    } else {
      const personnePhysique = response?.data?.message?.direction?.find(
        (p) => p?.cin === investor_cin
      );

      // if (inverstor_type === "شخص معنوي") {
      survey.setValue(
        "denomination_officielle_RNE_arabe",
        response?.data?.message?.nomAssociation
          ? response?.data?.message?.nomAssociation
          : response?.data?.message?.nomSociete
      );
      survey.setValue(
        "denomination_officielle_RNE_latin",
        response?.data?.message?.nomAssociationLatin
          ? response?.data?.message?.nomAssociationLatin
          : response?.data?.message?.nomSocieteLatin
      );

      // } else {
      //   survey.setValue(
      //     "denomination_officielle_RNE_arabe",
      //     personnePhysique?.nomPersonne
      //   );
      //   survey.setValue("denomination_officielle_RNE_latin", "");
      // }

      survey.setValue(
        "denomination_commerciale_RNE_arabe",
        response?.data?.message?.nomCommercial
      );
      survey.setValue(
        "denomination_commerciale_RNE_latin",
        response?.data?.message?.nomCommercialLatin
      );

      if (etat_secondaire === "000") {
        let commune = await getVillesById(
          response?.data?.message?.rueSociete
            ? response?.data?.message?.villeSociete
            : response?.data?.message?.villeSiege
        );
        let valueCommune = commune ? commune : "";
        survey.setValue(
          "adresse_epf_RNE",
          response?.data?.message?.rueSociete
            ? response?.data?.message?.rueSociete + " " + valueCommune
            : response?.data?.message?.rueSiege + " " + valueCommune
        );
      }
      return response?.data?.message;
    }

    // survey.getQuestionByName("data_spinner").visible = false;
  } catch (error) {
    survey.getQuestionByName("data_spinner").visible = false;
    notification.error({
      message: t("Erreur"),
      description: t(
        "Un service externe à SIGAF ne répond pas.Veuillez réessayer. Si le problème persiste, essayez plus tard."
      ),
      duration: 0,
    });
    throw error;
  }
};

export const getEntrepriseRegistrationId = async (survey, data, t) => {
  const value = survey.getValue("numero_matricule_fiscal_IURNE");
  const etat_secondaire = survey.getValue("numero_etat_secondaire");
  try {
    const response = await axiosInstance.get(
      `${
        import.meta.env.VITE_APP_GEOPROD_API
      }/config/RNE_ExtraitRegistreEntrepriseParId?Identifiant_Unique=${value}`
    );
    survey.setValue("adresse_EPF", data?.establishment_data_address);
    const userData = JSON.parse(sessionStorage.getItem("-x-token-user"));
    const personnePhysique = response?.data?.message?.direction?.find(
      (p) => p?.cin === userData?.cin
    );
    if (data?.inverstor_type == "Personne Morale") {
      survey.setValue(
        "denomination_officielle_RNE_arabe",
        response?.data?.message?.nomAssociation
          ? response?.data?.message?.nomAssociation
          : response?.data?.message?.nomSociete
      );
      survey.setValue(
        "denomination_officielle_RNE_latin",
        response?.data?.message?.nomAssociationLatin
          ? response?.data?.message?.nomAssociationLatin
          : response?.data?.message?.nomSocieteLatin
      );
    } else {
      survey.setValue(
        "denomination_officielle_RNE_arabe",
        personnePhysique?.nomPersonne
      );
      survey.setValue("denomination_officielle_RNE_latin", "");
    }

    survey.setValue(
      "denomination_commerciale_RNE_arabe",
      response?.data?.message?.nomCommercial
    );
    survey.setValue(
      "denomination_commerciale_RNE_latin",
      response?.data?.message?.nomCommercialLatin
    );

    if (etat_secondaire === "000") {
      let commune = await getVillesById(
        response?.data?.message?.rueSociete
          ? response?.data?.message?.villeSociete
          : response?.data?.message?.villeSiege
      );
      let valueCommune = commune ? commune : "";
      survey.setValue(
        "adresse_epf_RNE",
        response?.data?.message?.rueSociete
          ? response?.data?.message?.rueSociete + " " + valueCommune
          : response?.data?.message?.rueSiege + " " + valueCommune
      );
    }

    // survey.getQuestionByName("data_spinner").visible = false;
  } catch (error) {
    survey.getQuestionByName("data_spinner").visible = false;
    notification.error({
      message: t("Erreur"),
      description: t(
        "Un service externe à SIGAF ne répond pas.Veuillez réessayer. Si le problème persiste, essayez plus tard."
      ),
      duration: 0,
    });
    throw error;
  }
};
