import { useEffect } from "react";
import Loader from "../../../layout/Loader";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { useLoader } from "../../../context/LoaderContext";
const DatePikckerComponent = ({ question, survey }) => {
    const {showingPreview}=useLoader()
  
  useEffect(() => {
    question.showDatePicker = () => {
      const refreshBar = document?.querySelector(
        `div[data-name="${question?.name}"]`
      );
      if (refreshBar) {
        refreshBar.style.display = "block";
      }
    };
    return () => {
      delete question.showDatePicker;
    };
  }, [question]);

  const handleChange = (date) => {
    if (date) {
      question.value = dayjs(date).format("YYYY-MM-DD");
    } else {
      question.value = null;
    }
  };
  const getComparisonDate = () => {
    switch (question.name) {
      case "investor_date_delivrance":
        return "investor_birth_date";
      case "establishment_director_data_date_delivrance":
        return "establishment_director_data_birth_date";
      case "management_data_staff_date_delivrance":
        return "management_data_staff_birth_date";
      default:
        return null;
    }
  };
  const disabledDate = (current) => {
    const today = dayjs();

    if (question.name.includes("birth_date")) {
      const eighteenYearsAgo = dayjs().subtract(18, "years");

      return (
        current &&
        (current > today.endOf("day") || current.isAfter(eighteenYearsAgo))
      );
    } else if (question.name.includes("date_expiration_protection_civile")) {
      return current && current < dayjs().startOf("day");
    } else if (question.name.includes("date_expiration_passeport")) {
      const minDate = dayjs().add(3, "month").startOf("day");
      return current && current < minDate;
    } else if (question.name.includes("delivrance")) {
      const comparisonDate = getComparisonDate();
      if (comparisonDate === "management_data_staff_birth_date") {
        const management_data = survey.getQuestionByName("management_data");
        if (management_data) {
          for (let panel of management_data.panels) {
            if (
              survey.getQuestionByName("management_data").getItemIndex() - 1 ===
              management_data.panels.indexOf(panel)
            ) {
              const date = panel.getQuestionByName(comparisonDate)?.value;
              if (date) {
                return (
                  current &&
                  (current > today.endOf("day") ||
                    (date && current < dayjs(date)))
                );
              }
            }
          }
        }
      } else {
        const date = survey.getValue(comparisonDate);
        if (date) {
          return (
            current &&
            (current > today.endOf("day") || (date && current < dayjs(date)))
          );
        }
      }
    } else {
      return current && current > dayjs().endOf("day");
    }
  };

  return (
    <DatePicker
      style={{ width: "100%" }}
      value={question.value ? dayjs(question.value) : null}
      disabled={showingPreview || question.readOnly}
      placeholder="jj/mm/aaaa"
      format="DD/MM/YYYY"
      onChange={handleChange}
      disabledDate={disabledDate}
    />
  );
};
export default DatePikckerComponent;
