import React, { useState } from "react";
import { Button, Form, Input, Modal, notification, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { changeDirectorEmail } from "../../api/geoprod";
import axiosInstance from "../../utils/axios";
import { generatePassword } from "../../utils";
import { useLoader } from "../../context/LoaderContext";

// "establishment_director_data_cin_number": null,
//             "establishment_director_data_first_name": "\u0627\u0644\u0625\u0633\u0645",
//             "establishment_director_data_identity": "\u0645\u062f\u064a\u0631 \u063a\u064a\u0631 \u0627\u0644\u0645\u0633\u062a\u062b\u0645\u0631",
//             "establishment_director_data_last_name": "\u0627\u0644\u0644\u0642\u0628",
//             "establishment_director_data_nationality": "\u0623\u0641\u063a\u0627\u0646\u064a\u0629",
//             "establishment_director_data_passeport": "55512924",

const ChangeDirectorEmailModal = ({ record }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {setIsLoading}=useLoader()
  const { t } = useTranslation();
  const showModal = () => {
    if (record.establishment_director_data_identity === "المستثمر نفسه") {
      notification.error({
        message: t("Impossible de changer l'email"),
        description: t(
          "Vous ne pouvez pas changer l'email du directeur si le directeur est le même investisseur"
        ),
      });
      return;
    }
    setIsModalOpen(true);
  };
  const handleOk = async () => {
    const id =
      record.establishment_director_data_nationality !== "تونسية"
        ? record.establishment_director_data_passeport
        : record.establishment_director_data_cin_number;
    form.validateFields().then(async (values) => {
      setIsLoading(true)
    const pwd = generatePassword();
      axiosInstance
      .post(
       `${import.meta.env.VITE_APP_GEOPROD_API}/change_mail_directeur`,
       {
        id_directeur: id,
        mail: values.newEmail,
        id_affaire: record?.affaire_id,
        password: pwd,
      },
      {
        headers: {
          Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
            ?.Acces_Token,
        },
      }
      )
      .then(async (res) => {
        notification.success({
          message: t("Réussi"),
          description: t("L'email du directeur a été changé avec succès"),
        });
        setIsModalOpen(false);
        setIsLoading(false)
      })
      .catch((error) => {
        notification.error({
          message: t("Erreur"),
          description:
            error?.response?.data?.message ||
            "Une interruption est survenue lors de la récupération des données. Veuillez réessayer.",
          duration: 0,
        });
        setIsModalOpen(false);
        setIsLoading(false)
      })

    });
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [form] = Form.useForm();
  return (
    <>
      <Button size="small" block onClick={showModal}>
        {t("Changer l'email du directeur")}
      </Button>
      <Modal
        title={t("Changer l'email du directeur")}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={t("Confirmer")}
        cancelText={t("Annuler")}
      >
        <Typography.Paragraph>
          {t("Saisissez le nouvel e-mail de")}{" "}
          <span style={{ fontWeight: "bold" }}>
            `
            {record?.establishment_director_data_last_name
              ? record?.establishment_director_data_last_name +
                " " +
                record?.establishment_director_data_first_name
              : ""}
            `
          </span>{" "}
          {t("ci-dessous et confirmez le changement.")}
        </Typography.Paragraph>
        <Form form={form} layout="vertical" name="changeDirectorEmailForm">
          <Form.Item
            label={t("Nouvel email")}
            name="newEmail"
            rules={[
              { required: true, message: t("Veuillez entrer un email") },
              {
                type: "email",
                message: t("Veuillez entrer un email valide"),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default ChangeDirectorEmailModal;
