import React, { useContext, useState } from "react";
import {
  Button,
  Form,
  Input,
  DatePicker,
  Row,
  Col,
  Select,
  Card,
  notification,
  Spin,
  Space,
} from "antd";

import { useNavigate } from "react-router-dom";
import classes from "./SignUp.module.css";
import { useTranslation } from "react-i18next";
import GlobalContext from "../../context/GlobalContext";
import logo from "../../assets/LogoMinistère.svg";
import republique from "../../assets/tunisia.png";
import dayjs from "dayjs";
import { signUp } from "../../api/geoprod";
import { generatePassword } from "../../utils";
import { fetchCodePostalList, fetchDelegationList } from "../../context/FetchLists";

const { Option } = Select;

const SignUp = (props) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [loader, setLoader] = useState(false);
  const {
    form,
    globalData,
    setGlobalData,
    setIsRtl,
    nationalityOptions,
    gouvernoratOptions,
  } = useContext(GlobalContext);

  const handleGouvernoratChange = async (value) => {
    let selected_goverment = gouvernoratOptions.find(
      (e) => e.value === value
    ).id;
    let list_delegation = await fetchDelegationList(selected_goverment);
    form.setFieldsValue({
      ...form.getFieldsValue(),
      residenceDelegation: "",
      postal_code : "",
    });
    setDelegations(list_delegation);

  };
  const handleDelegationChange = async (value) => {
    let selected_delegation = delegations?.find(
      (e) => e?.value === value
    ).id;
    let list_code_postaux = await fetchCodePostalList(selected_delegation);
    form.setFieldsValue({
      ...form.getFieldsValue(),
      postal_code : "",
    });
    setPostalCodeList(list_code_postaux);
  };

  const role = new URLSearchParams(window.location.search).get("role");
  const espace = "Créer un nouveau compte " + role;
  const handleLanguageChange = (value) => {
    localStorage.setItem("language", value);
    setGlobalData({
      ...globalData,
      language: value,
    });
    i18n.changeLanguage(value);

    if (value === "ar") {
      setIsRtl(true);
    } else {
      setIsRtl(false);
    }
  };

  // const validator = (_, { valid }) => {
  //   if (valid()) return Promise.resolve();
  //   return Promise.reject(t("Veuillez valider le numéro de téléphone."));
  // };

  sessionStorage.setItem("role", role);

  const [nationalité, setNationalité] = useState("تونسية");
  const [delegations, setDelegations] = useState([]);
  const [postalCodeList, setPostalCodeList] = useState([]);
  const handleFinish = async (values) => {
    try {
      setLoader(true);

      let payload = {
        user: {
          id: "",
          civilite: "",
          telephone: "",
          tel_urgence: "",
          mobile: "",
          tel2: "",
          mail: "",
          nom: "",
          prenom: "",
          birthplace: "",
          nom_ar: "",
          prenom_ar: "",
          matricule_rh: "",
          identite_reelle: "",
          skype: "",
          password: "",
          old_password: "",
          pointage: false,
          super_admin: true,
          admin_restreint: false,
          api_key: "",
          entreprise_id:
            role === "investisseur"
              ? import.meta.env.VITE_APP_ID_Entreprise_INVESTISSEUR
              : import.meta.env.VITE_APP_ID_Entreprise_FORMATEUR,
          date_naiss_user: "",
          role_auto: [],
          active: "",
          server_in_mail_data: "",
          server_out_mail_data: "",
          port_in_mail_data: "",
          port_out_mail_data: "",
          ssl_mail_data: false,
          num_interne: "",
          photo: null,
          admin_tpv: 2,
          execution_tpv: 2,
          validate_paiement: 2,
          date_integration: null,
          heure_fin: "",
          heure_debut: "",
          proprietaire: true,
          adresse_auto: "",
          samedi_off: false,
          comparateur: "",
          tarificateur: 2,
          signature: "",
          groupe_commission: "",
          act_grp_pubpostage: 2,
          act_grp_negociateur: 2,
          act_grp_communication: 2,
          acces_maileva: 2,
          acces_fusion: 2,
          nom_producteur: "",
          prenom_producteur: "",
          qualite_producteur: "",
          orias_producteur: "",
          location: "",
          acces_ett: true,
          role:
            role === "investisseur"
              ? import.meta.env.VITE_APP_ID_ROLE_INVESTISSEUR
              : import.meta.env.VITE_APP_ID_ROLE_FORMATEUR,
          zone: "",
          code: "",
          act_grp_tags: 2,
          act_grp_statut: 2,
          hist: 2,
          hist_comm: 2,
          expiration_passeport: values?.passportExpiry
            ? dayjs(values?.passportExpiry)?.format("YYYY-MM-DD")
            : "",
          acces_communiques: 2,
          ecriture_transaction_financiere_client: 2,
          lecture_transaction_financiere_client: 2,
          lecture_transaction_financiere_distribution: 2,
          ecriture_transaction_financiere_distribution: 2,
          transaction_financiere: 2,
          user_mail_data: "",
          user_pwd_data: "",
          acces_reclamation_ticket: 2,
          acc_opportunite: [
            {
              id: 1,
              libele: "Prospection",
              cat: 1,
              type: "2",
            },
            {
              id: 5,
              libele: "Commercial",
              cat: 5,
              type: "2",
            },
            {
              id: 6,
              libele: "Classification des diplômes",
              cat: 6,
              type: "2",
            },
          ],
          acc_affaire: [
            {
              icon: "fa-solid fa-archive",
              id: 2,
              libele: "Enregistrement",
              cat: 2,
              type: "2",
            },
            {
              icon: "fa-solid fa-archive",
              id: 3,
              libele: "Emission",
              cat: 3,
              type: "2",
            },
            {
              icon: "fa-solid fa-archive",
              id: 10,
              libele: "Gestion ",
              cat: 10,
              type: "2",
            },
            {
              icon: "fa-solid fa-check",
              id: 12,
              libele: "Qualité et Validation",
              cat: 12,
              type: "2",
            },
            {
              icon: "fa-solid fa-graduation-cap",
              id: 13,
              libele: "Expertise & pré-enregistrement",
              cat: 13,
              type: "2",
            },
            {
              icon: "fa-solid fa-ad",
              id: 14,
              libele: "Pré-Enregistrement ATRIAS",
              cat: 14,
              type: "2",
            },
            {
              icon: "fa-solid fa-envelope-square",
              id: 15,
              libele: "Enregistrement ATRIAS",
              cat: 15,
              type: "2",
            },
            {
              icon: "fa-solid fa-birthday-cake",
              id: 16,
              libele: "Gestion ATRIAS",
              cat: 16,
              type: "2",
            },
            {
              icon: "fa-solid fa-allergies",
              id: 822,
              libele: "Conformité et Qualité",
              cat: 822,
              type: "2",
            },
            {
              icon: "",
              id: 825,
              libele: "Commercial",
              cat: 825,
              type: "2",
            },
            {
              icon: "fa-solid fa-ad",
              id: 826,
              libele: "Expertise",
              cat: 826,
              type: "2",
            },
            {
              icon: "fa-solid fa-graduation-cap",
              id: 827,
              libele: "Direction Regionale",
              cat: 827,
              type: "2",
            },
            {
              icon: "fa-solid fa-address-book",
              id: 828,
              libele: "Sous Direction Regionale",
              cat: 828,
              type: "2",
            },
          ],
          manager_list: [],
          manager_de: [],
          groupe_user: [],
          tab_code: [],
          groupe_user_alert: [],
          nom_organisme: "",
          type_user: "c81e728d9d4c2f636f067f89cc14862c",
          information_bancaire: {
            id: "",
            titulaire_compte: "",
            iban: "",
            bic_swift: "",
            type_iban: "prestation",
            nom_banque: "",
          },
          espace_distrib_access: {
            changement_statut: 2,
            acces_info_spec: 2,
          },
          acces_qualite: 2,
          nom_role: "",
        },
        adresse: {
          CP: "",
          streetNumber: "",
          streetName: "",
          longitude: 0,
          latitude: 0,
          geom: null,
        },
        ville: {
          id_pays: "",
          nom_comm: "",
        },
      };

      payload.user.nationality = values?.nationality;
      payload.user.cin = values?.cin;
      payload.user.passport = values?.passportNumber;
      payload.user.nom = values?.nomFr;
      payload.user.prenom = values?.prenomFr;
      payload.user.nom_ar = values?.nomAr;
      payload.user.prenom_ar = values?.prenomAr;
      (payload.user.code_postal = values?.postal_code),
        (payload.user.mail = values?.user?.mail);
      payload.user.password = values?.user?.password || generatePassword();
      payload.user.mobile = values?.phone;
      // payload.user.mobile = `+${values.phone.countryCode}${values.phone.areaCode}${values.phone.phoneNumber}`;
      payload.user.telephone = values?.phone;
      payload.user.date_naiss_user = dayjs(values.birthDate).format(
        "YYYY-MM-DD"
      );
      payload.ville.nom_comm = values?.ville?.nom_comm;
      payload.user.adresse = values?.ville?.nom_comm;
      payload.user.delegation = values?.residenceDelegation || "";
      payload.user.goverment = values?.residenceGovernorate || "";
      payload.user.first_login = 1;
      payload.adresse.CP = values?.adresse?.CP;
      payload.user.nom = values?.nomFr;

      const data = await signUp(payload);
      if (data) {
        setLoader(false);
        notification.success({
          message: t("Votre inscription est enregistrée"),
          description: t(
            "Veuillez vérifier votre email et cliquer sur le lien de confirmation pour valider votre compte.\nPensez à vérifier vos spams si nécessaire."
          ),
          duration: 0,
        });
        navigate(`/signin?role=${role}`);
        form.resetFields();
      }
    } catch (error) {
      setLoader(false);
      if (error?.response?.data?.codeR === "0") {
        notification.error({
          message: i18n.t("Erreur"),
          description: i18n.t(`${error?.response?.data?.message}`),
          duration: 0,
        });
      } else {
        notification.error({
          message: i18n.t("Erreur"),
          description:
            i18n.t(`${error?.response?.data?.message}`) ||
            i18n.t("Erreur lors de la création de votre compte."),
          duration: 0,
        });
      }
    }
  };

  return (
    <Row gutter={24} style={{ display: "flex", justifyContent: "center" }}>
      <Col
        lg={24}
        style={{
          marginBottom: "4rem",
          display: "flex",
          justifyContent: "space-around",
          backgroundColor: "white",
          width: "100%",
          padding: "1rem",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
         <Row
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "90%",
            alignItems: "center",
          }}
        >
          <Col>
            <img
              src={import.meta.env.VITE_APP_LOGO}
              style={{ width: "8rem" }}
            />
          </Col>
          <Col
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem", // Adds spacing between elements
            }}
          >
             <Select
              style={{ width: "8rem" }}
              onChange={handleLanguageChange}
              value={localStorage.getItem("language") || "fr"}
            >
              <Option value="fr">Français</Option>
              <Option value="ar">العربية</Option>
            </Select>
            <img src={logo} style={{ height: "90px" }} />
            <img src={republique} style={{ height: "86px" }} />
           
          </Col>
        </Row>
      </Col>
      <Col lg={14}>
        <Card
        >
          <div className="p-4 p-sm-6">
            <h3 style={{ marginBottom: "2rem" }}>
            {t(espace)}
            </h3>

            <Form
              disabled={loader}
              form={form}
              layout="vertical"
              onFinish={handleFinish}
              onKeyDown={(e) => (e.keyCode == 13 ? e.preventDefault() : "")}
              initialValues={{ nationality: "تونسية" }}
            >
              {role === "investisseur" ? (
                <Row gutter={16}>
                  <Col lg={12} md={24}>
                    <Form.Item
                      label="Adresse e-mail - البريد الإلكتروني"
                      name={["user", "mail"]}
                      rules={[
                        {
                          required: true,
                          message: t("Veuillez remplir ce champ."),
                        },
                        {
                          pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                          message: t("Veuillez vérifier ce champ."),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col lg={12} md={24}>
                    <Form.Item
                      label="Nationalité - الجنسية"
                      name={"nationality"}
                      rules={[
                        {
                          required: true,
                          message: t("Veuillez remplir ce champ."),
                        },
                      ]}
                    >
                      <Select
                        optionFilterProp="label"
                        showSearch
                        onChange={(e) => {
                          setNationalité(e);
                        }}
                        options={nationalityOptions}
                      />
                    </Form.Item>
                  </Col>
                  {nationalité === "تونسية" && (
                    <Col lg={12} md={24}>
                      <Form.Item
                        label="Numéro CIN - رقم بطاقة التعريف الوطنية"
                        name="cin"
                        rules={[
                          {
                            required: true,
                            message: t("Veuillez remplir ce champ."),
                          },
                          {
                            validator: (_, value) => {
                              if (value && !/^\d{8}$/.test(value)) {
                                return Promise.reject(
                                  new Error(t("Veuillez vérifier ce champ."))
                                );
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <Input
                          maxLength={8}
                          onChange={(e) =>
                            form.setFieldValue(
                              "cin",
                              e.target.value.replace(/[^0-9]/g, "")
                            )
                          }
                        />
                      </Form.Item>
                    </Col>
                  )}
                  {nationalité !== "تونسية" && (
                    <Col lg={12} md={24}>
                      <Form.Item
                        label="Numéro du passeport - رقم جواز السفر"
                        name="passportNumber"
                        rules={[
                          {
                            required: true,
                            message: t("Veuillez remplir ce champ."),
                          },
                          {
                            validator: (_, value) => {
                              if (value && value.trim() === "") {
                                return Promise.reject(
                                  t("Veuillez remplir ce champ.")
                                );
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <Input
                        // onChange={(e) =>
                        //   form.setFieldValue(
                        //     "passportNumber",
                        //     e.target.value.replace(/[^+0-9]/g, "")
                        //   )
                        // }
                        />
                      </Form.Item>
                    </Col>
                  )}
                  {nationalité !== "تونسية" && (
                    <Col lg={12} md={24}>
                      <Form.Item
                        label="Date d’expiration du passeport - تاريخ انتهاء جواز السفر"
                        name="passportExpiry"
                        rules={[
                          {
                            required: true,
                            message: t("Veuillez remplir ce champ."),
                          },
                        ]}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          placeholder=""
                          format="DD/MM/YYYY"
                          disabledDate={(current) => {
                            const minDate = dayjs()
                              .add(3, "month")
                              .startOf("day");
                            return current && current < minDate;
                          }}
                        />
                      </Form.Item>
                    </Col>
                  )}
                  {nationalité !== "تونسية" && (
                    <Col lg={12} md={24}>
                      <Form.Item
                        label="Nom (En français)  - اللقب (بالفرنسية)"
                        name="nomFr"
                        rules={[
                          {
                            required: true,
                            message: t("Veuillez remplir ce champ."),
                          },
                          {
                            pattern: /^[A-Za-zÀ-ÿ\s-]+$/, // Allows French alphabet (including accented characters), spaces, and hyphens
                            message: t(
                              "Veuillez entrer uniquement des caractères français."
                            ),
                          },
                          {
                            validator: (_, value) => {
                              if (value && value.trim() === "") {
                                return Promise.reject(
                                  t("Veuillez remplir ce champ.")
                                );
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  )}

                  {nationalité !== "تونسية" && (
                    <Col lg={12} md={24}>
                      <Form.Item
                        label="Prénom (En français) - الإسم (بالفرنسية)"
                        name="prenomFr"
                        rules={[
                          {
                            required: true,
                            message: t("Veuillez remplir ce champ."),
                          },
                          {
                            pattern: /^[A-Za-zÀ-ÿ\s-]+$/, // Same validation for French alphabet
                            message: t(
                              "Veuillez entrer uniquement des caractères français."
                            ),
                          },
                          {
                            validator: (_, value) => {
                              if (value && value.trim() === "") {
                                return Promise.reject(
                                  t("Veuillez remplir ce champ.")
                                );
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  )}

                  {nationalité !== "تونسية" && (
                    <Col lg={12} md={24}>
                      <Form.Item
                        label="Nom (En arabe)  - اللقب (بالعربية)"
                        name="nomAr"
                        rules={[
                          {
                            required: true,
                            message: t("Veuillez remplir ce champ."),
                          },
                          {
                            pattern: /^[\u0621-\u064A\s]+$/, // Allows Arabic characters and spaces
                            message: t(
                              "Veuillez entrer uniquement des caractères arabes."
                            ),
                          },
                          {
                            validator: (_, value) => {
                              if (value && value.trim() === "") {
                                return Promise.reject(
                                  t("Veuillez remplir ce champ.")
                                );
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  )}

                  {nationalité !== "تونسية" && (
                    <Col lg={12} md={24}>
                      <Form.Item
                        label="Prénom (En arabe) - الإسم (بالعربية)"
                        name="prenomAr"
                        rules={[
                          {
                            required: true,
                            message: t("Veuillez remplir ce champ."),
                          },
                          {
                            pattern: /^[\u0621-\u064A\s]+$/, // Same validation for Arabic alphabet
                            message: t(
                              "Veuillez entrer uniquement des caractères arabes."
                            ),
                          },
                          {
                            validator: (_, value) => {
                              if (value && value.trim() === "") {
                                return Promise.reject(
                                  t("Veuillez remplir ce champ.")
                                );
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  )}

                  <Col lg={12} md={24}>
                    <Form.Item
                      label="Date de naissance - تاريخ الولادة"
                      name="birthDate"
                      rules={[
                        {
                          required: true,
                          message: t("Veuillez remplir ce champ."),
                        },
                      ]}
                    >
                      <DatePicker
                        format={"DD/MM/YYYY"}
                        style={{ width: "100%" }}
                        placeholder=""
                        disabledDate={(current) => {
                          const eighteenYearsAgo = dayjs().subtract(
                            18,
                            "years"
                          );
                          const today = dayjs();

                          return (
                            current &&
                            (current > today.endOf("day") ||
                              current.isAfter(eighteenYearsAgo))
                          );
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={12} md={24}>
                    {/* <Form.Item
                      label="Numéro de téléphone - رقم الهاتف"
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: t("Veuillez remplir ce champ."),
                        },
                        {
                          validator: (_, value) => {
                            if (!value || !value.countryCode || !value.areaCode || !value.phoneNumber) {
                              return Promise.reject(new Error(t("Veuillez remplir ce champ.")));
                            }
                            if(value && value.isoCode==='tn' && value.phoneNumber.length<6 ){
                              return Promise.reject(new Error(t("Veuillez vérifier ce champ.")));

                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <PhoneInput
                        country="tn"
                        style={{
                          width: "100%",
                          direction: "ltr",
                        }}
                      />
                    </Form.Item> */}
                    <Form.Item
                      label="Numéro de téléphone - رقم الهاتف"
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: t("Veuillez remplir ce champ."),
                        },
                        {
                          pattern: new RegExp(/^\d{8}$/),
                          message: t("Veuillez vérifier ce champ."),
                        },
                      ]}
                    >
                      <Input
                        maxLength={8}
                        onChange={(e) =>
                          form.setFieldValue(
                            "phone",
                            e.target.value.replace(/[^0-9]/g, "")
                          )
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              ) : (
                <Row gutter={16}>
                  <Col lg={nationalité === "تونسية" ? 8 : 12} md={24}>
                    <Form.Item
                      label="Nationalité - الجنسية"
                      name="nationality"
                      rules={[
                        {
                          required: true,
                          message: t("Veuillez sélectionner une nationalité."),
                        },
                      ]}
                    >
                      <Select
                        optionFilterProp="label"
                        showSearch
                        onChange={(e) => setNationalité(e)}
                        options={nationalityOptions}
                      />
                    </Form.Item>
                  </Col>

                  {nationalité === "تونسية" && (
                    <Col lg={8} md={24}>
                      <Form.Item
                        label="Numéro CIN - رقم بطاقة التعريف الوطنية"
                        name="cin"
                        rules={[
                          {
                            required: true,
                            message: t("Veuillez remplir ce champ."),
                          },
                          {
                            validator: (_, value) => {
                              if (value && !/^\d{8}$/.test(value)) {
                                return Promise.reject(
                                  new Error(t("Veuillez vérifier ce champ."))
                                );
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <Input
                          maxLength={8}
                          onChange={async (e) => {
                            form.setFieldValue(
                              "cin",
                              e.target.value.replace(/[^0-9]/g, "")
                            );
                          }}
                        />
                      </Form.Item>
                    </Col>
                  )}
                  <Col lg={nationalité === "تونسية" ? 8 : 12} md={24}>
                    <Form.Item
                      label="Date de naissance - تاريخ الولادة"
                      name="birthDate"
                      rules={[
                        {
                          required: true,
                          message: t("Veuillez remplir ce champ."),
                        },
                      ]}
                    >
                      <DatePicker
                        format="DD/MM/YYYY"
                        style={{ width: "100%" }}
                        placeholder=""
                        disabledDate={(current) => {
                          const eighteenYearsAgo = dayjs().subtract(
                            18,
                            "years"
                          );
                          const today = dayjs();

                          return (
                            current &&
                            (current > today.endOf("day") ||
                              current.isAfter(eighteenYearsAgo))
                          );
                        }}
                      />
                    </Form.Item>
                  </Col>
                  {nationalité !== "تونسية" && (
                    <Col lg={12} md={24}>
                      <Form.Item
                        label={t("N° passeport")}
                        name="passportNumber"
                        rules={[
                          {
                            required: true,
                            message: t("Veuillez remplir ce champ."),
                          },
                          {
                            validator: (_, value) => {
                              if (value && value.trim() === "") {
                                return Promise.reject(
                                  t("Veuillez remplir ce champ.")
                                );
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <Input
                        // onChange={(e) =>
                        //   form.setFieldValue(
                        //     "passportNumber",
                        //     e.target.value.replace(/[^+0-9]/g, "")
                        //   )
                        // }
                        />
                      </Form.Item>
                    </Col>
                  )}
                  {nationalité !== "تونسية" && (
                    <Col lg={12} md={24}>
                      <Form.Item
                        label={t("Date d’expiration du passeport")}
                        name="passportExpiry"
                        rules={[
                          {
                            required: true,
                            message: t("Veuillez remplir ce champ."),
                          },
                        ]}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          format="DD/MM/YYYY"
                          placeholder=""
                          disabledDate={(current) => {
                            const minDate = dayjs()
                              .add(3, "month")
                              .startOf("day");
                            return current && current < minDate;
                          }}
                        />
                      </Form.Item>
                    </Col>
                  )}
                  {/* <Col lg={8} md={24}>
                    <Form.Item
                      label={t("Genre")}
                      name={["user", "civilite"]}
                      rules={[
                        {
                          required: true,
                          message: t("Veuillez sélectionner un genre."),
                        },
                      ]}
                    >
                      <Select
                        options={[
                          {
                            value: "ذكر",
                            label: "Masculin - ذكر",
                          },
                          {
                            value: "أنثى",
                            label: "Féminin - أنثى",
                          },
                        ]}
                      />
                    </Form.Item>
                  </Col> */}
                  <Col lg={12} md={24}>
                    <Form.Item
                      label="Adresse e-mail - البريد الإلكتروني"
                      name={["user", "mail"]}
                      rules={[
                        {
                          required: true,
                          message: t("Veuillez remplir ce champ."),
                        },
                        {
                          type: "email",
                          message: t("Veuillez vérifier ce champ."),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  {/* <Col lg={8} md={24}>
                    <Form.Item
                      label={t("Nom")}
                      name={["user", "nom"]}
                      rules={[
                        {
                          required: true,
                          message: t("Veuillez remplir ce champ."),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col> */}
                  {/* <Col lg={8} md={24}>
                    <Form.Item
                      label={t("Prénom")}
                      name={["user", "prenom"]}
                      rules={[
                        {
                          required: true,
                          message: t("Veuillez remplir ce champ."),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col> */}

                  {/* <Col lg={8} md={24}>
                    <Form.Item
                      label={t("Lieu de naissance")}
                      name="birthPlace"
                      rules={[
                        {
                          required: true,
                          message: t("Veuillez remplir ce champ."),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col> */}

                  <Col lg={12} md={24}>
                    <Form.Item
                      label="Adresse de résidence (En arabe) - عنوان الإقامة (بالعربية)"
                      name={["ville", "nom_comm"]}
                      rules={[
                        {
                          required: true,
                          message: t("Veuillez remplir ce champ."),
                        },
                        {
                          pattern: /^[\u0600-\u06FF0-9\s]+$/,
                          message: t(
                            "Veuillez entrer uniquement des caractères arabes ou des chiffres."
                          ),
                        },
                        {
                          validator: (_, value) => {
                            if (value && value.trim() === "") {
                              return Promise.reject(
                                t("Veuillez remplir ce champ.")
                              );
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col lg={12} md={24}>
                    <Form.Item
                      label="Gouvernorat de résidence - ولاية الإقامة"
                      name="residenceGovernorate"
                      rules={[
                        {
                          required: true,
                          message: t("Veuillez remplir ce champ."),
                        },
                      ]}
                    >
                      <Select
                       optionFilterProp="label"
                       showSearch
                        onChange={handleGouvernoratChange}
                        options={gouvernoratOptions}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={12} md={24}>
                    <Form.Item
                      label="Délégation de résidence - معتمدية الإقامة"
                      name="residenceDelegation"
                      rules={[
                        {
                          required: true,
                          message: t("Veuillez remplir ce champ."),
                        },
                      ]}             
                    >
                      <Select  onChange={handleDelegationChange}>
                        {delegations.map((delegation) => (
                          <Option
                            key={delegation.text}
                            value={delegation.value}
                          >
                            {delegation.text}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col lg={12} md={24}>
                  <Form.Item
                      label="Code postal - الترقيم البريدي"
                      name="postal_code"
                      rules={[
                        {
                          required: true,
                          message: t("Veuillez remplir ce champ."),
                        },
                      ]}
                      
                    >
                      <Select>
                        {postalCodeList?.map((postalCode) => (
                          <Option
                            key={postalCode?.text}
                            value={postalCode?.value}
                          >
                            {postalCode?.text}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  
                  </Col>

                  <Col lg={12} md={24}>
                    <Form.Item
                      label="Numéro de téléphone - رقم الهاتف"
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: t("Veuillez remplir ce champ."),
                        },
                        {
                          pattern: new RegExp(/^\d{8}$/),
                          message: t("Veuillez vérifier ce champ."),
                        },
                      ]}
                    >
                      <Input
                        maxLength={8}
                        onChange={(e) =>
                          form.setFieldValue(
                            "phone",
                            e.target.value.replace(/[^0-9]/g, "")
                          )
                        }
                      />
                    </Form.Item>
                    {/* <Form.Item
                      label="Numéro de téléphone - رقم الهاتف"
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: t("Veuillez remplir ce champ."),
                        },
                        {
                          validator: (_, value) => {
                            if (!value || !value.countryCode || !value.areaCode || !value.phoneNumber) {
                              return Promise.reject(new Error(t("Veuillez remplir ce champ.")));
                            }
                            if(value && value.isoCode==='tn' && value.phoneNumber.length<6 ){
                              return Promise.reject(new Error(t("Veuillez vérifier ce champ.")));

                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <PhoneInput
                        country="tn"
                        style={{
                          width: "100%",
                          direction: "ltr",
                         
                        }}
                      />
                    </Form.Item> */}
                  </Col>
                </Row>
              )}
              <Row gutter={16} style={{ marginTop: "1rem" }}>
                <Col>
                  <Space>
                    {loader && <Spin />}
                    <Button
                      type="primary"
                      style={{
                        marginInline: "0.5rem",
                        width: "7rem",
                      }}
                      htmlType="submit"
                    >
                      {t("Valider")}
                    </Button>
                  </Space>
                </Col>
                {/* <Col>
                  <Button
                    disabled={loader}
                    style={{
                      marginInline: "0.5rem",
                      width: "7rem",
                    }}
                    htmlType="button"
                    onClick={() => {
                      // handle cancel
                    }}
                  >
                    {t("Annuler")}
                  </Button>
                </Col> */}
              </Row>
            </Form>
            {/* Form END */}
          </div>
          <p>
            {t("Vous-avez déjà un compte ?")}
            <span
              className={classes.seConnecter}
              onClick={() => navigate(`/signin?role=${role}`)}
            >
              {t("Se connecter")}
            </span>
          </p>
        </Card>
      </Col>
    </Row>
  );
};
export default SignUp;
