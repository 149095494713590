import { Menu, Row, Badge } from "antd";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  LogoutOutlined,
  TeamOutlined,
  UserOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import GlobalContext from "../context/GlobalContext";
import { useContext } from "react";
import { saveAffaire } from "../api/geoprod";
import { useLoader } from "../context/LoaderContext";
import axiosInstance from "../utils/axios";
import {
  Building2,
  Files,
  FileUser,
  MessagesSquare,
  NotepadTextDashed,
  ScreenShareOff,
  Split,
  UserRoundPen,
  UsersRound,
  ClipboardListIcon,
  LayoutList,
  BookOpen,
  HistoryIcon,
  Loader,
  BookUser,
} from "lucide-react";
import i18n from "../i18n";

function Sidenav(props) {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");
  const { color } = props;
  const { t } = useTranslation();
  const role = sessionStorage.getItem("role");
  const navigate = useNavigate();
  const { form, listTasks, setIsRtl, brouillon } = useContext(GlobalContext);
  const { setIsLoading, setSurveyJson, setShowingPreview } = useLoader();
  const user = JSON.parse(sessionStorage.getItem("-x-token-user"));

  const redirectRole =
    role === "investisseur-directeur" ? "investisseur" : role;

  const tables = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M9 2C8.44772 2 8 2.44772 8 3C8 3.55228 8.44772 4 9 4H11C11.5523 4 12 3.55228 12 3C12 2.44772 11.5523 2 11 2H9Z"
        fill={color}
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 5C4 3.89543 4.89543 3 6 3C6 4.65685 7.34315 6 9 6H11C12.6569 6 14 4.65685 14 3C15.1046 3 16 3.89543 16 5V16C16 17.1046 15.1046 18 14 18H6C4.89543 18 4 17.1046 4 16V5ZM7 9C6.44772 9 6 9.44772 6 10C6 10.5523 6.44772 11 7 11H7.01C7.56228 11 8.01 10.5523 8.01 10C8.01 9.44772 7.56228 9 7.01 9H7ZM10 9C9.44772 9 9 9.44772 9 10C9 10.5523 9.44772 11 10 11H13C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9H10ZM7 13C6.44772 13 6 13.4477 6 14C6 14.5523 6.44772 15 7 15H7.01C7.56228 15 8.01 14.5523 8.01 14C8.01 13.4477 7.56228 13 7.01 13H7ZM10 13C9.44772 13 9 13.4477 9 14C9 14.5523 9.44772 15 10 15H13C13.5523 15 14 14.5523 14 14C14 13.4477 13.5523 13 13 13H10Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const profile = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <circle cx="10" cy="6" r="3" fill="#808080"></circle>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 16C4 13 7 11 10 11C13 11 16 13 16 16H4Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const billing = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M4 4C2.89543 4 2 4.89543 2 6V7H18V6C18 4.89543 17.1046 4 16 4H4Z"
        fill={color}
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 9H2V14C2 15.1046 2.89543 16 4 16H16C17.1046 16 18 15.1046 18 14V9ZM4 13C4 12.4477 4.44772 12 5 12H6C6.55228 12 7 12.4477 7 13C7 13.5523 6.55228 14 6 14H5C4.44772 14 4 13.5523 4 13ZM9 12C8.44772 12 8 12.4477 8 13C8 13.5523 8.44772 14 9 14H10C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12H9Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const handleClickOutside = async (event) => {
    setShowingPreview(false);
    if (
      page === "etablissements/save" ||
      page === "etablissements/edit" ||
      page === "investisseur/changer" ||
      page === "etablissements/existante/save" ||
      page === "annexes/save"
    ) {
      let payload = JSON.parse(sessionStorage.getItem("payload"));
      const type =
        page === "etablissements/existante/save"
          ? "epfExistante"
          : page === "etablissements/edit"
          ? "edit"
          : page === "investisseur/changer"
          ? "changementInvestisseur"
          : page === "annexes/save"
          ? "annexe"
          : null;

      await saveAffaire(payload, "Brouillon", setIsLoading, type);
    }
    sessionStorage.removeItem("current_step");
  };

  const handleMenuClick = () => {
    setIsLoading(true);
    const ligne_produit = import.meta.env
      .VITE_APP_LIGNE_PRODUIT_MODIFIACTION_SANS_VALIDATION_SANS_BO;
    axiosInstance
      .get(
        `${
          import.meta.env.VITE_APP_GEOPROD_API
        }/admin-bpm/contrat/${ligne_produit}`,
        {
          headers: {
            Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
              ?.Acces_Token,
          },
        }
      )
      .then((res) => {
        let form = res?.data?.contrat?.form;
        const role = sessionStorage.getItem("role");

        if (role === "directeur") {
          form.pages = form?.pages.filter(
            (page) => page.name === "Données de Directeur de l’Établissement"
          );
        } else {
          form.pages = form?.pages.filter((page) => page.name === "page1");
        }
        setSurveyJson(form);
        // axiosInstance
        //   .get(
        //     `${import.meta.env.VITE_APP_GEOPROD_API}/get_data_form_by_user`,
        //     {
        //       headers: {
        //         Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
        //           .Acces_Token,
        //       },
        //     }
        //   )
        //   .then(async (res) => {
        //     await setSurveyData(res?.data?.info.data_form_prod);
        //     sessionStorage.setItem("id_affaire_profile", res?.data?.info.id);
        // sessionStorage.setItem("selectedOption", "sans_bo_sans_validation");

        navigate("/userProfil");
        setIsLoading(false);
        // })
        // .catch(() => {
        //   setIsLoading(false);
        // });
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        paddingTop: "1rem",
      }}
    >
      {/* <img src={sigaf} style={{ width: "8rem" }} />
       */}
      <img
        src={import.meta.env.VITE_APP_LOGO}
        style={{ width: "8rem" }}
        alt="logo"
      />

      <div
        className="brand"
        style={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          flexDirection: "column",
        }}
      >
        <h3>
          {role === "formateur"
            ? t("Espace formateur")
            : role === "directeur"
            ? t("Espace directeur")
            : t("Espace investisseur")}
        </h3>
        {user?.nom && user?.prenom ? (
          <Row style={{ justifyContent: "center" }}>
            <UserOutlined style={{ marginRight: "5px" }} />
            <h4>
              {localStorage.getItem("language") === "ar" && user?.nom_ar
                ? user?.nom_ar + " " + user?.prenom_ar
                : user?.nom + " " + user?.prenom}
            </h4>
          </Row>
        ) : (
          user?.nom_ar &&
          user?.prenom_ar && (
            <Row>
              <UserOutlined style={{ marginRight: "5px" }} />
              <h4>{user?.nom_ar + " " + user?.prenom_ar}</h4>
            </Row>
          )
        )}
      </div>
      {role !== "formateur" ? (
        <Menu
          theme="light"
          mode="inline"
          defaultOpenKeys={["sub2", "20"]}
          onClick={() => {
            sessionStorage.getItem("consulter") === "true" ||
            sessionStorage.getItem("demande") === "true"
              ? sessionStorage.removeItem("surveyData")
              : handleClickOutside();
          }}
        >
          <Menu.SubMenu
            key="sub1"
            title={<span className="label">{t("Profil")}</span>}
            icon={<UserRoundPen strokeWidth={1.25} />}
          >
            <Menu.Item key="1" onClick={handleMenuClick}>
              <NavLink to="/userProfil" onClick={(e) => e.preventDefault()}>
                <span className="icon">
                  <FileUser strokeWidth={1.25} />
                </span>
                <span className="label">{t("Mes Informations")}</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="5">
              <NavLink to="/reclamation">
                <span
                  className="icon"
                  style={{
                    background: page === "rtl" ? color : "",
                  }}
                >
                  <MessagesSquare strokeWidth={1.25} />
                </span>
                <span className="label">{t("Réclamation")}</span>
              </NavLink>
            </Menu.Item>
          </Menu.SubMenu>

          <Menu.Item key="2">
            <NavLink to={"/etablissements"}>
              <span
                className="icon"
                style={{
                  background: page === "tables" ? color : "",
                }}
              >
                <Building2 strokeWidth={1.25} />
              </span>
              <span className="label">
                {role === "directeur"
                  ? t("Mon établissement")
                  : t("Mes établissements")}
              </span>
            </NavLink>
          </Menu.Item>

          <Menu.Item key="10">
            <NavLink to={"/taches"}>
              <span
                className="icon"
                style={{
                  background: page === "tables" ? color : "",
                }}
              >
                <LayoutList strokeWidth={1.25} />
              </span>
              <span className="label">
                {t("Mes tâches")}{" "}
                <Badge
                  count={listTasks}
                  overflowCount={99}
                  style={{ backgroundColor: "#f5222d" }}
                />
              </span>
            </NavLink>
          </Menu.Item>

          <Menu.Item key="22">
            <NavLink to={"/annexes"}>
              <span
                className="icon"
                style={{
                  background: page === "tables" ? color : "",
                }}
              >
                <Split strokeWidth={1.25} />
              </span>
              <span className="label">
                {role !== "directeur" ? t("Mes annexes") : t("Mon annexe")}
              </span>
            </NavLink>
          </Menu.Item>

          <Menu.SubMenu
            key="sub2"
            title={<span className="label">{t("Mes demandes")}</span>}
            icon={<Files strokeWidth={1.25} />}
          >
            <Menu.Item key="20">
              <NavLink to="/demandes">
                <span className="icon">
                  <Loader strokeWidth={1.25} />
                </span>
                <span className="label">{t("En cours")}</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="21">
              <NavLink to="/historique">
                <span
                  className="icon"
                  style={{
                    background: page === "rtl" ? color : "",
                  }}
                >
                  <HistoryIcon strokeWidth={1.25} />
                </span>
                <span className="label">{t("Historique")}</span>
              </NavLink>
            </Menu.Item>
          </Menu.SubMenu>

          {/* <Menu.Item key="3">
            <NavLink to="/demandes">
              <span
                className="icon"
                style={{
                  background: page === "billing" ? color : "",
                }}
              >
                <Files strokeWidth={1.25} />
              </span>
              <span className="label">{t("Mes demandes")}</span>
            </NavLink>
          </Menu.Item> */}
          <Menu.Item key="4">
            <NavLink to="/brouillon">
              <span
                className="icon"
                style={{
                  background: page === "billing" ? color : "",
                }}
              >
                <NotepadTextDashed strokeWidth={1.25} />
              </span>
              <span className="label">
                {t("Brouillon")}{" "}
                <Badge
                  count={brouillon?.length}
                  overflowCount={99}
                  style={{ backgroundColor: "#f5222d" }}
                />
              </span>
            </NavLink>
          </Menu.Item>

          {role !== "directeur" && (
            <Menu.Item key="22">
              <NavLink to="/directeurs">
                <span
                  className="icon"
                  style={{
                    background: page === "rtl" ? color : "",
                  }}
                >
                  <BookUser strokeWidth={1.25} />
                </span>
                <span className="label">{t("Liste des directeurs")}</span>
              </NavLink>
            </Menu.Item>
          )}

          <Menu.Item key="11">
            <NavLink to="/formateurs">
              <span
                className="icon"
                style={{
                  background: page === "rtl" ? color : "",
                }}
              >
                <UsersRound strokeWidth={1.25} />
              </span>
              <span className="label">{t("Liste des formateurs")}</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="12">
            <NavLink to="/formations">
              <span
                className="icon"
                style={{
                  background: page === "rtl" ? color : "",
                }}
              >
                <BookOpen strokeWidth={1.25} />
              </span>
              <span className="label">{t("Liste des formations")}</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item
            key="6"
            onClick={() => {
              handleClickOutside().then(() => {
                sessionStorage.clear();
                form.resetFields();
                navigate(`/signin?role=${redirectRole}`);
              });
            }}
          >
            <NavLink to={`/signin?role=${redirectRole}`}>
              <span
                className="icon"
                style={{
                  background: page === "billing" ? color : "",
                }}
              >
                <ScreenShareOff strokeWidth={1.25} />
              </span>
              <span className="label">{t("Se déconnecter")}</span>
            </NavLink>
          </Menu.Item>
        </Menu>
      ) : (
        <div
          className="sider-menu-container"
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <Menu style={{ flex: 1 }}>
            {/* <Menu.Item key="1">
              <NavLink to="/acceuil">
                <span
                  className="icon"
                  style={{
                    background: page === "billing" ? color : "",
                  }}
                >
                  <HomeOutlined />
                </span>
                <span className="label">{t("Acceuil")}</span>
              </NavLink>
            </Menu.Item> */}
            <Menu.Item key="2">
              <NavLink to="/profile">
                <span
                  className="icon"
                  style={{
                    background: page === "billing" ? color : "",
                  }}
                >
                  <UserOutlined />
                </span>
                <span className="label">{t("Mon profil")}</span>
              </NavLink>
            </Menu.Item>
            {/* </Menu>
        <Menu> */}
            <Menu.Item
              key="3"
              onClick={() => {
                sessionStorage.clear();
                navigate("/signin?role=formateur");
              }}
            >
              <NavLink to="/signin?role=formateur">
                <span
                  className="icon"
                  style={{
                    background: page === "billing" ? color : "",
                  }}
                >
                  <LogoutOutlined />
                </span>
                <span className="label">{t("Se déconnecter")}</span>
              </NavLink>
            </Menu.Item>
          </Menu>
        </div>
      )}
    </div>
  );
}

export default Sidenav;
