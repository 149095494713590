import { Button, Modal, notification } from "antd";
import React from "react";
import axiosInstance from "../../../../utils/axios";
import { useLoader } from "../../../../context/LoaderContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
const DeleteBrouillonModal = (props) => {
  const { t } = useTranslation();
  const { setConfirmModal, confirmModal, typeAffaire } = props;
  const { setIsLoading } = useLoader();
  const navigate = useNavigate();
  const deleteBrouillon = () => {
    setIsLoading(true);
    const selectedOption = sessionStorage.getItem("selectedOption");

    const id_affaire_modification = sessionStorage.getItem(
      `id_affaire_edit_${selectedOption}`
    );
    const id_affaire =
      typeAffaire === "epfExistant"
        ? sessionStorage.getItem("idAffaireEpfExistant")
        : typeAffaire === "annexe"
        ? sessionStorage.getItem("id_affaire_annexe")
        : typeAffaire === "changementInvestisseur"
        ? sessionStorage.getItem("idAffaireChangementInvestisseur")
        : typeAffaire === "modification"
        ? id_affaire_modification
        : sessionStorage.getItem("id_affaire");
    const idAffaireName =
      typeAffaire === "epfExistant"
        ? "idAffaireEpfExistant"
        : typeAffaire === "annexe"
        ? "id_affaire_annexe"
        : typeAffaire === "modification"
        ? `id_affaire_edit_${selectedOption}`
        : "id_affaire";
    axiosInstance
      .delete(
        `${import.meta.env.VITE_APP_GEOPROD_API}/delete_affaire/${id_affaire}`,
        {
          headers: {
            Idsession: JSON.parse(sessionStorage.getItem("-x-token-user"))
              ?.Acces_Token,
          },
        }
      )
      .then((res) => {
        setConfirmModal(false);
        setIsLoading(false);
        sessionStorage.removeItem(idAffaireName);
        sessionStorage.removeItem("surveyStep");
        notification.success({
          message: t("Votre demande a bien été annulée."),
          duration: 0,
        });
        navigate("/demandes");
      })
      .catch(() => {
        setConfirmModal(false);
        setIsLoading(false);
      });
  };
  return (
    <Modal
      open={confirmModal}
      footer={null}
      width={600}
      onCancel={() => setConfirmModal(false)}
    >
      <h4>{t("Attention, l'annulation de la demande entraînera la suppression du brouillon.")}</h4>
      <Button type="primary" onClick={deleteBrouillon}>
        {t("Oui")}
      </Button>
      <Button
        type="primary"
        style={{marginInline:"15px"}}
        onClick={() => setConfirmModal("")}
      >
        {t("Non")}
      </Button>
    </Modal>
  );
};

export default DeleteBrouillonModal;
